<template lang="pug">
div(v-if="poi")
  v-img(
    :src="parallax",
    width="100%",
    max-height="500",
    cover,
    v-if="parallax"
  )
  v-container
    .title.my-5 {{ poi.name }}
    div {{ poi.detail }}
    v-divider.my-5
    BizList.mb-3(:bizs="bizs", v-if="bizs && bizs.length")
</template>

<script>
import BizList from "./../Bizs/List";

export default {
  components: { BizList },
  props: ["url"],
  metaInfo() {
    if (this.poi) {
      const title = this.poi.name + " | Selflane";
      const description = this.poi.detail;
      return {
        title,
        meta: [{ name: "description", content: description }],
      };
    }
    return {};
  },
  data() {
    return {
      mediaHost: process.env.VUE_APP_MEDIAHOST,
      poi: null,
      loading: false,
      bizs: [],
    };
  },
  computed: {
    parallax() {
      if (this.poi && this.poi.parallax && this.poi.parallax.length) {
        return this.mediaHost + this.poi.parallax;
      }
      return null;
    },
  },
  watch: {
    url(val, oldVal) {
      if (val && val != oldVal) {
        this.load();
      }
    },
  },
  async mounted() {
    await this.load();
  },
  methods: {
    async load() {
      this.poi = null;
      this.loading = true;
      try {
        const params = { criteria: { url: this.url } };
        this.poi = await this.$api.poi.retrieve(params);
        await this.loadBizs();
      } catch (e) {
        //
      }
      this.loading = false;
    },
    async loadBizs() {
      if (!this.poi) {
        this.bizs = [];
        return;
      }
      const params = {
        criteria: {
          _id: { $in: this.poi.bizs },
          "status.byAdmin": 1,
          "status.byUser": 1,
        },
        select: "name logo url address cates schedule stats orderType",
      };
      this.bizs = await this.$api.biz.list(params);
    },
  },
};
</script>
