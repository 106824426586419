<template lang="pug">
.event-container
  v-row(wrap, no-gutters)
    v-col(cols="12", md="8")
      .event-top-container-left
        v-img(contain, max-height="300", :src="poster", v-if="poster")
    v-col(cols="12", md="4")
      .event-top-container-right
        TitleBlock
</template>

<script>
import { mapGetters } from "vuex";
import TitleBlock from "/libs/components/Event/TitleBlock";

export default {
  components: { TitleBlock },
  computed: {
    ...mapGetters(["biz", "event"]),
    poster() {
      if (this.event?.poster) {
        return "https://media.selflane.com/" + this.event.poster;
      }
      return null;
    },
  },
  methods: {
    handlePurchase() {
      if (!this.event) return;
      this.$router.push("/event/" + this.event._id + "/purchase");
    },
  },
};
</script>

<style lang="sass" scoped>
.event-top-container-left
  background: radial-gradient(circle, rgba(119,122,125,1) 0%, rgba(47,41,40,1) 35%, rgba(98,79,69,1) 100%)

.event-top-container-right
  background-color: rgba(238, 237, 237,0.7)
  padding: 20px
  height: 100%
</style>