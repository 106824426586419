<template lang="pug">
v-dialog(v-model="dialog", width="300")
  template(v-slot:activator="{ on }")
    v-btn(color="gray", icon, v-on="on")
      img(:src="icon", role="button")
  v-card(v-if="card")
    v-card-text
      .text-center
        .sl-title Gift Card QR Code
        QrcodeVue#qrcode(
          :value="qrcode",
          size="180",
          level="H",
          ref="qrCodeUrl"
        )
      div {{ message }}
    v-card-actions
      IconBtn(
        @click="download",
        icon="mdi-printer",
        title="PDF",
        :loading="loading",
        :x-small="true"
      )
</template>

<script>
import jsPDF from "jspdf";
import QrcodeVue from "qrcode.vue";

export default {
  components: { QrcodeVue },
  props: {
    card: { type: Object, default: null },
  },
  data() {
    return {
      icon: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Fqrcode_square.svg?alt=media&token=183eaa78-2ea7-4f7c-84ea-39333837b4b8",
      dialog: false,
      loading: false,
      message:
        "You can use this QR Code for purchase. For your convenience, you can take a screenshot and show it to the cashier at the store or download a PDF file and print it out.",
    };
  },
  computed: {
    qrcode() {
      if (this.card) return "sl:gc:" + this.card._id;
      else return null;
    },
  },
  methods: {
    getQRCodeImg() {
      const el = this.$refs.qrCodeUrl.$refs["qrcode-vue"];
      const img = el.toDataURL("image/png");
      return img;
    },
    async download() {
      if (!this.card) return this.$toast.error("No card to download.");
      this.loading = true;
      try {
        const criteria = { _id: this.card.issuer };
        const biz = await this.$api.biz.retrieve({
          criteria,
          select: "name address",
        });
        this.process(biz);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async process(biz) {
      if (!biz) return;
      const formatter = this.$options.filters.address;
      const bizName = biz.name;
      const bizStreet = formatter(biz.address, "street");
      const bizCityState = formatter(biz.address, "city, state");

      let doc = new jsPDF({ orientation: "portrait", format: "letter" });
      let y = 20;
      const img = this.getQRCodeImg();
      const balance = Math.round(this.card.balance * 100) / 100;
      const created = new Date(this.card.created)
        .toLocaleString()
        .replace(/:\d{2}\s/, " ");
      const message = this.card.message || "Enjoy your gift!";

      addTitle();
      drawCard(50);
      drawDivider();
      drawInstruction();
      doc.save("gift_card.pdf");

      function addTitle() {
        const pageWidth = doc.internal.pageSize.width;
        const x = pageWidth / 2;
        doc.setTextColor(156, 163, 175);
        doc.setFontSize(12);
        doc.text("Your Gift Card", x, y, { align: "center" });
      }

      function drawCard(yStart) {
        // letter size: 8.5 x 11 inches
        // 1 inch = 25.4 mm
        // credit card size: 3.375 x 2.125 inches
        const pageWidth = doc.internal.pageSize.width;
        const factor = 25.4;
        const width = 3.375 * factor;
        const height = 2.125 * factor;
        let x = pageWidth / 2 - width / 2;
        let y = yStart;
        doc.setDrawColor(242, 162, 65);
        doc.roundedRect(x, y, width, height, 3, 3);

        x = x + 3;
        y = y + 3;

        // add QR Code to top-left corner
        doc.addImage(img, "PNG", x, y, 20, 20);

        // add business name and address
        x += 23;
        y += 4;
        doc.setTextColor(242, 162, 65);
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text(bizName.substring(0, 30), x, y, { maxWidth: width - -29 });
        y += 10;
        doc.setFont("helvetica", "normal");
        doc.setTextColor(156, 163, 175);
        doc.setFontSize(10);
        doc.text(bizStreet, x, y);
        y += 5;
        doc.text(bizCityState, x, y);

        // add card balance to bottom
        x = pageWidth / 2;
        y = yStart + height - 14;
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(14);
        // bold text
        doc.setFont("helvetica", "bold");
        doc.text("Balance: $" + balance, x, y, { align: "center" });

        // add printed time
        y = yStart + height - 7;
        doc.setTextColor(156, 163, 175);
        doc.setFontSize(8);
        doc.setFont("helvetica", "normal");
        // print current time, no seconds
        const time = new Date().toLocaleString().replace(/:\d{2}\s/, " ");
        doc.text(`Printed: ${time}`, x, y, { align: "center" });

        // add order date
        y = y + 4;
        doc.text(`Ordered on Selflane: ${created}`, x, y, { align: "center" });

        // add message
        x = pageWidth / 2;
        y = yStart + height + 12;
        doc.setTextColor(31, 41, 55);
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text(message, x, y, { align: "center", maxWidth: pageWidth - 50 });
      }

      // draw a divider line in the middle of the page
      function drawDivider() {
        const pageWidth = doc.internal.pageSize.width;
        const pageHeight = doc.internal.pageSize.height;
        doc.setDrawColor(156, 163, 175);
        doc.setLineWidth(0.5);
        doc.line(0, pageHeight / 2, pageWidth, pageHeight / 2);
      }

      function drawInstruction() {
        const pageHeight = doc.internal.pageSize.height;
        const x = 25;
        const y = pageHeight / 2 + 10;

        doc.setTextColor(31, 41, 55);
        doc.setFontSize(11);
        doc.setFont("helvetica", "normal");
        const items = [
          "This gift card can be used at the store or online.",
          "To use this gift card at the store, show this QR Code to the cashier.",
          "To use this gift card online, please send it to the recipient from your account (Wallet -> Gift Card).",
        ];
        items.forEach((item, i) => {
          doc.text(item, x, y + i * 6);
        });

        const footer = "Powered by Selflane.com ®";
        doc.text(footer, x, pageHeight - 10);
      }
    },
  },
};
</script>