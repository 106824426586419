<template lang="pug">
div
  img.my-3(:src="picture", v-if="picture")
  .my-3 {{ dish.description }}
</template>

<script>
export default {
  props: {
    dish: { type: Object, required: true },
  },
  computed: {
    picture() {
      if (!this.dish.picLink) return;
      return process.env.VUE_APP_MEDIAHOST + this.dish.picLink;
    },
  },
};
</script>

<style lang="sass" scoped>
img
  display: block
  width: 240px
  height: 240px
  border-radius: 6px
  overflow: hidden
  object-fit: cover
  margin-left: auto
  margin-right: auto
</style>
