<template lang="pug">
.d-flex.flex-row.align-start.sticky
  img(:src="icon")
  .pl-3
    .name {{ menu.name }}
    .subtitle-2.success--text {{ menu.time }}
    .my-description.body-2.text--secondary {{ menu.description }}
</template>

<script>
export default {
  props: ["menu"],
  data() {
    return {
      icon: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Frestaurant_list_icon.svg?alt=media&token=c53c8cda-a5d7-40f7-8979-f67fb33daaf6",
    };
  },
};
</script>

<style lang="sass" scoped>
.sticky
  position: -webkit-sticky
  position: sticky
  top: 107px
  z-index: 3
  background: #F0F2F5
  padding-top: 6px
  padding-bottom: 6px

.name
  font-weight: 500
  font-size: 22px
// line limit to 2
.my-description
  display: -webkit-box
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
  overflow: hidden
  text-overflow: ellipsis
</style>