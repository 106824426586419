<template lang="pug">
#search-address-container
  v-text-field.my-5(
    v-model="searchText",
    :placeholder="placeHolder",
    solo,
    rounded,
    ref="autocomplete",
    style="max-width: 100%",
    prepend-inner-icon="mdi-map-marker",
    color="secondary",
    hide-details,
    autocomplete="off"
  )
  v-btn(
    text,
    block,
    small,
    v-for="(item, index) in recentAddresses",
    :key="index",
    @click="setAddress(item)"
  ) {{ item | addressTitle }}
  CitySelector
</template>

<script>
import _ from "underscore";
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import CitySelector from "./CitySelector";

export default {
  components: { CitySelector },
  filters: {
    addressTitle(val) {
      if (val) {
        if (val.line1) return val.line1;
        else return val.city + ", " + val.state;
      }
      return "";
    },
  },
  props: ["open"],
  data() {
    return {
      searchText: "",
    };
  },
  computed: {
    ...mapGetters(["selectedCity", "recentAddresses"]),
    placeHolder() {
      if (this.selectedCity) {
        if (this.selectedCity.line1)
          return this.selectedCity.line1 + ", " + this.selectedCity.city;
        else return this.selectedCity.city + ", " + this.selectedCity.state;
      }
      return "Enter Address";
    },
  },
  watch: {
    open(val) {
      if (!val) return;
      this.initGoogle();
    },
  },
  mounted() {
    const initGoogle = this.initGoogle;
    setTimeout(() => {
      initGoogle();
    }, 500);
  },
  methods: {
    ...mapActions(["setSelectedCity"]),
    setWithGooglePlace(place) {
      if (!place) return;
      // address components
      let address = {
        line1: "",
        city: "",
        state: "",
        country: "",
        postal_code: "",
        geometry: { lat: null, lng: null },
      };

      const street_number_component = _.find(place.address_components, (o) => {
        return _.find(o.types, (t) => t == "street_number") != undefined;
      });
      const route_component = _.find(place.address_components, (o) => {
        return _.find(o.types, (t) => t == "route") != undefined;
      });
      const locality_component = _.find(place.address_components, (o) => {
        return _.find(o.types, (t) => t == "locality") != undefined;
      });
      const administrative_area_level_1_component = _.find(
        place.address_components,
        (o) => {
          return (
            _.find(o.types, (t) => t == "administrative_area_level_1") !=
            undefined
          );
        }
      );
      const country_component = _.find(place.address_components, (o) => {
        return _.find(o.types, (t) => t == "country") != undefined;
      });
      const postal_code_component = _.find(place.address_components, (o) => {
        return _.find(o.types, (t) => t == "postal_code") != undefined;
      });
      if (street_number_component) {
        address.line1 =
          street_number_component.short_name + " " + route_component.short_name;
      }
      if (locality_component) {
        address.city = locality_component.short_name;
      }
      if (administrative_area_level_1_component) {
        address.state = administrative_area_level_1_component.short_name;
      }
      if (country_component) {
        address.country = country_component.short_name;
      }
      if (postal_code_component) {
        address.postal_code = postal_code_component.short_name;
      }
      if (place.geometry) {
        address.geometry.lat = place.geometry.location.lat();
        address.geometry.lng = place.geometry.location.lng();
      }
      this.setAddress(address);
      this.searchText = "";
    },
    setAddress(address) {
      this.setSelectedCity(address);
      EventBus.$emit("set-selected-city");
      this.$emit("saved", address);
    },
    initGoogle() {
      // eslint-disable-next-line no-undef
      this.autocomplete = new google.maps.places.Autocomplete(
        this.$refs.autocomplete.$refs.input
        // { types: ["geocode"] }
      );
      this.autocomplete.setFields(["address_component", "geometry"]);
      this.autocomplete.addListener("place_changed", () => {
        const place = this.autocomplete.getPlace();
        this.setWithGooglePlace(place);
      });
    },
  },
};
</script>

<style lang="sass" scoped>
#search-address-container
  width: 500px
  max-width: 90%
  margin-left: auto
  margin-right: auto
</style>
