<template lang="pug">
.link-box
  QRCode(:order="order")
  Tracking(:order="order")
  v-spacer
  .d-flex.flex-row
    Conversation(:order="order")
    ReviewBtn(:order="order")
    DetailBtn(:order="order")
</template>

<script>
import DetailBtn from "./DetailBtn";
import QRCode from "./QRCode";
import ReviewBtn from "./ReviewBtn";
import Tracking from "./Tracking";
import Conversation from "/libs/components/Order/Conversation";

export default {
  components: { QRCode, Tracking, ReviewBtn, DetailBtn, Conversation },
  props: ["order"],
};
</script>