<template lang="pug">
.not-found
  div Business is Not Found
  .subtitle-2 Please check if you entered correct url
  v-btn.my-10(color="secondary", large, @click="handleSearch()")
    v-icon(left) mdi-magnify
    span Search A Business
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  methods: {
    handleSearch() {
      EventBus.$emit("search-biz");
    },
  },
};
</script>

<style lang="sass" scoped>
.not-found
  margin-top: 100px
  margin-bottom: 100px
  text-align: center
  font-size: 30pt
  font-weight: 500
</style>