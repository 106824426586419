<template lang="pug">
v-dialog(v-model="dialog", width="500")
  template(v-slot:activator="{ on }")
    IconBtn(
      icon="mdi-reply",
      title="Reply",
      v-on="on",
      @click="open",
      :outlined="false",
      :text="true"
    )
  v-card
    v-card-title Reply to Review
    v-form(@submit.prevent="submit")
      v-card-text
        v-textarea(label="Reply", v-model="text", :counter="2000")
      v-card-actions
        v-btn(block, :loading="loading", type="submit", color="secondary") Save
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    review: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      text: "",
      loading: false,
      dialog: false,
    };
  },
  methods: {
    ...mapActions(["updateReview"]),
    open() {
      this.text = this.review?.reply || "";
    },
    async submit() {
      const id = this.review?._id;
      if (!id) return;
      this.loading = true;
      const action = { $set: { reply: this.text.trim(), replyt: Date.now() } };
      try {
        const result = await this.$api.review.put(id, action);
        this.updateReview(result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
