<template lang="pug">
.hourselector-container
  v-select(
    outlined,
    hide-details,
    v-model="hh",
    :items="hours",
    dense,
    append-icon="",
    menu-props="auto"
  )
  span :
  v-select(
    outlined,
    hide-details,
    v-model="mm",
    :items="minutes",
    dense,
    append-icon="",
    menu-props="auto"
  )
  span :
  v-select(
    outlined,
    hide-details,
    v-model="ampm",
    :items="ampm_items",
    dense,
    append-icon="",
    menu-props="auto"
  )
</template>

<script>
import moment from "moment-timezone";
import Selector from "./Selector";

export default {
  components: { Selector },
  props: {
    value: { type: Number, required: true },
    timezone: { type: String, required: true },
  },
  data() {
    return {
      hh: moment.tz(this.value, this.timezone).format("h"),
      mm: moment.tz(this.value, this.timezone).format("mm"),
      ampm: moment.tz(this.value, this.timezone).format("A"),
    };
  },
  computed: {
    hours() {
      return [...Array(12).keys()].map((o) => {
        return String(o + 1);
      });
    },
    minutes() {
      return [...Array(60).keys()].map((o) => {
        return String(o).padStart(2, "0");
      });
    },
    ampm_items() {
      return ["AM", "PM"];
    },
  },
  watch: {
    value() {
      this.hh = moment.tz(this.value, this.timezone).format("h");
      this.mm = moment.tz(this.value, this.timezone).format("mm");
      this.ampm = moment.tz(this.value, this.timezone).format("A");
    },
    hh() {
      this.update();
    },
    mm() {
      this.update();
    },
    ampm() {
      this.update();
    },
  },
  methods: {
    update() {
      const date = moment.tz(this.value, this.timezone).format("YYYY-MM-DD");
      const combined = `${date} ${this.hh}:${this.mm} ${this.ampm}`;
      const value = moment
        .tz(combined, "YYYY-MM-DD h:mm A", this.timezone)
        .valueOf();
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="sass" scoped>
.hourselector-container
  display: flex
  flex-direction: row
  align-items: center
  gap: 8px
  padding: 30px 12px
</style>