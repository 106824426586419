<template lang="pug">
div
  Label(icon="mdi-phone")
    v-menu
      template(v-slot:activator="{ on }")
        .d-flex.flex-row.align-center(v-on="on", role="button")
          .font-weight-medium(v-if="phone") {{ phone | phone }}
          span(v-else) Select
          v-icon(right) mdi-menu-down
      v-card
        v-list.py-0
          v-list-item(
            v-for="(item, index) in phones",
            :key="index",
            @click="setPhone(item.number)"
          )
            v-list-item-content {{ item.number | phone }}
            v-list-item-action
              v-icon(color="success", v-if="item.number == phone") mdi-check
    v-spacer
    v-btn(icon, small, @click="add", color="primary")
      v-icon(small) mdi-plus
  UserPhoneForm(@saved="setPhone")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "CartPhone",
  computed: {
    ...mapGetters(["cartOrder"]),
    phones() {
      return this.$auth.user()?.phones;
    },
    phone() {
      return this.cartOrder.customer?.phone;
    },
  },
  methods: {
    ...mapActions(["setPhone"]),
    add() {
      EventBus.$emit("user.phone:edit");
    },
  },
};
</script>
