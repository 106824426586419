<template lang="pug">
v-dialog(v-model="dialog", max-width="450")
  v-card(v-if="needed")
    v-toolbar(flat)
      .time-slot {{ needed | datetime("MMMM YYYY", timezone) }}
      v-spacer
      v-btn.text-capitalize(
        outlined,
        rounded,
        color="secondary",
        @click="save()"
      ) Save
    DateSelector(v-model="needed", :biz="biz", :timezone="timezone")
    HourSelector(v-model="needed", :timezone="timezone")
    v-card-text
      v-alert(type="error", v-if="!validity.status") {{ validity.error }}
      Label(icon="mdi-calendar")
        Schedule(:time="needed")
      v-divider.my-2
      .caption(v-if="isDelivery") Delivery order should be <b>{{ delivery_delay }}'</b> after opening hours
      .caption(v-else) Pick up order should be <b>{{ open_delay }}'</b> after opening hours
      .caption Based on merchant timezone: <b>{{ timezone }}</b>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import DateSelector from "./DateSelector/index";
import HourSelector from "./HourSelector/index";
import Schedule from "./Schedule";
import BizUtils from "/libs/utils/biz.js";

export default {
  components: { DateSelector, HourSelector, Schedule },
  data() {
    return {
      dialog: false,
      needed: moment().valueOf(),
    };
  },
  computed: {
    ...mapGetters(["cartOrder", "cartGeoService", "biz", "timezone"]),
    open_delay() {
      const value = this.biz?.schedule?.open_delay || 0;
      return Math.max(value, 10);
    },
    delivery_delay() {
      return Math.max(this.open_delay, 30);
    },
    // is future date
    isDelivery() {
      return this.cartOrder?.type === "delivery";
    },
    isFuture() {
      const needed = this.cartOrder?.needed;
      if (!needed) return false;
      const end = moment().tz(this.timezone).endOf("day").valueOf();
      return needed > end;
    },
    validity() {
      const result = BizUtils.validateTypeTime(
        this.cartOrder.type,
        this.needed,
        this.biz,
        this.cartGeoService
      );
      return result;
    },
  },
  methods: {
    open(value) {
      this.dialog = true;
      this.needed = value;
    },
    save() {
      if (!this.validity.status) {
        return this.$toast.error(this.validity.error, { position: "top" });
      }
      this.$emit("save", this.needed);
      this.dialog = false;
    },
  },
};
</script>

<style lang="sass" scoped>
#cart-time
  cursor: pointer

.time-slot
  font-size: 17px
  font-weight: 500
  line-height: 52px
  margin-right: 12px
  white-space: nowrap
</style>
