<template lang="pug">
v-card-text(v-if="card && card.balance > 0")
  .text-center(v-if="card.to && card.to.length > 0")
    div Sent to {{ card.to }}
    div Your friend hasn't claimed this gift card yet. Don't want to go through the register/claim process? Just send a screenshot with the above QR Code, your friend can use that directly.
  div(v-else)
    .green.white--text.body-2.pa-2 Send Gift Card to a Friend
    span Enter email below. If entered email is a registered Selflane user, your gift card will be delivered immediately. Otherwise, your friend will receive an email with instruction to claim the gift card.
    v-form(@submit.prevent="submit")
      v-text-field(
        label="Email",
        v-model="email",
        :error-messages="emailErrors",
        @blur="$v.email.$touch()"
      )
      v-text-field(label="Message", v-model="message")
      v-row(justify="end")
        v-btn(text, @click="dialog = false") Cancel
        v-btn(:disabled="$v.$invalid", type="submit", color="secondary") Send
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  mixins: [validationMixin],
  props: ["card", "dialog"],
  validations: { email: { required, email } },
  data() {
    return { email: "", message: "" };
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("Email is required");
      !this.$v.email.email && errors.push("Invalid email address");
      return errors;
    },
  },
  watch: {
    dialog() {
      this.reset();
    },
  },
  methods: {
    ...mapActions(["updateGiftCard", "removeGiftCard"]),
    reset() {
      this.email = "";
      this.message = "";
      this.$v.$reset();
    },
    async submit() {
      if (!this.card) return;
      const gcId = this.card._id;
      const params = {
        gcId: gcId,
        email: this.email.trim(),
        message: this.message,
      };
      try {
        const result = await this.$api.giftcard.custom("transfer", params);
        if (result.to) this.updateGiftCard(result);
        else this.removeGiftCard(result);
        this.$toast.success("Gift Card Sent Successfully");
        this.$emit("transferred");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>
