<template lang="pug">
v-simple-table(dense, v-if="items && items.length")
  thead
    tr
      th Date
      th.text-right Points
  tbody
    tr(v-for="(item, index) in items", :key="index")
      td {{ item._id | date }}
      td.text-right {{ item.amount }}
</template>

<script>
export default {
  props: ["items"],
};
</script>
