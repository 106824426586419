<template lang="pug">
v-btn.text-capitalize(
  depressed,
  small,
  color="#E4E6EB",
  @click="show",
  v-if="chain"
)
  v-icon(small, left) mdi-store
  span Multiple Locations
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      lat: 0, // user location
      lng: 0,
    };
  },
  computed: {
    ...mapGetters(["biz", "chain"]),
  },
  methods: {
    show() {
      EventBus.$emit("show-chain-dialog", this.chain);
    },
  },
};
</script>
