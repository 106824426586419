<template lang="pug">
.label-container
  v-icon(small, :color="color", v-if="icon") {{ icon }}
  .body-2(v-if="title") {{ title }}
  slot
</template>

<script>
export default {
  props: {
    icon: { type: String, default: "" },
    title: { type: String, default: "" },
    color: { type: String, default: "primary" },
  },
};
</script>

<style lang="sass" scoped>
.label-container
  display: flex
  gap: 12px
  align-items: first baseline
</style>