<template lang="pug">
tr
  td {{ title }}
    v-dialog(width="400")
      template(v-slot:activator="{ on }")
        v-icon.ml-2(v-on="on", small, color="grey") mdi-information
      v-card
        v-toolbar(flat)
          .subtitle-2 {{ title }}
        v-simple-table
          tbody
            AmountTableRow(
              v-for="(item, index) in cartOrder.payment.tax_items",
              :key="index",
              :name="getName(item)",
              :amount="item.value"
            )
            tr(v-if="service_fee")
              td
                div Service fee
                .caption.text--secondary Charged by Selflane to facilitate the payment and service.
              td.text-right {{ service_fee | currency }}
          tfoot
            AmountTableRow(name="Total", :amount="total", bold)
  td.text-right {{ total | currency }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["cartOrder", "cartServiceFeeRate", "cartMinServiceFee"]),
    service_fee() {
      const fee = this.cartOrder?.payment?.selflane?.items.find(
        (o) => o.name == "service_fee"
      )?.value;
      return fee || 0;
    },
    total() {
      if (!this.cartOrder) return 0;
      return this.cartOrder.payment.tax + this.service_fee;
    },
    title() {
      if (this.service_fee) return "Tax and fees";
      return "Tax";
    },
  },
  methods: {
    getName(item) {
      return `${item.name} ${item.percentage}%`;
    },
  },
};
</script>
