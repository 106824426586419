<template lang="pug">
div
  .banner-style(v-if="valid", @click="dialog = true")
    v-img(:src="icon", alt="icon", :max-width="40", contain)
    div {{ message }}
  v-dialog(v-model="dialog", max-width="600px")
    v-card
      v-card-title {{ title }}
      v-card-text
        v-img(:src="icon", alt="icon", :height="100", contain)
        div Exciting News! <b>Selflane has partnered with New Mexico United</b>, our state's professional soccer team! When you order from any Selflane restaurant partner between 10/24 - 10/30, you are automatically entered into a drawing to win 1 of 10 pairs of tickets to the first home playoff game in New Mexico United history!!! This historic match takes place on Sunday November 3rd, Kickoff is at 6pm at Isotopes Park in Albuquerque. <b>Somos Unidos!</b>
        b We will announce the winners on 10/31. Good luck!
      v-card-actions
        v-btn(@click="dialog = false") Close
</template>

<script>
import moment from "moment-timezone";
export default {
  props: {
    biz: { type: Object, required: true },
  },
  data() {
    return {
      message:
        "Order now! You are automatically entered into a drawing to win 1 of 10 pairs of tickets to the first home playoff game in New Mexico United history!!!",
      title: "United is in Playoffs!",
      icon: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/partner%2Fnew_mexico_united%2Funited.webp?alt=media&token=abab7c1e-2fd3-4eb5-baca-917a5eea370c",
      dialog: false,
    };
  },
  computed: {
    city_state() {
      if (!this.biz) return "";
      // return biz.address city and state in lowercase and joined by a _
      return `${this.biz.address?.city || ""}_${
        this.biz.address?.state || ""
      }`.toLowerCase();
    },
    valid() {
      // return true if city_state is in the list of valid city_states
      // albuquerque_nm, rio rancho_nm, santa fe_nm
      // end on 2024-10-31
      const end = moment.tz("2024-10-31", "America/Denver").endOf("day");
      if (moment().isAfter(end)) return false;
      return ["albuquerque_nm", "rio rancho_nm", "santa fe_nm"].includes(
        this.city_state
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.banner-style
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
  gap: 10px
  background-color: #f5f5f5
  color: #333
  padding: 10px
  font-size: 1.1em
  font-weight: 500
  border-bottom: 1px solid #ccc
  cursor: pointer
  .v-btn
    text-transform: none
</style>