<template lang="pug">
div
  .block-container.mb-3.px-3.py-1
    DishCell(
      v-for="(dish, index) in cartOrder.dishes",
      :key="index + 'd'",
      :dish="dish",
      @delete="removeDishAt(index)",
      @click="edit(dish, index)",
      editable
    )
  .block-container
    Cell(
      v-for="(gift, index) in cartOrder.gifts",
      :key="index + 'g'",
      @remove="removeGiftAt(index)"
    )
      template(slot="name-block")
        span eGift Card
      template(slot="price-block") {{ gift.amount | currency }}
  AlcoholAlert
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import Cell from "./Cell";
import AlcoholAlert from "./AlcoholAlert";

export default {
  components: { Cell, AlcoholAlert },
  computed: { ...mapGetters(["cartOrder"]) },
  methods: {
    ...mapActions(["removeDishAt", "removeGiftAt"]),
    edit(dish, index) {
      const clone = JSON.parse(JSON.stringify(dish));
      EventBus.$emit("show-dish", clone, index);
    },
  },
};
</script>

<style lang="sass" scoped>
.block-container
  font-size: 15px
  font-weight: 500
  border-radius: 12px
  background: white
</style>