<template lang="pug">
div
  .d-flex.flex-row.align-center.justify-space-between.my-5
    .sl-title Phones
    IconBtn(@click="edit()", title="Add", icon="mdi-plus")
  v-card(flat, v-if="phones && phones.length")
    v-list.py-0(dense)
      Cell(
        v-for="(item, index) in phones",
        :key="index",
        :item="item",
        @edit="edit(item)"
      )
  UserPhoneForm
</template>

<script>
import Cell from "./Cell";
import { EventBus } from "@/event-bus.js";

export default {
  components: { Cell },
  computed: {
    phones() {
      return this.$auth.user()?.phones;
    },
  },
  methods: {
    edit(data) {
      EventBus.$emit("user.phone:edit", data);
    },
  },
};
</script>
