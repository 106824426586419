/**
 * Transform a number of hours into a string with the format hh:mm or hh.mm(2 decimals)
 * @param {*} value 
 * @param {*} isDecimal. Default false
 * @returns 
 * @example
 * hours(1.5) // 1:30
 * hours(1.5, true) // 1.50
 */

function hours(value, isDecimal) {
  if (typeof (value) !== 'number') return value
  if (value < 0.01) return '-'
  if (isDecimal) {
    return value.toFixed(2)
  } else {
    const num = Math.round(value * 100) / 100
    const hh = Math.floor(num)
    const mm = Math.round((num - hh) * 60)
    if (mm >= 10) return hh + ':' + mm
    else return hh + ':0' + mm
  }
}

export default hours