<template lang="pug">
div(v-if='bizs && bizs.length').my-3.white
  v-container
    .sl-title Most Liked
    BizList(:bizs='bizs')
</template>

<script>
import { mapGetters } from "vuex";
import BizList from "@/components/Bizs/ListRow";
import _ from "underscore";

export default {
  components: { BizList },
  computed: {
    ...mapGetters(["bizAccounts"]),
    bizs() {
      return _.chain(this.bizAccounts)
        .filter(o => {
          if (o && o.stats && o.stats.fav > 5) return true;
        })
        .sortBy(o => {
          if (o && o.stats) return o.stats.fav;
          return 0;
        })
        .value()
        .reverse();
    },
  },
};
</script>
