<template lang="pug">
div
  TopBar(:menus="menus")
  div(v-for="group in menus", :key="group.menu._id")
    MenuTitle(:menu="group.menu")
    CourseSection(
      v-for="cItem in group.courses",
      :key="cItem.course._id",
      :id="cItem.id",
      :data="cItem",
      :modifiers="modifiers"
    )
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";
import TopBar from "./TopBar";
import MenuTitle from "./MenuTitle";
import CourseSection from "./CourseSection/index";

export default {
  components: { TopBar, MenuTitle, CourseSection },
  computed: {
    ...mapGetters(["biz", "modifiers"]),
    bizId() {
      return this.biz?._id;
    },
    menus() {
      if (!this.biz?.menus || !this.biz?.courses || !this.biz?.dishes)
        return [];
      const dayschedule = this.$options.filters.dayschedule;
      const day = this.$options.filters.day;
      // reject dish if ooo, hide, uom
      // if price is 0, reject only if the dish has no required modifiers
      const dishes = this.biz.dishes.filter((o) => {
        if (o.ooo || o.hide || o.uom) return false;
        if (o.price === 0) {
          const dishModifiers = this.modifiers?.filter(
            (m) => o.modifierIDs?.includes(m._id) && m.required
          );
          return dishModifiers?.some((m) => m.choices.some((c) => c.price > 0));
        }
        return true;
      });

      const courses = this.biz.courses;

      function getObjById(id, list) {
        return list.find((o) => o._id === id);
      }
      let n = 0;
      return _.chain(this.biz.menus)
        .reject((menu) => menu.ooo || menu.controlled)
        .map((menu) => {
          if (menu?.selectedTime) {
            menu.time =
              day(menu.date, true) +
              " " +
              dayschedule({ status: true, schedule: menu.schedule });
          }
          return {
            menu: menu,
            courses: _.chain(dishes)
              .filter((dish) => dish.menus.includes(menu._id) && dish.course)
              .groupBy("course")
              .map((dishes, courseId) => {
                const course = getObjById(courseId, courses);
                return { course: course, dishes };
              })
              .reject((o) => !o.course || o.course.ooo || o.course.controlled)
              .sortBy((o) => o.course.sortId)
              .value(),
          };
        })
        .reject((o) => !o.courses?.length)
        .each((o) => {
          o.courses.forEach((c) => {
            c.n = n;
            n++;
            c.id = `course_${c.course._id}_${o.menu._id}`;
          });
        })
        .value();
    },
  },
  watch: {
    bizId(oldVal, val) {
      if (oldVal != val) this.loadModifiers();
    },
  },
  mounted() {
    this.loadModifiers();
  },
  methods: {
    async loadModifiers() {
      if (!this.bizId) return;
      const params = { criteria: { bizId: this.bizId } };
      try {
        const data = await this.$api.bizModifier.list(params);
        const result = _.chain(data)
          .reject((o) => !o.modifier?.ol)
          .pluck("modifier")
          .each((o) => (o.choices = o.choices?.filter((c) => c.ol)))
          .reject((o) => !o.choices?.length)
          .value();
        this.$store.dispatch("setModifiers", result);
      } catch (e) {
        this.$store.dispatch("setModifiers", []);
      }
    },
  },
};
</script>
