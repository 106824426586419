<!--
  Add or edit address for users
  Usage: EventBus.$emit("user.address:edit", address)
  In success save, emit $saved back to parent component
-->


<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  v-card
    v-card-title {{ address._id ? "Edit" : "Add" }} Address
    v-form(@submit.prevent="save")
      v-card-text
        GoogleAddressField(v-model="address")
        v-text-field(label="Apt/Room (optional)", v-model="address.line2")
        v-text-field(label="Instruction", v-model="address.note")
      v-card-actions
        v-btn(color="secondary", block, type="submit", :loading="loading") Save
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      address: {},
      loading: false,
    };
  },
  mounted() {
    EventBus.$on("user.address:edit", this.edit);
  },
  destroyed() {
    EventBus.$off("user.address:edit", this.edit);
  },
  methods: {
    edit(data) {
      if (data) {
        this.address = JSON.parse(JSON.stringify(data));
      } else {
        this.address = {};
      }
      this.dialog = true;
    },
    async save() {
      const userId = this.$auth.user()?._id;
      if (!userId) return;
      this.loading = true;
      let params;
      if (this.address._id) {
        params = {
          criteria: { _id: userId, "addresses._id": this.address._id },
          action: { $set: { "addresses.$": this.address } },
        };
      } else {
        params = {
          criteria: { _id: userId },
          action: { $push: { addresses: this.address } },
        };
      }
      try {
        const result = await this.$api.user.update(params);
        this.$auth.user(result);
        this.dialog = false;
        this.$toast.success("Address saved");
        this.$emit("saved", result.addresses.slice(-1)[0]);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
