<!--
  Add or edit phone for users
  Usage: EventBus.$emit("user.phone:edit", phone)
  In success save, emit $saved back to parent component
-->


<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  v-card
    v-card-title {{ phone._id ? "Edit" : "Add" }} Phone
    v-form(@submit.prevent="save")
      v-card-text
        PhoneField(v-model="number")
      v-card-actions
        v-btn(color="secondary", block, type="submit", :loading="loading") Save
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      phone: {},
      number: "",
      loading: false,
    };
  },
  mounted() {
    EventBus.$on("user.phone:edit", this.edit);
  },
  destroyed() {
    EventBus.$off("user.phone:edit", this.edit);
  },
  methods: {
    edit(data) {
      if (data) {
        this.phone = JSON.parse(JSON.stringify(data));
      } else {
        this.phone = {};
      }
      this.number = this.phone.number || "";
      this.dialog = true;
    },
    async save() {
      const userId = this.$auth.user()?._id;
      if (!userId) return;
      this.phone.number = this.number;
      const found = this.$auth
        .user()
        .phones.find((o) => o.number === this.number);
      if (found) {
        this.$toast.error("Phone number already exists");
        return;
      }
      let params;
      if (this.phone._id) {
        params = {
          criteria: { _id: userId, "phones._id": this.phone._id },
          action: { $set: { "phones.$": this.phone } },
        };
      } else {
        params = {
          criteria: { _id: userId },
          action: { $push: { phones: this.phone } },
        };
        this.loading = true;
      }
      try {
        const result = await this.$api.user.update(params);
        this.$auth.user(result);
        this.dialog = false;
        this.$toast.success("Phone saved");
        this.$emit("saved", result.phones.slice(-1)[0].number);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
