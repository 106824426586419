<template lang="pug">
v-card(outlined)
  v-toolbar(flat, dense)
    .subtitle-2 {{ item.name }}
  v-card-text
    div {{ item.introduction }}
    v-row(wrap, no-gutters)
      v-col(cols="4", v-for="(logo, index) in logos", :key="index")
        v-img(:src="logo")
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["item"],
  computed: {
    ...mapGetters(["bizs"]),
    logos() {
      let list = [];
      this.item.bizs.forEach((o) => {
        const found = this.bizs.find((biz) => biz._id == o);
        if (found) {
          list.push("https://media.selflane.com/" + found.logo);
        }
      });
      return list.slice(0, 9);
    },
  },
};
</script>
