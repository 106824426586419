<template lang="pug">
.choice-btn(:class="{ 'choice-selected': choice.selected }")
  .d-flex.flex-row.align-end.unselectable
    .d-flex.flex-row.px-3(@click="$emit('click')", role="button")
      .text-truncate {{ choice.name }}
      .price.ml-1(v-if="choice.price > 0") +{{ choice.price | currency }}
    SizePicker.select-style(
      v-model="choice.quantity",
      :max="choice.maximum + 1",
      @change="selectQuantity",
      v-if="choice.selected && choice.maximum > 1",
      dark
    )
</template>

<script>
export default {
  props: ["choice"],
  methods: {
    selectQuantity() {
      this.$emit("selectQuantity", this.choice.quantity);
    },
  },
};
</script>

<style lang="sass" scoped>
.price
  font-size: 9px

.select-style
  width: 48px
  font-size: 13px
  font-weight: 500
  padding: 0

.unselectable
  -webkit-user-select: none
  -webkit-touch-callout: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
</style>