const state = {
  rewards: []
}

const getters = {
  rewards: state => state.rewards
}

const actions = {
  setRewards: ({ commit }, data) => { commit('setRewards', data) },
  updateReward: ({ commit }, data) => { commit('updateReward', data) },
  addReward: ({ commit }, data) => { commit('addReward', data) }
}

const mutations = {
  setRewards(state, rewards) {
    state.rewards = rewards
  },
  updateReward(state, reward) {
    state.rewards = state.rewards.map(o => {
      return (o._id === reward._id) ? reward : o
    })
  },
  addReward(state, reward) {
    state.rewards.unshift(reward)
  }
}

export default { state, getters, actions, mutations }