<template lang="pug">
.d-flex.flex-row.py-10.justify-center
  .countdown-block(v-if="days > 0")
    .countdown-block-top {{ days }}
    .countdown-block-bottom Days
    .countdown-bottom-border
  .countdown-spacer(v-if="days > 0") D
  .countdown-block
    .countdown-block-top {{ hours }}
    .countdown-block-bottom HOURS
    .countdown-bottom-border
  .countdown-spacer :
  .countdown-block
    .countdown-block-top {{ minutes }}
    .countdown-block-bottom MINS
    .countdown-bottom-border
  .countdown-spacer :
  .countdown-block
    .countdown-block-top {{ seconds }}
    .countdown-block-bottom SECS
    .countdown-bottom-border
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      now: moment().unix(),
    };
  },
  computed: {
    ...mapGetters(["event"]),
    ending() {
      if (this.event) return this.event.sale_time.end / 1000;
      return moment().unix();
    },
    diff() {
      if (this.ending > this.now) return this.ending - this.now;
      return 0;
    },
    days() {
      return Math.floor(this.diff / 60 / 60 / 24);
    },
    hours() {
      return Math.floor((this.diff / 60 / 60) % 24);
    },
    minutes() {
      return String(Math.floor((this.diff / 60) % 60)).padStart(2, "0");
    },
    seconds() {
      return String(Math.floor(this.diff % 60)).padStart(2, "0");
    },
  },
  mounted() {
    this.countDownTimer();
  },
  methods: {
    countDownTimer() {
      if (this.now > this.ending) return;
      setTimeout(() => {
        this.now = moment().unix();
        this.countDownTimer();
      }, 1000);
    },
  },
};
</script>

<style lang="sass" scoped>
.countdown-block
  overflow: hidden
  border-radius: 12px
  background-color: #1C1A1B
  margin-left: 12px
  margin-right: 12px
  width: 210px
  position: relative
  box-shadow: 1px 4px 8px 1px white, 1px 6px 20px 1px white

  &-top
    color: white
    font-size: 70px
    font-weight: 700
    width: 100%
    text-align: center
    margin-top: 16px
    margin-bottom: 16px

  &-bottom
    color: #807e7e
    background-color: #090909
    width: 100%
    font-size: 18px
    font-weight: 600
    text-align: center
    padding: 10px

.countdown-bottom-border
  position: relative
  bottom: 0px
  width: 40px
  left: 85px
  height: 6px
  background-color: #ff6d32
  border-top-left-radius: 6px
  border-top-right-radius: 6px

.countdown-spacer
  font-size: 36px
  font-weight: 900
  margin-top: 40px
  position: relative
</style>