const state = {
  stripePersons: []
}

const getters = {
  stripePersons: state => state.stripePersons
}

const actions = {
  setStripePersons: ({ commit }, data) => { commit('setStripePersons', data) }
}

const mutations = {
  setStripePersons(state, stripePersons) {
    state.stripePersons = stripePersons
  }
}

export default { state, getters, actions, mutations }