<template lang="pug">
div
  v-list-item
    v-list-item-content
      v-list-item-subtitle Name
      v-list-item-title {{ fullname }}
    v-list-item-action
      v-btn(icon, @click="edit()", :loading="loading")
        v-icon(small) mdi-pencil
  v-dialog(v-model="dialog", width="300")
    v-card
      v-card-title
      v-form(@submit.prevent="submit")
        v-card-text
          v-text-field(
            label="Full Name",
            v-model="name",
            :error-messages="nameErrors",
            :counter="30",
            @blur="$v.name.$touch()",
            autofocus
          )
        v-card-actions
          v-btn(
            block,
            :disabled="$v.$invalid",
            type="submit",
            color="secondary",
            :loading="loading"
          ) save
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: { name: { required, maxLength: maxLength(30) } },
  data() {
    return {
      dialog: false,
      name: "",
      loading: false,
    };
  },
  computed: {
    fullname() {
      const user = this.$auth.user();
      if (!user) return "";
      return user.name.first + " " + user.name.last;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Required");
      !this.$v.name.maxLength && errors.push("No more than 30 charactors");
      return errors;
    },
  },
  methods: {
    edit() {
      this.dialog = true;
      this.name = this.fullname;
      this.$v.$reset();
    },
    // split name into first and last
    getFirstLast(name) {
      let first = "";
      let last = "";
      const items = name.trim().split(" ");
      if (items.length) first = items.shift();
      if (items.length) last = items.join(" ");
      return { first, last };
    },
    async submit() {
      const user = this.$auth.user();
      if (!user) return;
      this.name = this.name.trim();
      this.loading = true;
      const { first, last } = this.getFirstLast(this.name);
      const params = {
        criteria: { _id: user._id },
        action: { $set: { "name.first": first, "name.last": last } },
      };
      try {
        const { data } = await this.axios.post("/users/update", params);
        this.$auth.user(data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
