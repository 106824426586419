<template lang="pug">
v-dialog(v-model="dialog", max-width="500px")
  v-card(flat, v-if="schedule")
    v-toolbar(dense, flat)
      .subtitle-2 Hours
    v-alert(v-model="closed", type="error", dense) Temporary Close. {{ schedule?.temporary_close.reason }}
    simple-table
      tbody
        tr(v-for="(item, index) in schedule.weekdays", :key="index")
          td {{ item.date | day }}
          td.text-right.text--secondary {{ item | dayschedule }}
        tr(
          v-for="(item, index) in schedule.specialdays",
          :key="index + 's'",
          @click.stop="edit(item)",
          role="button"
        )
          td {{ item.date }}
            .text--secondary.nowrap {{ item.date | holiday }}
          td
            .d-flex.flex-row.justify-end.align-center
              .text--secondary {{ item | dayschedule }}
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      schedule: null,
    };
  },
  computed: {
    closed() {
      return this.schedule?.temporary_close?.status;
    },
  },
  mounted() {
    EventBus.$on("show-hours", this.show);
  },
  destroyed() {
    EventBus.$off("show-hours", this.show);
  },
  methods: {
    show(schedule) {
      this.schedule = schedule;
      this.dialog = true;
    },
  },
};
</script>
