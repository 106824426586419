<template lang="pug">
tr(:class="color", v-if="amount || mandatory", v-on="$listeners", :role="role")
  th
    a(v-if="link", :href="link", target="_blank") {{ name }}
    span(v-else) {{ name }}
  td.text-right
    span(v-if="currency") {{ amount | currency }}
    span(v-else) {{ amount }}
</template>

<script>
export default {
  props: {
    name: { type: String, default: null },
    amount: { type: Number, default: null },
    link: { type: String, default: null },
    mandatory: { type: Boolean, default: false },
    bold: { type: Boolean, default: false },
    currency: { type: Boolean, default: true },
    button: { type: Boolean, default: false },
  },
  computed: {
    color() {
      let text = "";
      if (this.bold) text += "font-weight-bold ";
      if (this.button) text += "blue--text ";
      else if (this.amount && this.amount < 0) text += "green--text ";
      return text;
    },
    role() {
      return this.button ? "button" : "";
    },
  },
};
</script>