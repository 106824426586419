/// Register all components here

import Label from "@/components/basic/Label";
import QRCodeDialog from "@/components/basic/QRCodeDialog";

// libs
import SimpleTable from "/libs/components/Basic/SimpleTable";
import Page from '/libs/components/Page'
import IconBtn from '/libs/components/IconBtn'
import GoogleAddressField from '/libs/components/GoogleAddressField'
import PhoneField from '/libs/components/PhoneField'
import EmptyView from '/libs/components/EmptyView'
import BirthdayPicker from '/libs/components/BirthdayPicker'
import DayHours from '/libs/components/Biz/DayHours'
import UserAddressForm from '/libs/components/User/AddressForm'
import UserPhoneForm from '/libs/components/User/PhoneForm'
import YearSelector from '/libs/components/Time/YearSelector'
import AmountTableRow from '/libs/components/Basic/AmountTableRow'
import DishCell from '/libs/components/Biz/Menu/DishCell'
import DishPrice from '/libs/components/Biz/Menu/DishPrice'
import DishServing from '/libs/components/Biz/Menu/DishServing'
import DishTags from '/libs/components/Biz/Menu/DishTags'
import HalfHalfPicker from '/libs/components/Biz/Menu/HalfHalfPicker'
import SizePicker from '/libs/components/Biz/Menu/SizePicker'

export default function (Vue) {
  Vue.component('Label', Label)
  Vue.component('QRCodeDialog', QRCodeDialog)
  // libs
  Vue.component('SimpleTable', SimpleTable)
  Vue.component('Page', Page)
  Vue.component('IconBtn', IconBtn)
  Vue.component('GoogleAddressField', GoogleAddressField)
  Vue.component('PhoneField', PhoneField)
  Vue.component('EmptyView', EmptyView)
  Vue.component('BirthdayPicker', BirthdayPicker)
  Vue.component('DayHours', DayHours)
  Vue.component('UserAddressForm', UserAddressForm)
  Vue.component('UserPhoneForm', UserPhoneForm)
  Vue.component('YearSelector', YearSelector)
  Vue.component('AmountTableRow', AmountTableRow)
  Vue.component('DishCell', DishCell)
  Vue.component('DishPrice', DishPrice)
  Vue.component('DishServing', DishServing)
  Vue.component('DishTags', DishTags)
  Vue.component('HalfHalfPicker', HalfHalfPicker)
  Vue.component('SizePicker', SizePicker)
}
