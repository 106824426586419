<template lang="pug">
v-list-item(@click="select", @mouseover="handleHover(item)")
  v-list-item-content
    v-list-item-title {{ name }}
    v-list-item-subtitle
      span(v-if="item.address")
        span.mr-1(v-if="item.industry != 4") {{ item.address.line1 }},
        span {{ item.address.city }}
      span.ml-3(v-if="distance") {{ distance | readableDistance(unit) }}
  v-list-item-action
    v-icon mdi-chevron-right
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ChainBizItem",
  props: ["item", "distance"],
  computed: {
    ...mapGetters(["biz"]),
    name() {
      return this.item.short_name || this.item.name;
    },
    unit() {
      if (this.item?.address?.country == "CA") return "km";
      return "mi.";
    },
  },
  methods: {
    ...mapActions(["setConfirmedBiz"]),
    handleHover() {
      this.$emit("hover", this.item);
    },
    select() {
      const url = `/bizs/${this.item.url}`;
      this.setConfirmedBiz(this.item._id);
      this.$router.push(url);
    },
  },
};
</script>
