<template lang="pug">
.mb-10
  .mb-5(v-for="(item, index) in items", :key="index")
    Cell(:review="item", @edit="edit(item)")
    .sl-dash-divider.mt-1(v-if="index < items.length - 1")
  .text-center(v-if="length && length > 1")
    v-pagination(
      :length="length",
      v-model="page",
      v-if="length",
      circle,
      color="grey"
    )
  EmptyView(v-if="reviews.length == 0", text="No Reviews Yet")
  Form(ref="form")
</template>

<script>
import Cell from "./Cell";
import Form from "./Form";

export default {
  components: { Cell, Form },
  props: {
    reviews: { type: Array, required: true },
    pageMax: { type: Number, default: 5 },
  },
  data() {
    return { page: 1 };
  },
  computed: {
    // return the items for the current page
    items() {
      const start = (this.page - 1) * this.pageMax;
      return this.reviews.slice(start, start + this.pageMax);
    },
    length() {
      return Math.ceil(this.reviews.length / this.pageMax);
    },
  },
  methods: {
    edit(review) {
      this.$refs.form.open(review);
    },
  },
};
</script>
