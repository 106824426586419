<template lang="pug">
div
  Payment
  Card
</template>

<script>
import Payment from "./Payment/index";
import Card from "./Card";

export default {
  components: { Payment, Card },
};
</script>