<template lang="pug">
div(v-if="cartDue > 0")
  .body-2.mb-2 Payment
  .d-flex.flex-row.align-center.justify-space-between
    v-menu(v-model="dialog")
      template(v-slot:activator="{ on }")
        .d-flex.flex-row.align-center.mr-5(v-on="on", role="button")
          .font-weight-medium(v-if="selectedCard") {{ selectedCard.brand }} ...{{ selectedCard.last4 }} {{ selectedCard.exp_month }}/{{ selectedCard.exp_year }}
          span(v-else) Select
          v-icon(right) mdi-menu-down
      v-card
        v-list.py-0(v-if="stripeSources && stripeSources.length")
          v-list-item(
            v-for="(item, index) in stripeSources",
            :key="index",
            @click="setCard(item)"
          )
            v-list-item-content
              v-list-item-title {{ item.brand }} ...{{ item.last4 }}
              v-list-item-subtitle {{ item.exp_month }}/{{ item.exp_year }}
            v-list-item-action
              v-icon(
                v-if="selectedCard && item.id == selectedCard.id",
                color="success"
              ) mdi-check
        v-card-text(v-else)
          CreateSource(@saved="saved")
    CreateSource(:roundIcon="true", @saved="saved")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CreateSource from "@/components/User/Payments/CreateSource";

export default {
  components: { CreateSource },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters(["cartOrder", "stripeSources"]),
    cartDue() {
      if (!this.cartOrder || !this.cartOrder.payment) return 0;
      const payment = this.cartOrder.payment;
      const paid = payment.charges.reduce((a, b) => a + b.amount, 0);
      return Math.round((payment.total - paid) * 100) / 100;
    },
    selectedCard() {
      const cardId = this.cartOrder?.payment?.card?.id;
      if (!cardId) return null;
      return this.stripeSources.find((o) => o.id == cardId);
    },
  },
  mounted() {
    this.setStripeSources([]);
    this.load();
  },
  methods: {
    ...mapActions(["setCard", "setStripeSources"]),
    async load() {
      const user = this.$auth.user();
      if (!user) return;
      try {
        const result = await this.$api.stripe.source.list({ userId: user._id });
        this.setStripeSources(result);
        this.initPayment();
      } catch (e) {
        //
      }
    },
    initPayment() {
      if (this.selectedCard?.length) return;
      this.setCard(this.stripeSources[0]);
    },
    saved(source) {
      this.setCard(source);
    },
  },
};
</script>
