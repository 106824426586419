<template lang="pug">
.body-2(v-if="cartAddressResult")
  .red--text(v-if="!cartAddressResult.status") {{ cartAddressResult.message }}
  .green--text(v-else-if="cartAddressResult.message") {{ cartAddressResult.message }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: { ...mapGetters(["cartAddressResult"]) },
};
</script>
