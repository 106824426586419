/**
 * Filter for price level
 */

import PriceRangeJSON from "../../json/PriceLevel.json";

function price_level(value) {
  const items = JSON.parse(JSON.stringify(PriceRangeJSON))

  const found = items.find(item => item.value == value)
  return found ? found.text : value;
}

export default price_level;