<template lang="pug">
div(v-if="biz")
  div {{ biz.address | address }}
  div(v-if="biz.phones && biz.phones.length") {{ biz.phones[0].number | phone }}
</template>

<script>
export default {
  props: ["biz"],
};
</script>
