export default function (input) {
  switch (input) {
    case 0:
      return 'Restaurant'
    case 1:
      return 'Food Truck'
    case 2:
      return 'Catering'
    case 3:
      return 'Commercial Kitchen'
    case 4:
      return 'Virtual Kitchen'
    case 100:
      return 'Nail Salon'
    case 101:
      return 'Hair Salon'
    case 200:
      return 'Car Wash'
    case 300:
      return 'Nursery'
    case 400:
      return 'Retail'
    default:
      return 'Not Defined'
  }
}