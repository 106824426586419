<template lang="pug">
.text-center.my-3(v-if="influencer")
  .influencer-logo
    v-img(:src="logo")
  .influencer-name Shop with {{ influencer.name }}
  .influencer-code Code: {{ influencer.code }}
</template>

<script>
export default {
  props: ["influencer"],
  computed: {
    logo() {
      if (this.influencer)
        return "https://media.selflane.com/" + this.influencer.logo;
      else return null;
    },
  },
};
</script>

<style lang="sass" scoped>
.influencer-logo
  margin-left: auto
  margin-right: auto
  height: 180px
  width: 180px
  position: relative
  border: 5px solid white
  border-radius: 90px
  cursor: pointer
  overflow: hidden

.influencer-name
  font-size: 32px
  font-weight: 600
  margin: 10px

.influencer-code
  font-size: 20px
  font-weight: 600
  margin: 10px
  color: grey
</style>