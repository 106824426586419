<template lang="pug">
.head-box(v-if="chain")
  img(:src="logoPath", v-if="logoPath")
  .subtitle-2.mx-2 {{ chain.name }}
  v-spacer
  v-btn(icon, :to="'/chains/' + chain.url")
    v-icon mdi-chevron-right
</template>

<script>
export default {
  props: ["chain"],
  data() {
    return {
      mediaHost: process.env.VUE_APP_MEDIAHOST,
    };
  },
  computed: {
    logoPath() {
      if (this.chain?.logo) return this.mediaHost + this.chain.logo;
      return null;
    },
  },
};
</script>

<style lang="sass" scoped>
.head-box
  display: flex
  padding: 12px
  align-items: center
  overflow: hidden

img
  flex: 0 0 80px
  width: 80px
  height: 80px
  border-radius: 6px
  overflow: hidden
  object-fit: cover
</style>