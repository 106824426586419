<template lang="pug">
v-container
  v-row(wrap)
    v-col(cols="12", md="8")
      Menu
    v-col(cols="12", md="4")
      WeeklySpecial.mb-5
      Coupons.mb-5
      GiftCards.mb-5
      Selected(@showCheckout="showMenu = false")
      SuggestedOrder
</template>

<script>
import Menu from "./Menu/index";
import Coupons from "./Coupons/index";
import Selected from "./Checkout/Selected/index";
import GiftCards from "./GiftCards/index";
import WeeklySpecial from "./WeeklySpecial/index";
import SuggestedOrder from "./SuggestedOrder";

export default {
  components: {
    Menu,
    Coupons,
    GiftCards,
    WeeklySpecial,
    Selected,
    SuggestedOrder,
  },
};
</script>
