<template lang="pug">
.text-center.pa-5
  img(:src="src")
  .text {{ text }}
</template>

<script>
export default {
  props: ["text"],
  data() {
    return {
      src: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Fempty.svg?alt=media&token=3040d75a-33c9-4075-874b-50c8f8eee9c4",
    };
  },
};
</script>

<style  lang="sass" scoped>
.text
  display: block
  margin-top: 18px
  font-size: 18px
  color: #8d8f99
  letter-spacing: 0
  text-align: center
</style>
