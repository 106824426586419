<template lang="pug">
v-container
  v-row(justify="center")
    v-card.text-center(
      max-width="500",
      width="90%",
      outlined,
      :loading="loading"
    )
      v-card-text
        v-icon(color="success", x-large) mdi-check-circle-outline
        .sl-title Member Benefits Unsubscribe Successful
        v-btn.text-capitalize.mt-10(
          to="/user/wallet/member",
          text,
          color="secondary"
        ) Manage Membership
</template>

<script>
export default {
  props: ["member_id"],
  data() {
    return {
      loading: false,
      member: null,
    };
  },
  mounted() {
    this.unsubscribe();
  },
  methods: {
    async unsubscribe() {
      if (!this.member_id) return;
      this.loading = true;
      const params = { memberId: this.member_id };
      try {
        const { data } = await this.axios.post("/member/unsubscribe", params);
        this.member = data;
        this.$toast.success("Unsubscribed");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>