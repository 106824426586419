<template lang="pug">
v-card(flat)
  v-card-text
    .section-title {{ event.title }}
    .mt-5
    .section-title Ticket Number: {{ ticket.ticket_number }}
    table
      tr
        td.pr-3
          v-icon(color="#402F5A", small) mdi-account
        td
          .section-description {{ ticket.user.name }}
        td.pl-3
          EditName(:ticket="ticket")
      tr
        td.pr-3
          v-icon(color="#402F5A", small) mdi-phone
        td
          .section-description {{ ticket.user.phone | phone }}
        td.pl-3
          EditPhone(:ticket="ticket")
    .section-description {{ ticket_item.name }} {{ ticket_item.price | currency }}
    InfoBlock.my-3(:event="event")
    v-btn.mr-3(
      :href="event_page",
      target="_blank",
      small,
      outlined,
      color="secondary"
    ) Event Page
    Download(
      :event="event",
      :ticket="ticket",
      :time_items="time_items",
      :ticket_item="ticket_item"
    )
    .mt-5
    //- .d-flex.flex-row.align-center 
    //-   AddToAppleWallet(
    //-     :event="event",
    //-     :ticket="ticket",
    //-     :time_items="time_items",
    //-     :ticket_item="ticket_item"
    //-   )
    //-   GooglePayPass.ml-3(
    //-     :event="event",
    //-     :ticket="ticket",
    //-     :time_items="time_items",
    //-     :ticket_item="ticket_item"
    //-   )
    //- .mt-5
    .caption Purchased: {{ ticket.created | datetime }}
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import Download from "./Download";
import AddToAppleWallet from "./AddToAppleWallet";
import GooglePayPass from "./GooglePayPass";
import EditName from "./EditName";
import EditPhone from "./EditPhone";
import InfoBlock from "/libs/components/Event/InfoBlock";

export default {
  components: {
    Download,
    AddToAppleWallet,
    GooglePayPass,
    EditName,
    EditPhone,
    InfoBlock,
  },
  props: ["ticket"],
  computed: {
    ...mapGetters(["events"]),
    event() {
      return this.events.find((o) => o._id == this.ticket.event_id);
    },
    event_page() {
      if (!this.event) return null;
      return "/event/" + this.event._id;
    },
    begin_date() {
      return moment(this.event.event_time.begin).format("ddd, MMM DD, YYYY");
    },
    begin_time() {
      return moment(this.event.event_time.begin).format("h:mm A");
    },
    end_date() {
      return moment(this.event.event_time.end).format("ddd, MMM DD, YYYY");
    },
    end_time() {
      return moment(this.event.event_time.end).format("h:mm A");
    },
    time_items() {
      if (this.begin_date == this.end_date)
        return [
          this.begin_date + " " + this.begin_time + " - " + this.end_time,
        ];
      return [
        this.begin_date + " " + this.begin_time,
        "to",
        this.end_date + " " + this.end_time,
      ];
    },
    ticket_item() {
      return this.event?.items.find((o) => o._id == this.ticket?.item_id);
    },
  },
  methods: {
    gotoEvent() {
      this.$router.push("/event/" + this.event._id);
    },
  },
};
</script>

<style lang="sass" scoped>
.section-title
  font-size: 15px
  font-weight: 600
  color: #402F5A

.section-description
  font-size: 14px
  font-weight: 400
  color: #402F5A
</style>