<template lang="pug">
.dateselector-container
  Cell(
    v-for="(item, index) in items",
    :key="index",
    :item="item",
    :selected="item.day == content",
    @click="selectDay(item)"
  )
</template>

<script>
import Cell from "./Cell";
import moment from "moment-timezone";
import BizUtils from "/libs/utils/biz.js";

export default {
  components: { Cell },
  props: {
    value: { type: Number, required: true },
    timezone: { type: String, required: true },
    biz: { type: Object, required: true },
  },
  data() {
    return {
      content: moment.tz(this.value, this.timezone).format("YYYY-MM-DD"),
    };
  },
  computed: {
    items() {
      return [...Array(7).keys()].map((o) => {
        const m = moment().tz(this.timezone).add(o, "days");
        const today = o == 0;
        const schedule = BizUtils.getDaySchedule(
          this.biz?.schedule,
          m,
          this.timezone
        );
        const disabled = !schedule?.status;
        return {
          today, // boolean, is today
          ddd: m.format("ddd"),
          dayofmonth: m.format("D"),
          day: m.format("YYYY-MM-DD"),
          disabled,
        };
      });
    },
  },
  watch: {
    value() {
      this.content = moment.tz(this.value, this.timezone).format("YYYY-MM-DD");
    },
  },
  methods: {
    selectDay(item) {
      if (item.disabled) return;
      const hhmm = moment.tz(this.value, this.timezone).format("hh:mm A");
      const combined = `${item.day} ${hhmm}`;
      this.content = moment
        .tz(combined, "YYYY-MM-DD hh:mm A", this.timezone)
        .valueOf();
      this.$emit("input", this.content);
    },
  },
};
</script>

<style lang="sass" scoped>
// flex align row
.dateselector-container
  display: flex
  flex-direction: row
  justify-content: space-between
  gap: 4px
  padding: 0 12px
  overflow-x: auto
  overflow-y: hidden
</style>