<template lang="pug">
v-badge(
  overlap,
  color="#FF6D32",
  offset-x="20",
  offset-y="23",
  :value="cartCount"
)
  template(v-slot:badge)
    span {{ cartCount }}
  v-btn(icon, @click="showCart()", color="primary")
    v-icon mdi-cart
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "NavBarCart",
  computed: {
    ...mapGetters(["cartOrder", "cartCount", "deviceId", "bag"]),
  },
  mounted() {
    this.initDeviceId();
    // this.initCart();
  },
  methods: {
    ...mapActions(["initDeviceId", "setBag"]),
    showCart() {
      if (this.cartCount) EventBus.$emit("show-cart-sheet");
    },
    async initCart() {
      await this.initDeviceId();
      const deviceId = this.deviceId;
      const userId = this.$auth.user()?._id;
      let params;
      if (!deviceId && !userId) return;
      if (userId) {
        params = { criteria: { "customer.id": userId } };
      } else {
        params = { criteria: { deviceId } };
      }
      const result = await this.$api.shoppingBag.retrieve(params);
      if (result) this.setBag(result);
      // else this.migrateCart();
    },
    async migrateCart() {
      if (!this.cartOrder?.dishes?.length) return;
      const order = {
        deviceId: this.deviceId,
        biz: this.cartOrder.seller.id,
        customer: this.cartOrder.customer,
        catering: this.cartOrder.catering,
        type: this.cartOrder.type,
        dishes: this.cartOrder.dishes,
        gifts: this.cartOrder.gifts,
      };
      const result = await this.$api.shoppingBag.create(order);
      if (result) this.setBag(result);
    },
  },
};
</script>
