<template lang="pug">
v-dialog(v-model="dialog", width="300")
  v-card
    v-card-title
    v-card-text
      v-form(@submit.prevent="submit")
        v-text-field(
          label="Email",
          v-model="email",
          :error-messages="emailErrors",
          @blur="$v.email.$touch()",
          autofocus,
          ref="focus"
        )
        v-btn(block, :disabled="$v.$invalid", type="submit", color="secondary") save
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  props: ["open"],
  validations: { email: { required, email } },
  data() {
    return { email: "", dialog: false };
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("Email is required");
      !this.$v.email.email && errors.push("Invalid email address");
      return errors;
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.dialog = true;
        this.email = "";
        this.$v.$reset();
        this.$nextTick(() => this.$refs.focus.focus());
      }
    },
    dialog(val) {
      if (!val) {
        this.$emit("close");
        this.$v.$reset();
      }
    },
  },
  methods: {
    submit() {
      this.$emit("save", this.email);
      this.dialog = false;
    },
  },
};
</script>
