const state = {
  press_reports: []
};

const getters = {
  press_reports: state => state.press_reports
};

const actions = {
  setPressReports: ({ commit }, data) => { commit("setPressReports", data); },
  addPressReport: ({ commit }, data) => { commit("addPressReport", data); },
  updatePressReport: ({ commit }, data) => { commit("updatePressReport", data); },
  removePressReport: ({ commit }, data) => { commit("removePressReport", data); },
};

const mutations = {
  setPressReports(state, data) { state.press_reports = data; },
  addPressReport(state, data) { state.press_reports.push(data); },
  updatePressReport(state, data) {
    state.press_reports = state.press_reports.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  removePressReport(state, data) {
    state.press_reports = state.press_reports.filter(o => o._id !== data._id);
  }
};

export default { state, getters, actions, mutations };