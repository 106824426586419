// Show the associated chain for the business.
<template lang="pug">
v-dialog(v-model="dialog", width="450")
  v-card(v-if="chain", outlined, :loading="loading")
    Head(:chain="chain")
    v-card-text We have multiple locations. Please select the closest for your order.
    v-list.py-0(dense)
      div(
        v-for="(item, index) in bizs",
        :key="item._id",
        :biz="item",
        :index="index"
      )
        v-divider(v-if="index != 0")
        Cell(:item="item", :distance="item.distance", @select="dialog = false")
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Head from "./Head";
import Cell from "./Cell";
import _ from "underscore";
import BizUtils from "/libs/utils/biz.js";
import { EventBus } from "@/event-bus.js";

export default {
  components: { Head, Cell },
  data() {
    return {
      dialog: false,
      chain: null,
      bizs: [],
      lastChainId: null,
      loading: false,
    };
  },
  computed: { ...mapGetters(["biz", "userLocation"]) },
  mounted() {
    EventBus.$on("show-chain-dialog", this.open);
  },
  destroyed() {
    EventBus.$off("show-chain-dialog", this.open);
  },
  methods: {
    ...mapActions(["setUserLocation"]),
    open(val) {
      if (!val) return;
      this.chain = val;
      this.dialog = true;
      if (this.lastChainId != this.chain._id) this.loadBizs();
    },
    async loadBizs() {
      if (!this.chain) return;
      this.loading = true;
      this.lastChainId = this.chain._id;
      const params = {
        criteria: { _id: { $in: this.chain.bizs } },
        select: "name short_name address url status",
      };
      const result = await this.$api.biz.list(params);
      this.bizs =
        result?.filter((o) => o.status.byAdmin && o.status.byUser) || [];
      await this.sortDistance();
      this.loading = false;
    },
    async sortDistance() {
      try {
        if (!this.userLocation?.lat) {
          const position = await this.getPosition();
          this.setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        }
        if (this.userLocation?.lat) {
          this.bizs.forEach((item) => {
            const distance = BizUtils.geoDistanceInMeter(
              item.address.geometry,
              this.userLocation
            );
            item.distance = distance;
          });
          this.bizs = _.sortBy(this.bizs, "distance");
        } else {
          this.bizs = _.sortBy(this.bizs, "name");
        }
      } catch (e) {
        this.bizs = _.sortBy(this.bizs, "name");
      }
    },
    async getPosition() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
    },
  },
};
</script>
