<template lang="pug">
v-select(v-model="content", :items="items", hide-details, dense)
</template>

<script>
export default {
  name: "HalfHalfPicker",
  props: ["value"],
  data() {
    return {
      content: this.value,
      items: [
        { value: 0, text: "Left" },
        { value: 1, text: "Full" },
        { value: 2, text: "Right" },
      ],
    };
  },
  watch: {
    value() {
      this.content = this.value;
    },
    content(newval, oldval) {
      if (newval == oldval) return;
      this.$emit("input", this.content);
      this.$emit("change");
    },
  },
};
</script>
