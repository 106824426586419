<template lang="pug">
.d-flex.flex-row.my-5
  Language
  v-spacer
  Social
</template>

<script>
import Language from "./Language";
import Social from "./Social";
export default {
  components: { Language, Social },
};
</script>
