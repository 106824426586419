import moment from 'moment-timezone'

function datetime(input, format, timezone) {
  if (input == null) return ''
  let m
  // if input is a string and is exactly 14 digits long, parse as 'YYYYMMDDHHmmss'
  // used for PAX log timestamps
  if (typeof input === 'string' && input.match(/^[0-9]{14}$/)) {
    m = moment(input, 'YYYYMMDDHHmmss')
  } else if (typeof input == 'number' && input.toString().length === 14) {
    m = moment(input.toString(), 'YYYYMMDDHHmmss')
  } else if (typeof input === 'string' && input.match(/^[0-9a-fA-F]{24}$/)) {
    m = moment(parseInt(input.substring(0, 8), 16) * 1000)
  } else {
    m = moment(input)
  }
  if (m.isValid()) {
    if (format == 'smart') {
      const now = moment()
      const today = moment().startOf('day')
      const yesterday = moment().subtract(1, 'day').startOf('day')
      const tomorrow = moment().add(1, 'day').startOf('day')
      const thisYear = moment().startOf('year')
      const lastYear = moment().subtract(1, 'year').startOf('year')
      const nextYear = moment().add(1, 'year').startOf('year')
      if (m.isSame(now, 'day')) {
        return m.format('h:mm A')
      } else if (m.isSame(today, 'day')) {
        return m.format('[Today] h:mm A')
      } else if (m.isSame(yesterday, 'day')) {
        return m.format('[Yesterday] h:mm A')
      } else if (m.isSame(tomorrow, 'day')) {
        return m.format('[Tomorrow] h:mm A')
      } else if (m.isSame(thisYear, 'year')) {
        return m.format('MMM D h:mm A')
      } else if (m.isSame(lastYear, 'year')) {
        return m.format('MMM D, YYYY h:mm A')
      } else if (m.isSame(nextYear, 'year')) {
        return m.format('MMM D, YYYY h:mm A')
      } else {
        return m.format('MMM D, YYYY h:mm A')
      }
    }
    if (timezone) m.tz(timezone).format(format || 'M/D/YYYY h:mm A')
    return m.format(format || 'M/D/YYYY h:mm A')
  }
  return input
}

export default datetime