function handler(modifiers) {
  if (!modifiers) return ''
  const arr = []
  modifiers.forEach(o => {
    const formatted = formatModifier(o)
    if (formatted) arr.push(formatted)
  })
  return arr.join('; ')
}

// Format a modifier
function formatModifier(modifier) {
  let text = modifier.choices.filter(o => o.selected).map(o => getName(o)).join(', ')
  if (!text) return ''

  const some = modifier.choices.some(o => o.selected && !o.hsc)
  if (!some) return text
  if (!modifier.associatedChoices?.length) return text

  const noSubchoices = modifier.associatedChoices
    .filter((o) => o.isDefault && !o.selected)
    .map((o) => `No ${getName(o)}`).join(', ')
  if (noSubchoices) text += ` (${noSubchoices})`

  const editedSubchoices = modifier.associatedChoices
    .filter((o) => o.isDefault && o.selected && (o.quantity !== 1 || o.halfhalf !== 1))
    .map((o) => `Edit ${getName(o)}`)
    .join(', ')
  if (editedSubchoices) text += ` (${editedSubchoices})`
  const subchoices = modifier.associatedChoices
    .filter((o) => !o.isDefault && o.selected)
    .map((o) => `Add ${getName(o)}`)
    .join(', ')
  if (subchoices) text += ` (${subchoices})`

  return text
}

// Format choice/subchoice name
function getName(choice) {
  let name = ''
  if (choice.quantity > 1) name += `${choice.quantity}X `
  // halfhalf property is only available for subchoices
  if (choice.halfhalf === 0) name += 'H1-'
  else if (choice.halfhalf === 2) name += 'H2-'
  name += choice.name || ''
  return name
}

export default handler