<template lang="pug">
div(v-if="eventCart && eventCart.payment")
  .body-2.mb-2 Payment
  .d-flex.flex-row.align-center.justify-space-between
    v-menu(v-model="dialog")
      template(v-slot:activator="{ on }")
        .d-flex.flex-row.align-center.mr-5(v-on="on", role="button")
          .font-weight-medium(v-if="selectedCard") {{ selectedCard.brand }} ...{{ selectedCard.last4 }} {{ selectedCard.exp_month }}/{{ selectedCard.exp_year }}
          span(v-else) Select
          v-icon(right) mdi-menu-down
      v-card
        v-list.py-0(v-if="stripeSources && stripeSources.length")
          v-list-item(
            v-for="(item, index) in stripeSources",
            :key="index",
            @click="setCard(item)"
          )
            v-list-item-content
              v-list-item-title {{ item.brand }} ...{{ item.last4 }}
              v-list-item-subtitle {{ item.exp_month }}/{{ item.exp_year }}
            v-list-item-action
              v-icon(
                v-if="selectedCard && item.id == selectedCard.id",
                color="success"
              ) mdi-check
        v-card-text(v-else)
          CreateSource(@saved="saved")
    CreateSource(:roundIcon="true", @saved="saved")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CreateSource from "@/components/User/Payments/CreateSource";
import _ from "underscore";

export default {
  components: { CreateSource },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters(["eventCart", "stripeSources"]),
    selectedCard() {
      const cardId = this.eventCart?.payment?.card?.id;
      if (!cardId) return;
      return this.stripeSources.find((o) => o.id == cardId);
    },
  },
  mounted() {
    this.setStripeSources([]);
    this.load();
  },
  methods: {
    ...mapActions(["setStripeSources"]),
    async load() {
      const user = this.$auth.user();
      if (!user) return;
      const params = { userId: user._id };
      const result = await this.$api.stripe.source.list(params);
      this.setStripeSources(result);
      this.initPayment();
    },
    initPayment() {
      if (this.selectedCard) return;
      const first = _.first(this.stripeSources);
      if (first) this.saved(first);
    },
    saved(card) {
      const { id, last4, brand } = card;
      this.eventCart.payment.card = { id, last4, brand, type: 0 };
    },
  },
};
</script>
