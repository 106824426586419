<template lang="pug">
v-btn.text-capitalize(
  v-if="promotion",
  block,
  :color="color",
  outlined,
  @click="setPromotion(promotion)"
)
  span {{ title }}
  v-icon(right, small, v-if="selected") mdi-check
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["promotion"],
  computed: {
    ...mapGetters(["cartPromotion"]),
    title() {
      if (this.promotion.type === "fix") {
        return `Save $${this.promotion.fix}`;
      }
      return `Save ${this.promotion.pct}%`;
    },
    selected() {
      return (
        this.cartPromotion && this.cartPromotion._id === this.promotion?._id
      );
    },
    color() {
      /// if is selected promotion, set to primary btn, otherwise use default btn
      if (this.selected) return "success";
      return "secondary";
    },
  },
  methods: {
    ...mapActions(["setPromotion"]),
  },
};
</script>
