<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="charge")
    v-card-title Charge Details
    v-card-text
      p Amount: {{ charge.amount | currency }}
        br
        span(v-if="charge.last4") Card: {{ charge.last4 }}
          br
        | Method: {{ charge.method }}
      div(v-if="allowed")
        .sl-dash-divider.my-2
        .subtitle-2 Void a Charge
        p Are you sure you want to void this charge? Please note that this action cannot be undone.
        v-switch(
          v-model="confirmed",
          label="Confirm",
          color="error",
          :disabled="!allowed"
        )
    v-card-actions(v-if="allowed")
      v-btn(
        color="error",
        block,
        @click="submit",
        :disabled="!allowed || !confirmed"
      ) Void
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      charge: null,
      order: null,
      checkId: null,
      confirmed: false,
      dialog: false,
      loading: false,
    };
  },
  computed: {
    allowed() {
      if (!this.charge || !this.order) return false;
      // cutoff time is 30 days
      const cutoff = new Date();
      cutoff.setDate(cutoff.getDate() - 30);
      if (this.order.needed < cutoff.getTime()) return false;
      const methods = ["cash", "check", "gift", "excard", "reward", "stripe"];
      return methods.includes(this.charge.method);
    },
  },
  mounted() {
    EventBus.$on("void-charge", this.open);
  },
  destroyed() {
    EventBus.$off("void-charge", this.open);
  },
  methods: {
    ...mapActions(["setOrder"]),
    open(charge, order, checkId) {
      this.charge = charge;
      this.order = order;
      this.checkId = checkId;
      if (!this.charge || !this.order) {
        this.$toast.error("Invalid charge or order");
        return;
      }
      this.dialog = true;
    },
    async submit() {
      this.loading = true;
      let params;
      let path;
      try {
        if (this.charge.method == "stripe") await this.voidStripe();
        if (this.checkId) {
          params = {
            orderId: this.order._id,
            checkId: this.checkId,
            chargeId: this.charge._id,
          };
          path = "voidChargeCheck";
        } else {
          params = {
            orderId: this.order._id,
            chargeId: this.charge._id,
          };
          path = "voidCharge";
        }
        const result = await this.$api.order.custom(path, params);
        this.setOrder(result);
        this.$toast.success("Charge voided");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async voidStripe() {
      const params = { stripeId: this.charge.transId };
      await this.$api.stripe.paymentIntent.cancel(params);
    },
  },
};
</script>