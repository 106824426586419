<template lang="pug">
v-menu
  template(v-slot:activator="{ on }")
    v-btn(v-on="on", outlined, small, color="secondary")
      span {{ years[index] }}
      v-icon(right) mdi-chevron-down
  v-card(max-height="400", width="120")
    v-list.py-0(dense)
      v-list-item(
        v-for="(item, index) in years",
        :key="index",
        @click="select(index)"
      )
        v-list-item-content
          v-list-item-title {{ item }}
</template>

<script>
/**
 * Select a year from 2017 - Current year
 * return YYYY
 */
import _ from "underscore";

export default {
  name: "YearSelector",
  data() {
    return {
      years: _.range(new Date().getFullYear(), 2016),
      index: 0,
    };
  },
  mounted() {
    this.select(0);
  },
  methods: {
    select(index) {
      this.index = index;
      this.$emit("select", this.years[index]);
    },
  },
};
</script>
