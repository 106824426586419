<template lang="pug">
v-card(@click="goto", flat)
  .img-container
    v-img(:src="poster", height="180", contain)
  v-card-text
    .event-title {{ item.title }}
    .start-time.primary--text {{ item.event_time.begin | datetime("ddd, MMM D, h:mm A") }}
    .biz-info(v-if="biz") {{ item.address.name }} &bull; {{ item.address.city }}, {{ item.address.state }}
    .biz-info Starts at {{ start_price | currency }}
    .biz-name.mt-2(v-if="biz") {{ biz.name }}
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
export default {
  props: ["item"],
  computed: {
    ...mapGetters(["bizAccounts"]),
    poster() {
      if (this.item?.poster) {
        return "https://media.selflane.com/" + this.item.poster;
      }
      return null;
    },
    biz() {
      return this.bizAccounts.find((o) => o._id == this.item.biz_id);
    },
    start_price() {
      const prices = _.chain(this.item.items).pluck("price").sortBy().value();
      if (!prices || !prices.length) return null;
      return prices[0];
    },
  },
  methods: {
    goto() {
      this.$router.push("/event/" + this.item._id);
    },
  },
};
</script>

<style lang="sass" scoped>
.img-container
  border-bottom: 2px solid #F0F2F5

.event-title
  font-size: 18px
  font-weight: 800
  color: #39364F

.start-time
  font-size: 14px
  font-weight: 600
  height: 28px

.biz-info
  font-size: 13px
  color: #9496A6

.biz-name
  font-size: 14px
  font-weight: 600
  color: #3D3A52
</style>