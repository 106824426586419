<template lang="pug">
a.ml-3(@click="showTracking", v-if="isDelivery")
  img(:src="icon")
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  props: ["order"],

  data() {
    return {
      icon: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Ftracking_square.svg?alt=media&token=18e51850-37f1-4d0f-8d3e-52ff353e360e",
    };
  },
  computed: {
    isDelivery() {
      return this.order && this.order.type == "delivery";
    },
  },
  methods: {
    showTracking() {
      if (!this.order) return;
      EventBus.$emit("show-deliv-order-detail", this.order);
    },
  },
};
</script>
