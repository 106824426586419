<template lang="pug">
.coupon-logo
  v-img(:src="mediaHost + logo", height="100%", contain, v-if="logo")
</template>

<script>
export default {
  props: ["promotion", "bizs", "chains"],
  data() {
    return {
      mediaHost: process.env.VUE_APP_MEDIAHOST,
    };
  },
  computed: {
    logo() {
      if (!this.promotion) return "";
      if (this.promotion.chain) {
        return (
          this.chains?.find((o) => o._id == this.promotion.chain)?.logo || ""
        );
      } else {
        return this.bizs?.find((o) => o._id == this.promotion.biz)?.logo || "";
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.coupon-logo
  width: 70px
  height: 70px
  border-radius: 6px 6px 6px 6px
  overflow: hidden
</style>
