<template lang="pug">
a(@click="show")
  img(:src="icon")
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  props: ["order"],
  data() {
    return {
      icon: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Fqrcode_square.svg?alt=media&token=183eaa78-2ea7-4f7c-84ea-39333837b4b8",
    };
  },
  methods: {
    show() {
      if (!this.order) return;
      EventBus.$emit(
        "show-qrcode",
        "sl:oid:" + this.order._id,
        "Order QR Code"
      );
    },
  },
};
</script>
