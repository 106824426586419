<template lang="pug">
div
  .d-flex.flex-row.align-center.justify-space-between.my-5
    .sl-title Credit and Debit Cards
    CreateSource
  v-card(v-if="stripeSources && stripeSources.length", flat)
    v-list.py-0(dense)
      v-list-item(v-for="(item, index) in stripeSources", :key="index")
        v-list-item-content
          v-list-item-title {{ item.brand }} ...{{ item.last4 }} {{ item.name }}
          v-list-item-subtitle {{ item.exp_month }}/{{ item.exp_year }}
        v-list-item-action
          .d-flex.flex-row
            v-btn(icon, @click="edit(item)")
              v-icon(small) mdi-pencil
            DeleteSource(:source="item")
  Update
  Notice
</template>

<script>
import CreateSource from "@/components/User/Payments/CreateSource";
import DeleteSource from "@/components/User/Payments/DeleteSource";
import Update from "./Update";
import Notice from "./Notice";
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  components: { CreateSource, DeleteSource, Update, Notice },
  computed: { ...mapGetters(["stripeSources"]) },
  mounted() {
    this.setStripeSources([]);
    this.load();
  },
  methods: {
    ...mapActions(["setStripeSources"]),
    async load() {
      const user = this.$auth.user();
      if (!user) return;
      const params = { userId: user._id };
      const result = await this.$api.stripe.source.list(params);
      this.setStripeSources(result);
    },
    edit(item) {
      EventBus.$emit("edit-stripe-source", item);
    },
  },
};
</script>
