class BaseApiService {
  constructor(resource) {
    this.baseUrl = process.env.VUE_APP_MAIN_API
    if (!resource) {
      throw new Error('Resource is required')
    }
    this.resource = resource
  }

  getUrl() {
    return `${this.baseUrl}/${this.resource}`
  }
}

class ModelApiService extends BaseApiService {
  constructor(resource) {
    super(resource)
  }

  async create(params) {
    const { data } = await window.axios.post(this.getUrl() + '/create', params)
    return data
  }
  async get(id, select) {
    const url = this.getUrl() + '/' + id + (select ? '?select=' + select : '')
    const { data } = await window.axios.get(url)
    return data
  }
  async put(id, params) {
    const { data } = await window.axios.put(this.getUrl() + '/' + id, params)
    return data
  }
  async update(params) {
    const { data } = await window.axios.post(this.getUrl() + '/update', params)
    return data
  }
  async retrieve(params) {
    const { data } = await window.axios.post(this.getUrl() + '/retrieve', params)
    return data
  }
  async list(params) {
    const { data } = await window.axios.post(this.getUrl() + '/list', params)
    return data
  }
  async delete(params) {
    if (typeof params === 'string') {
      const { data } = await window.axios.delete(this.getUrl() + '/' + params)
      return data
    }
    const { data } = await window.axios.post(this.getUrl() + '/delete', params)
    return data
  }
  async deleteMany(params) {
    const { data } = await window.axios.post(this.getUrl() + '/deleteMany', params)
    return data
  }
  async count(params) {
    const { data } = await window.axios.post(this.getUrl() + '/count', params)
    return data
  }
  async cancel(params) {
    const { data } = await window.axios.post(this.getUrl() + '/cancel', params)
    return data
  }
  async custom(path, params) {
    const { data } = await window.axios.post(this.getUrl() + '/' + path, params)
    return data
  }
}

class ShoppingBagApiService extends ModelApiService {
  constructor() { super('shopping/bag') }
}
class BlacklistApiService extends ModelApiService {
  constructor() { super('blacklist/user') }
}
class OrderApiService extends ModelApiService {
  constructor() { super('orders') }
}

class CardConnect extends BaseApiService {
  constructor() {
    super('cardpointe/cardconnect')
  }
  async inquireMerchant(params) {
    const { data } = await window.axios.post(this.getUrl() + '/inquireMerchant', params)
    return data
  }
  async auth(params) {
    const { data } = await window.axios.post(this.getUrl() + '/auth', params)
    return data
  }
  async capture(params) {
    const { data } = await window.axios.post(this.getUrl() + '/capture', params)
    return data
  }
  async voidCharge(params) {
    const { data } = await window.axios.post(this.getUrl() + '/voidCharge', params)
    return data
  }
  async refund(params) {
    const { data } = await window.axios.post(this.getUrl() + '/refund', params)
    return data
  }
  async inquire(params) {
    const { data } = await window.axios.post(this.getUrl() + '/inquire', params)
    return data
  }
  async inquireByOrderid(params) {
    const { data } = await window.axios.post(this.getUrl() + '/inquireByOrderid', params)
    return data
  }
  async voidByOrderId(params) {
    const { data } = await window.axios.post(this.getUrl() + '/voidByOrderId', params)
    return data
  }
  async settlestat({ merchid, date, batchid }) {
    let url = this.getUrl() + '/settlestat'
    if (date) {
      url += `?merchid=${merchid}&date=${date}`
    } else {
      url += `?merchid=${merchid}&batchid=${batchid}`
    }
    const { data } = await window.axios.get(url)
    return data
  }
  async createProfile(params) {
    const { data } = await window.axios.post(this.getUrl() + '/profile/create', params)
    return data
  }
  async getProfile(params) {
    const { data } = await window.axios.post(this.getUrl() + '/profile/get', params)
    return data
  }
  async deleteProfile(params) {
    const { data } = await window.axios.post(this.getUrl() + '/profile/delete', params)
    return data
  }
  async sigcap(params) {
    const { data } = await window.axios.post(this.getUrl() + '/sigcap', params)
    return data
  }
  async bin(params) {
    const { data } = await window.axios.post(this.getUrl() + '/bin', params)
    return data
  }
}

class Bolt extends BaseApiService {
  constructor() {
    super('cardpointe/bolt')
  }
  async listTerminals(params) {
    const { data } = await window.axios.post(this.getUrl() + '/listTerminals', params)
    return data
  }
  async terminalDetails(params) {
    const { data } = await window.axios.post(this.getUrl() + '/terminalDetails', params)
    return data
  }
  async dateTime(params) {
    const { data } = await window.axios.post(this.getUrl() + '/dateTime', params)
    return data
  }
  async getPanPadVersion(params) {
    const { data } = await window.axios.post(this.getUrl() + '/getPanPadVersion', params)
    return data
  }
  async ping(params) {
    const { data } = await window.axios.post(this.getUrl() + '/ping', params)
    return data
  }
  async connect(params) {
    const { data } = await window.axios.post(this.getUrl() + '/connect', params)
    return data
  }
  async disconnect(params) {
    const { data } = await window.axios.post(this.getUrl() + '/disconnect', params)
    return data
  }
  async display(params) {
    const { data } = await window.axios.post(this.getUrl() + '/display', params)
    return data
  }
  async clearDisplay(params) {
    const { data } = await window.axios.post(this.getUrl() + '/clearDisplay', params)
    return data
  }
  async readConfirmation(params) {
    const { data } = await window.axios.post(this.getUrl() + '/readConfirmation', params)
    return data
  }
  async readInput(params) {
    const { data } = await window.axios.post(this.getUrl() + '/readInput', params)
    return data
  }
  async readSignature(params) {
    const { data } = await window.axios.post(this.getUrl() + '/readSignature', params)
    return data
  }
  async cancel(params) {
    const { data } = await window.axios.post(this.getUrl() + '/cancel', params)
    return data
  }
  async readCard(params) {
    const { data } = await window.axios.post(this.getUrl() + '/readCard', params)
    return data
  }
  async readManual(params) {
    const { data } = await window.axios.post(this.getUrl() + '/readManual', params)
    return data
  }
  async authCard(params) {
    const { data } = await window.axios.post(this.getUrl() + '/authCard', params)
    return data
  }
  async authManual(params) {
    const { data } = await window.axios.post(this.getUrl() + '/authManual', params)
    return data
  }
  async tip(params) {
    const { data } = await window.axios.post(this.getUrl() + '/tip', params)
    return data
  }
  async printReceipt(params) {
    const { data } = await window.axios.post(this.getUrl() + '/printReceipt', params)
    return data
  }
}

export const $api = {
  user: new ModelApiService('users'),
  b: {
    domain: new ModelApiService('bizs/domain'),
    request: new ModelApiService('bizs/request'),
    throttle: new ModelApiService('bizs/throttles'),
    log: new ModelApiService('bizs/log'),
    access: new ModelApiService('bizs/access'),
    action: new ModelApiService('bizs/actions'),
    document: new ModelApiService('bizs/documents'),
    opsmonth: new ModelApiService('bizs/opsmonth'),
    rsvtsetting: new ModelApiService('bizs/rsvtsetting'),
    rsvtoption: new ModelApiService('bizs/rsvtoption'),
  },
  biz: new ModelApiService('bizs'),
  bizAccount: new ModelApiService('bizaccount'),
  bizAccess: new ModelApiService('bizs/access'),
  bizService: new ModelApiService('bizService'),
  bizModifier: new ModelApiService('bizModifiers'),
  bizRequest: new ModelApiService('bizs/request'),
  bizOrder: new ModelApiService('bizOrder'),
  bizTransfer: new ModelApiService('bizs/transfers'),
  bizBackup: new ModelApiService('bizs/backup'),
  chain: {
    account: new ModelApiService('chain'),
    access: new ModelApiService('chain/access'),
  },
  entp: {
    enterprise: new ModelApiService('enterprise/account'),
    biz: new ModelApiService('enterprise/biz'),
    access: new ModelApiService('enterprise/access'),
    region: new ModelApiService('enterprise/region'),
    log: new ModelApiService('enterprise/log'),
  },
  city: new ModelApiService('city'),
  poi: new ModelApiService('geoService/poi'),
  matrixModifier: new ModelApiService('matrixmodifier'),
  delivery: new ModelApiService('deliv'),
  driver: new ModelApiService('driver/accounts'),
  member: new ModelApiService('member'),
  memberPromo: new ModelApiService('memberpromo'),
  memberProgram: new ModelApiService('member/programs'),
  memberTier: new ModelApiService('membertier'),
  m: {
    pointearn: new ModelApiService('pointearn'),
    pointredeem: new ModelApiService('pointredeem'),
  },
  invoice: new ModelApiService('invoices'),
  shoppingBag: new ShoppingBagApiService(),
  blacklist: new BlacklistApiService(),
  bouncingEmail: new ModelApiService('blacklist/email'),
  order: new OrderApiService(),
  reservation: new ModelApiService('reservations'),
  expense: {
    entry: new ModelApiService('bizExpenses'),
    source: new ModelApiService('bizExpenseSources'),
  },
  delivOrder: new ModelApiService('delivOrders'),
  giftcard: new ModelApiService('giftcard'),
  promotion: new ModelApiService('promotion'),
  picture: new ModelApiService('pictures'),
  pointVoucher: new ModelApiService('pointvoucher'),
  pointShop: new ModelApiService('pointShop'),
  review: new ModelApiService('reviews'),
  pos: new ModelApiService('pos'),
  posDevice: new ModelApiService('pos/devices'),
  posPrinterAction: new ModelApiService('pos/printeractions'),
  posPrinter: new ModelApiService('pos/printers'),
  posJob: new ModelApiService('pos/jobs'),
  punchcard: new ModelApiService('punchcards'),
  serverShift: new ModelApiService('serverShift'),
  serverChecklist: new ModelApiService('serverchecklist'),
  ingredient: new ModelApiService('menu/ingredients'),
  recipe: new ModelApiService('menu/recipes'),
  voucher: new ModelApiService('vouchers'),
  successStory: new ModelApiService('corp/success_story'),
  podcast: new ModelApiService('corp/podcast'),
  announcement: new ModelApiService('announcement'),
  geoZone: new ModelApiService('geoService/zones'),
  taxFile: new ModelApiService('tax/taxfile'),
  chargeback: new ModelApiService('orders/chargeback'),
  menu: {
    itemCount: new ModelApiService('menu/item-count'),
    itemLimit: new ModelApiService('menu/item-limit'),
    marker: new ModelApiService('menu/markers'),
    combo: new ModelApiService('bizcombo'),
  },
  hr: {
    setting: new ModelApiService('hr/settings'),
    person: new ModelApiService('servers'),
  },
  event: {
    entry: new ModelApiService('event/entries'),
    ticket: new ModelApiService('event/tickets'),
    order: new ModelApiService('event/orders'),
  },
  corp: {
    access: new ModelApiService('corp/accesses'),
    sampleEmail: new ModelApiService('corp/tool/email_sample'),
    log: new ModelApiService('corp/log'),
    debugLog: new ModelApiService('corp/debug_log'),
    pressReport: new ModelApiService('press/report'),
  },
  influencer: {
    account: new ModelApiService('influencer/accounts'),
    person: new ModelApiService('influencer/persons'),
    plan: new ModelApiService('influencer/plans'),
    shop: new ModelApiService('influencer/shops'),
    commission: new ModelApiService('influencer/commissions'),
  },
  paxTrans: new ModelApiService('paxTrans'),
  transaction: {
    batch: new ModelApiService('transaction/pax/batch'),
    missed: new ModelApiService('transaction/pax/missed'),
    stripe: new ModelApiService('transaction/stripe'),
    refund: new ModelApiService('creditReturn'),
  },
  salesforce: {
    organization: new ModelApiService('salesforce/organizations'),
    person: new ModelApiService('salesforce/persons'),
    invoice: new ModelApiService('salesforce/invoices'),
  },
  crm: {
    contact: new ModelApiService('crm/contact'),
    lead: new ModelApiService('crm/lead'),
    opportunity: new ModelApiService('crm/opportunity'),
    interaction: new ModelApiService('crm/interaction'),
    serviceLog: new ModelApiService('crm/service_log'),
    log: new ModelApiService('crm/log'),
  },
  service: {
    account: new ModelApiService('bizService/accounts'),
    plan: new ModelApiService('bizService/plans'),
    item: new ModelApiService('bizService/items'),
    subscription: new ModelApiService('bizService/subscriptions'),
  },
  catalog: {
    product: new ModelApiService('catalog/product'),
    group: new ModelApiService('catalog/group'),
    subgroup: new ModelApiService('catalog/subgroup'),
    stateTax: new ModelApiService('catalog/stateTax'),
    inventory: {
      in: new ModelApiService('catalog/inventory/in'),
      out: new ModelApiService('catalog/inventory/out'),
      misc: new ModelApiService('catalog/inventory/misc'),
    }
  },
  merchant: {
    account: new ModelApiService('merchant/accounts'),
    income: new ModelApiService('merchant/incomes'),
  },
  partner: {
    account: new ModelApiService('partner/accounts'),
    payout: new ModelApiService('partner/payouts'),
  },
  stripe: {
    account: new ModelApiService('stripe/accounts'),
    accountLink: new ModelApiService('stripe/account-link'),
    charges: new ModelApiService('stripe/charges'),
    disputes: new ModelApiService('stripe/disputes'),
    files: new ModelApiService('stripe/files'),
    person: new ModelApiService('stripe/persons'),
    source: new ModelApiService('stripe/sources'),
    bankaccount: new ModelApiService('stripe/bankaccounts'),
    balance: new ModelApiService('stripe/balance'),
    payout: new ModelApiService('stripe/payouts'),
    paymentIntent: new ModelApiService('stripe/paymentIntent'),
    terminal: new ModelApiService('stripe/terminal'),
    terminalLocation: new ModelApiService('stripe/terminal/locations'),
    terminalReader: new ModelApiService('stripe/terminal/readers'),
  },
  cardpointe: {
    cardconnect: new CardConnect(),
    bolt: new Bolt(),
    terminal: new ModelApiService('cardpointe/terminal'),
  },
  seven: {
    user: new ModelApiService('sevenShifts/user'),
    company: new ModelApiService('sevenShifts/company'),
    location: new ModelApiService('sevenShifts/location'),
    department: new ModelApiService('sevenShifts/department'),
    role: new ModelApiService('sevenShifts/role'),
    roleAssignment: new ModelApiService('sevenShifts/roleAssignment'),
    userWage: new ModelApiService('sevenShifts/userWage'),
    webhook: new ModelApiService('sevenShifts/webhook'),
    connect: new ModelApiService('sevenShifts/connect'),
  },
  orderAction: {
    printLog: new ModelApiService('orders/printlog'),
    action: new ModelApiService('orders/action'),
    conversation: new ModelApiService('orders/conversation'),
  },
  waitlist: new ModelApiService('waitlist'),
  dev: {
    issue: new ModelApiService('dev/issue'),
    issueWatcher: new ModelApiService('dev/issue_watcher'),
  },
  doordash: new ModelApiService('delivery/external/doordash'),
  pricing: new ModelApiService('pricing'),
  timeline: {
    category: new ModelApiService('timeline/category'),
    event: new ModelApiService('timeline/event'),
  },
  cashdrawer: new ModelApiService('cashdrawers'),
  misc: new ModelApiService('misc'),
  rs: {
    book: new ModelApiService("reservations/book"),
    bookedDate: new ModelApiService("reservations/booked_date"),
    bookedUp: new ModelApiService("reservations/booked_up"),
    bookedCredit: new ModelApiService("reservations/booked_credit"),
  },
}