<template lang="pug">
.my-10.d-flex.flex-row.justify-center
  Card(:id="id")
</template>

<script>
import Card from "./Card";
export default {
  components: { Card },
  props: ["id"],
};
</script>