<template lang="pug">
v-alert(v-if="distance > 5000", color="warning", dark) You are {{ distance | readableDistance(unit) }} from this location. Please make sure you are ordering from the right location.
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BizUtils from "/libs/utils/biz.js";

export default {
  computed: {
    ...mapGetters(["biz", "userLocation"]),
    distance() {
      if (!this.userLocation?.lat) return null;
      const geo = this.biz?.address?.geometry;
      if (!geo) return null;
      return BizUtils.geoDistanceInMeter(this.userLocation, geo);
    },
    unit() {
      if (this.item?.address?.country == "CA") return "km";
      return "mi.";
    },
  },
  mounted() {
    this.getPosition();
  },
  methods: {
    ...mapActions(["setUserLocation"]),
    getPosition() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    },
  },
};
</script>
