const state = {
  memberAccounts: []
}

const getters = {
  memberAccounts: state => state.memberAccounts
}

const actions = {
  setMemberAccounts: ({ commit }, data) => { commit('setMemberAccounts', data) },
  updateMemberAccount: ({ commit }, data) => { commit('updateMemberAccount', data) },
  addMemberAccount: ({ commit }, data) => { commit('addMemberAccount', data) },
  removeMemberAccount: ({ commit }, data) => { commit('removeMemberAccount', data) }
}

const mutations = {
  setMemberAccounts(state, data) {
    state.memberAccounts = data
  },
  updateMemberAccount(state, data) {
    state.memberAccounts = state.memberAccounts.map(o => {
      return (o._id === data._id) ? data : o
    })
  },
  addMemberAccount(state, data) {
    state.memberAccounts.push(data)
  },
  removeMemberAccount(state, data) {
    state.memberAccounts = state.memberAccounts.filter(o => o._id !== data._id)
  }
}

export default { state, getters, actions, mutations }