<template lang="pug">
.d-flex.flex-row.justify-center
  #qrcode
    QrcodeVue(
      v-if="event",
      :value="url",
      size="160",
      renderAs="canvas",
      level="H",
      ref="qrCodeUrl"
    )
</template>

<script>
import { mapGetters } from "vuex";
import QrcodeVue from "qrcode.vue";

export default {
  components: { QrcodeVue },
  computed: {
    ...mapGetters(["event"]),
    url() {
      if (!this.event) return null;
      return "https://selflane.com/event/" + this.event._id;
    },
  },
};
</script>

<style lang="sass" scoped>
#qrcode
  padding: 20px
  border-radius: 6px
  overflow: hidden
  margin-bottom: 60px
  background-color: white
  position: relative
  box-shadow: 1px 4px 8px 1px white, 1px 6px 20px 1px white
</style>