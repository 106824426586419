<template lang="pug">
.message-box(v-if="conversation")
  Message(:message="item", v-for="item in messages", :key="item._id")
</template>

<script>
import _ from "underscore";
import Message from "./Message";

export default {
  components: { Message },
  props: {
    conversation: { type: Object, default: null },
  },
  computed: {
    messages() {
      return _.sortBy(this.conversation.messages, "created")?.reverse();
    },
  },
};
</script>

<style lang="sass" scoped>
.message-box
  height: 300px
  overflow-y: scroll
  padding: 5px
  border: 1px solid #ccc
  border-radius: 6px
  display: flex
  flex-direction: column-reverse
</style>