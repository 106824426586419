<template lang="pug">
div
  v-parallax(:src="title_pic")
  v-container
    .about-title A Tech Company Powers Main Street Businesses
    .about-header What do we do
    .about-description We build software infrastructure to facilitate the operations of businesses in the Main Street and the interactions with their customers. With our software, family owned businesses or independent businesses are equipped with the same tools as the larger establishments. With a uniformed infrastructure, we build a community of a hybrid life with the convenience of modern electronics and the varying offering from the local businesses.
    .about-header Remove technology hurdles
    .about-description We build Point of Sales, online ordering, deliveries, membership program, bookkeeping program, payroll, so businesses can focus on their products and services and not worry about incompetence to large companies with IT departments.
    .about-header Who do we serve
    .about-description From independent businesses to local chains
</template>

<script>
export default {
  data() {
    return {
      title_pic:
        "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/background%2Fmain-street.JPG?alt=media&token=bb6bd4dc-a2ba-469d-9289-7b3c64eccf60",
    };
  },
};
</script>

<style lang="sass" scoped>
.about-title
  color: #32325d
  font-size: 30px
  font-weight: 500
  text-align: center
  margin-top: 40px

.about-header
  color: #32325d
  font-size: 26px
  font-weight: 500
  margin-top: 30px

.about-description
  color: #555a7f
  font-size: 22px
  font-weight: 400
  margin-top: 20px
  margin-bottom: 20px
</style>
