<template lang="pug">
v-btn.text-notransform(outlined, small, color="secondary", @click="toMenu()")
  v-icon(left) mdi-chevron-left
  span Back to Menu
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["biz"]),
  },
  methods: {
    toMenu() {
      if (this.biz) {
        this.$router.push("/bizs/" + this.biz.url);
        this.$vuetify.goTo("#bizMainCon");
      }
    },
  },
};
</script>
