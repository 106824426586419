<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  template(v-slot:activator="{ on }")
    v-icon(v-if="roundIcon", icon, v-on="on", :loading="loading") mdi-plus
    IconBtn(
      v-else,
      v-on="on",
      :loading="loading",
      title="Add",
      icon="mdi-plus"
    )
  v-card
    v-form(@submit.prevent="submit")
      v-toolbar(flat)
        .subtitle-2 Add Credit/Debit Card
        v-spacer
        v-btn(@click="submit", :loading="loading", color="secondary") Save
      v-card-text
        StripeElementCard(ref="card", :pk="stripeKey", @token="createSource")
        .caption.mt-5
          span.mr-1 Secured by
          a(href="http://www.stripe.com", target="_blank") Stripe
          span .
</template>

<script>
import { mapActions } from "vuex";
import { StripeElementCard } from "@vue-stripe/vue-stripe";

export default {
  components: { StripeElementCard },
  props: ["roundIcon"],
  data() {
    return {
      dialog: false,
      stripeKey: process.env.VUE_APP_STRIPE,
      loading: false,
    };
  },
  methods: {
    ...mapActions(["setStripeSources"]),
    submit() {
      this.$refs.card.submit();
    },
    async createSource(data) {
      const { id } = data;
      const user = this.$auth.user();
      if (!user || !id) return;
      this.loading = true;
      const params = { userId: user._id, token: id };
      try {
        await this.$api.stripe.source.create(params);
        await this.load();
        this.dialog = false;
        this.$emit("saved", data);
        this.$refs.card.clear();
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async load() {
      const user = this.$auth.user();
      if (!user) return;
      const params = { userId: user._id };
      const result = await this.$api.stripe.source.list(params);
      this.setStripeSources(result);
    },
  },
};
</script>
