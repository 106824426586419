<template lang="pug">
v-container
  Page(title="Wallet", :items="items")
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Member", url: "/user/wallet/member" },
        { title: "Coupon", url: "/user/wallet/coupon" },
        { title: "Gift Card", url: "/user/wallet/gift" },
        { title: "Event", url: "/user/wallet/event" },
      ],
    };
  },
};
</script>
