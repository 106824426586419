<template lang="pug">
div
  v-row.sticky(dense, wrap)
    v-col(cols="6", sm="4", v-for="(item, index) in type_items", :key="index")
      TypeButton(:name="item.name", :value="item.value")
  #type-time-body
    Label(icon="mdi-clock", title="Time (approximate)")
    Time
    .text--secondary.body-2
      Schedule(:time="cartOrder.needed")
      div {{ selectedType?.beforehand | leadtime }} lead time.
      .success--text {{ selectedType?.note }}
</template>

<script>
import { mapGetters } from "vuex";
import TypeButton from "./TypeButton";
import Time from "./Time";
import Schedule from "./TimeSelector/Schedule";

export default {
  components: { TypeButton, Time, Schedule },
  computed: {
    ...mapGetters(["cartOrder", "biz"]),
    type_items() {
      let list = [];
      const data = this.biz?.orderType;
      if (!data) return list;
      if (data.pickup?.status) {
        list.push({ name: "Pick up", value: "pickup" });
      }
      if (data.curbside?.status) {
        list.push({ name: "Curbside", value: "curbside" });
      }
      if (data.delivery?.status && !data.delivery.deliv) {
        list.push({ name: "Delivery", value: "delivery" });
      }
      return list;
    },
    selectedType() {
      const orderType = this.biz?.orderType;
      if (this.cartOrder.type == "delivery") return orderType?.delivery;
      if (this.cartOrder.type == "curbside") return orderType?.curbside;
      if (this.cartOrder.type == "pickup") return orderType?.pickup;
      if (this.cartOrder.type == "dinein") return orderType?.dinein;
      return null;
    },
  },
};
</script>

<style lang="sass" scoped>
#type-time-body
  background-color: white
  border-radius: 12px
  padding: 6px 12px
  margin-top: 12px

.sticky
  position: -webkit-sticky
  position: sticky
  top: 204px
  z-index: 3
  background: #F0F2F5
  display: flex
</style>
