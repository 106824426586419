<template lang="pug">
v-card(width="400", outlined)
  v-progress-linear(indeterminate, v-if="loading")
  v-toolbar(flat, dense)
    .subtitle-2 {{ title }}
  v-card-text(v-if="order && event")
    div(v-for="(item, index) in tickets", :index="index")
      .section-title Ticket Number: {{ item.ticket_number }}
      .section-description {{ item.user.name }}
      .section-description {{ item.user.phone | phone }}
      .section-description {{ item.user.email }}
      .mt-5
    .mt-5
    .section-title Date and time
    .section-description(v-for="(item, index) in time_items", :key="index") {{ item }}
    .mt-5
    .section-title Location
    .section-description {{ event.address.name }}
    .section-description {{ event.address | address }}
    v-btn.mt-5(
      :href="event_page",
      target="_blank",
      small,
      rounded,
      outlined,
      color="secondary"
    ) Event Page
</template>

<script>
import moment from "moment-timezone";
export default {
  props: ["id"],
  data() {
    return {
      order: null,
      event: null,
      tickets: [],
      loading: false,
    };
  },
  computed: {
    title() {
      if (!this.event) return "Event Tickets";
      return this.event.title;
    },
    event_page() {
      if (!this.event) return null;
      return "/event/" + this.event._id;
    },
    begin_date() {
      if (!this.event) return "";
      return moment(this.event.event_time.begin).format("ddd, MMM DD, YYYY");
    },
    begin_time() {
      if (!this.event) return "";
      return moment(this.event.event_time.begin).format("h:mm A");
    },
    end_date() {
      if (!this.event) return "";
      return moment(this.event.event_time.end).format("ddd, MMM DD, YYYY");
    },
    end_time() {
      if (!this.event) return "";
      return moment(this.event.event_time.end).format("h:mm A");
    },
    time_items() {
      if (!this.event) return "";
      if (this.begin_date == this.end_date)
        return [
          this.begin_date + " " + this.begin_time + " - " + this.end_time,
        ];
      return [
        this.begin_date + " " + this.begin_time,
        "to",
        this.end_date + " " + this.end_time,
      ];
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      if (!this.id) return;
      const params = { order_id: this.id };
      this.loading = true;
      try {
        const res = await this.axios.post("/event/orders/retrieve", params);
        this.order = res.data;
      } catch (e) {
        //
      }
      this.loading = false;
      this.loadTickets();
      this.loadEvent();
    },
    async loadTickets() {
      if (!this.order) return;
      const params = { criteria: { order_id: this.order._id } };
      try {
        const res = await this.axios.post("/event/tickets/list", params);
        this.tickets = res.data;
      } catch (e) {
        //
      }
    },
    async loadEvent() {
      if (!this.order) return;
      const params = { event_id: this.order.event_id, select: "-detail" };
      try {
        const res = await this.axios.post("/event/entries/retrieve", params);
        this.event = res.data;
      } catch (e) {
        //
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.section-title
  font-size: 15px
  font-weight: 600
  color: #402F5A

.section-description
  font-size: 14px
  font-weight: 400
  color: #402F5A
</style>