<template lang="pug">
v-card(v-if="biz && biz.orderType.gc.status", shaped, flat)
  v-toolbar(dense, flat, color="navy", dark)
    .sl-title Gift Cards
    v-spacer
    Instruction
  v-card-text.py-2
    v-row.my-2(wrap, dense)
      v-col(cols="4", v-for="value in items", :key="value")
        v-btn(
          @click="addGift(value)",
          color="#E4E6EB",
          block,
          depressed,
          rounded
        ) ${{ value }}
      v-col(cols="4")
        v-btn.text-capitalize(
          @click="handleMore()",
          color="#E4E6EB",
          block,
          depressed,
          rounded
        ) More
          v-icon(right, small) mdi-pencil
    .text-center(
      v-if="giftProgram && giftProgram.discount && giftProgram.discount.status"
    )
      div {{ giftProgram.discount.desc }}
      div
        span(v-if="giftProgram.discount.minimum > 0") Buy {{ giftProgram.discount.minimum | currency }} or More Get
        span.ml-1 {{ giftProgram.discount.pct }}% off
  v-dialog(v-model="dialog", width="400")
    v-card
      v-toolbar(flat, dense)
        .subtitle-2 Customize Gift Cards
        v-spacer
        .subtitle-2 {{ total | currency }}
      v-divider
      v-simple-table(dense)
        thead
          tr
            th Value
            th Quantity
            th.text-right Actions
        tbody
          tr(v-for="(item, cindex) in customPurchases", :key="cindex")
            td
              v-text-field(
                type="number",
                v-model.number="item.value",
                step="1",
                prefix="$"
              )
            td
              v-text-field(
                type="number",
                v-model.number="item.quantity",
                step="1"
              )
            td.text-right(style="width: 120px")
              v-btn(icon, color="secondary", @click="addRow()")
                v-icon(small) mdi-plus
              v-btn(
                icon,
                color="error",
                v-if="customPurchases.length > 1",
                @click="removeRow(cindex)"
              )
                v-icon(small) mdi-delete
      v-card-text
        span Use this form to add custom gift cards quickly to your cart. All cards can be sent to your friends separately.
        v-btn(
          block,
          color="secondary",
          :disabled="total <= 0",
          :loading="loading",
          @click="addCustomGift"
        ) Save to Cart
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Instruction from "./Instruction";
import _ from "underscore";

export default {
  components: { Instruction },
  data() {
    return {
      items: [25, 50, 75, 100],
      dialog: false,
      customPurchases: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz", "giftProgram"]),
    total() {
      return this.customPurchases.reduce((a, b) => a + b.value * b.quantity, 0);
    },
  },
  mounted() {
    this.loadGiftProgram();
  },
  methods: {
    ...mapActions(["addGiftToCart", "setGiftProgram"]),
    addGift(value) {
      if (!this.biz) return;
      let giftProgram;
      if (this.giftProgram && this.giftProgram.biz == this.biz._id)
        giftProgram = this.giftProgram;
      this.addGiftToCart({ value, biz: this.biz, giftProgram });
    },
    handleMore() {
      this.dialog = true;
      this.customPurchases = [{ value: 20, quantity: 1 }];
    },
    removeRow(index) {
      if (this.customPurchases.length == 1) return;
      this.customPurchases.splice(index, 1);
    },
    addRow() {
      this.customPurchases.push({ value: 20, quantity: 1 });
    },
    addCustomGift() {
      const found = this.customPurchases.find(
        (o) => o.value < 5 || o.quantity < 1
      );
      if (found) {
        this.$toast.error("Minimum value is $5 and minimum quantity is 1");
        return;
      }
      this.loading = true;
      _.each(this.customPurchases, (o) => {
        _.each(_.range(o.quantity), () => {
          let value = parseFloat(o.value);
          value = Math.round(value * 10) / 10;
          this.addGift(value);
        });
      });
      this.dialog = false;
      this.loading = false;
    },
    async loadGiftProgram() {
      if (!this.biz) return;
      const params = { criteria: { biz: this.biz._id } };
      const { data } = await this.axios.post("/gift/program/retrieve", params);
      this.setGiftProgram(data);
    },
  },
};
</script>
