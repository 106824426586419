<template lang="pug">
v-btn.cart-button(
  fixed,
  right,
  bottom,
  color="navy",
  dark,
  large,
  rounded,
  v-if="cartCount",
  @click="show"
)
  v-icon(left) mdi-cart-outline
  span &times{{ cartCount }}; Total: {{ cartRestaurantTotal | currency }}
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  computed: {
    ...mapGetters(["cartRestaurantTotal", "cartCount"]),
  },
  methods: {
    show() {
      EventBus.$emit("show-cart-sheet");
    },
  },
};
</script>

<style lang="sass" scoped>
.cart-button
  z-index: 3
</style>