<template lang="pug">
v-dialog(v-model="dialog", width="500")
  template(v-slot:activator="{ on }")
    v-btn(v-on="on", @click="open", icon, small, color="secondary")
      v-icon(small) mdi-share-variant
  v-card(v-if="biz")
    v-card-title Share
    v-card-text
      .d-flex.flex-row
        ShareNetwork.mr-3(
          network="facebook",
          :url="url",
          :title="title",
          :quote="review.content",
          :description="review.content",
          hashtags="#selflane"
        )
          i.fab.fah.fa-facebook.fa-lg(style="color: #3b5998")
        ShareNetwork(
          network="twitter",
          :url="url",
          :title="title",
          :quote="review.content",
          :description="review.content",
          hashtags="#selflane"
        )
          i.fab.fah.fa-twitter.fa-lg(style="color: #38a1f3")
</template>

<script>
export default {
  props: {
    review: { type: Object, required: true },
  },
  data() {
    return {
      dialog: false,
      biz: null,
    };
  },
  computed: {
    url() {
      if (this.biz) {
        return "https://selflane.com/bizs/" + this.biz.url;
      } else {
        return "https://selflane.com";
      }
    },
    title() {
      return `${this.biz?.name || "Selflane"} - ${this.review.title}`;
    },
  },
  methods: {
    // load biz url
    async open() {
      const bizId = this.review.biz;
      if (!bizId) return;
      this.biz = await this.$api.biz.retrieve({
        criteria: { _id: bizId },
        select: "name url",
      });
    },
  },
};
</script>
