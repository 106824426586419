<template lang="pug">
v-card.mb-10(v-if="event", outlined, :loading="loading")
  Top
  Tickets
  Middle
  Map
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Top from "./Top";
import Tickets from "./Tickets";
import Middle from "./Middle";
import Map from "./Map";

export default {
  components: { Top, Tickets, Middle, Map },
  props: ["id"],
  data() {
    return { loading: false };
  },
  computed: { ...mapGetters(["event"]) },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setBiz", "setEvent"]),
    async load() {
      if (!this.id) return;
      this.loading = true;
      const params = { event_id: this.id };
      try {
        const url = "/event/entries/retrieve";
        const { data } = await this.axios.post(url, params);
        this.setEvent(data);
      } catch (e) {
        this.setEvent();
        this.$router.push("/event");
      }
      await this.loadBiz();
      this.loading = false;
    },
    async loadBiz() {
      if (!this.event) return;
      const params = { criteria: { _id: this.event.biz_id } };
      try {
        const res = await this.axios.post("/bizs/retrieve", params);
        this.setBiz(res.data);
      } catch (e) {
        //
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.event-container
  border-bottom: 1px solid #DBDAE3
</style>