<template lang="pug">
.coupon-top(@click="toDetail", role="button")
  Logo(:promotion="promotion", :bizs="bizs", :chains="chains")
  .text-center.pa-2
    .subheading.text-truncate.mb-2 {{ name }}
    .title(v-if="promotion.type === 'fix'") {{ -promotion.fix | currency }}
    .title(v-else) {{ -promotion.pct }}%
    .grey--text.text-truncate.mt-2 Expires: {{ promotion.expiry | date }}
    .body-2.text-truncate {{ promotion.title }}
</template>

<script>
import Logo from "@/components/Promotion/Logo";
export default {
  components: { Logo },
  props: ["promotion", "bizs", "chains"],
  computed: {
    name() {
      if (!this.promotion) return "";
      if (this.promotion.chain) {
        return this.chains?.find((o) => o._id == this.promotion.chain)?.name;
      } else {
        return this.bizs?.find((o) => o._id == this.promotion.biz)?.name;
      }
    },
  },
  methods: {
    toDetail() {
      if (!this.promotion) return;
      this.$router.push("/couponisland/" + this.promotion.code);
    },
  },
};
</script>

<style lang="sass" scoped>
.coupon-top
  height: 100%
</style>
