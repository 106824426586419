<template lang="pug">
div(v-if="cards && cards.length && !cartPromotion")
  v-list.mb-3.pa-0(dense, dark, color="success")
    v-list-item(v-for="item in cartOrder.payment.charges", :key="item.transId")
      v-list-item-content Applied eGift Card {{ item.amount | currency }}
      v-list-item-action
        v-btn(icon, small, @click="removeGC(item.transId)")
          v-icon mdi-close
  Label.mb-3(icon="mdi-gift", title="Pay with Gift Card")
  v-row.card-box(wrap, dense)
    v-col(cols="4", md="3", v-for="item in cards", :key="item._id")
      v-btn(@click="applyGC(item)", block, outlined, color="secondary") {{ item.balance | currency }}
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return { cards: [] };
  },
  computed: {
    ...mapGetters(["cartOrder", "cartPromotion"]),
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["applyGC", "removeGC"]),
    async load() {
      if (!this.$auth.user()) {
        this.cards = [];
        return;
      }
      const order = this.cartOrder;
      if (!order.seller?.id || order.gifts?.length) {
        this.cards = [];
        return;
      }
      const params = {
        criteria: {
          acceptors: { $elemMatch: { $eq: order.seller.id } },
          owner: this.$auth.user()._id,
          balance: { $gt: 0 },
        },
        select: "balance to",
      };
      try {
        const result = await this.$api.giftcard.list(params);
        this.cards = result.filter((o) => !o.to);
      } catch (e) {
        this.cards = [];
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.card-box
  max-height: 100px
  overflow-y: auto
</style>
