<template lang="pug">
.company-body
  About
  Links
  Content
</template>

<script>
import About from "./About";
import Content from "./Content";
import Links from "./Links";

export default {
  components: { About, Links, Content },
  metaInfo() {
    const title = "Selflane Company";
    const description =
      "Selflane is a platform for restaurants to manage their online presence, including online ordering, reservations, and point of sale.";
    return { title, meta: [{ name: "description", content: description }] };
  },
};
</script>

<style lang="sass" scoped>
.company-body
  background-color: white
  height: 100%
</style>
