<template lang="pug">
v-row(wrap, dense)
  v-col(cols="12", md="6")
    .name-phone-address.s-vstack
      Name
      Access
      Phone
      Address(v-if="showAddress")
      Note(v-if="hasDish")
  v-col(cols="12", md="6")
    Map
</template>

<script>
import Name from "./Name";
import Phone from "./Phone";
import Address from "./Address/index";
import Access from "./Access";
import Note from "./Note";
import Map from "./Map";
import { mapGetters } from "vuex";

export default {
  components: { Name, Access, Phone, Address, Note, Map },
  computed: {
    ...mapGetters(["cartOrder"]),
    hasDish() {
      return this.cartOrder?.dishes?.length;
    },
    showAddress() {
      return this.hasDish && this.cartOrder.type == "delivery";
    },
  },
};
</script>

<style lang="sass" scoped>
.name-phone-address
  background: white
  border-radius: 12px
  padding: 12px
</style>
