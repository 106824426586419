/// present suggested biz in the biz home page
/// find close biz in parameter (0.1 rad)
/// rank by distance to user
/// only present first three

<template lang="pug">
div(v-if="bizs && bizs.length")
  .title-style Nearby Restaurants
  BizList(:bizs="bizs", :loading="loading")
</template>

<script>
import _ from "underscore";
import BizList from "./../List";
import BizUtils from "/libs/utils/biz.js";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { BizList },
  data() {
    return {
      bizs: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    bizId() {
      return this.biz?._id;
    },
  },
  watch: {
    bizId(val, oVal) {
      if (val != oVal) this.load();
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setUserLocation"]),
    async load() {
      const geometry = this.biz?.address?.geometry;
      if (!geometry) return;
      if (!navigator || !navigator.geolocation) return;
      const params = {
        criteria: {
          _id: { $ne: this.bizId },
          "address.geometry.lat": {
            $gte: geometry.lat - 0.1,
            $lte: geometry.lat + 0.1,
          },
          "address.geometry.lng": {
            $gte: geometry.lng - 0.1,
            $lte: geometry.lng + 0.1,
          },
          "status.byAdmin": 1,
          "status.byUser": 1,
        },
        select: "name logo url address cates schedule stats orderType",
      };
      const res = await this.axios.post("/bizs/list", params);
      // get distance and assign 3 closest locations
      this.loading = false;
      navigator.geolocation.getCurrentPosition((position) => {
        const location = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.setUserLocation(location);
        const data = _.map(res.data, (o) => {
          o.distance = BizUtils.geoDistance(o.address.geometry, location);
          return o;
        });
        this.bizs = data
          .toSorted((a, b) => a.distance - b.distance)
          .slice(0, 3);
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.title-style
  display: inline-block
  font-weight: 500
  font-size: 24px
  padding-bottom: 4px
  border-bottom: solid 3px #FF6D32
  margin-bottom: 20px
</style>
