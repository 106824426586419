<template lang="pug">
.d-flex.flex-row.py-10.justify-center
  .raising-block
    .raising-block-top {{ amount | currency("$", 0) }}
    .raising-block-bottom {{ count }} SOLD
    .raising-bottom-border
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      report: null,
    };
  },
  computed: {
    ...mapGetters(["event"]),
    count() {
      return this.report?.ticketReport?.reduce((a, b) => a + b.count, 0) || 0;
    },
    amount() {
      if (this.report) return this.report.subtotal;
      return 0;
    },
  },
  mounted() {
    this.getCountUp();
  },
  methods: {
    async getCountUp() {
      await this.loadSales();
      const gap = 60 * 1000;
      setTimeout(() => {
        this.getCountUp();
      }, gap);
    },
    async loadSales() {
      if (!this.event) return;
      const params = { event_id: this.event._id };
      try {
        const url = "/event/report/sales";
        const { data } = await this.axios.post(url, params);
        this.report = data;
      } catch (e) {
        //
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.raising-block
  overflow: hidden
  border-radius: 12px
  background-color: #1C1A1B
  margin-left: 12px
  margin-right: 12px
  width: 700px
  position: relative
  box-shadow: 1px 4px 8px 1px white, 1px 6px 20px 1px white

  &-top
    color: white
    font-size: 100px
    font-weight: 700
    width: 100%
    text-align: center
    margin-top: 30px
    margin-bottom: 30px

  &-bottom
    color: white
    background-color: #090909
    width: 100%
    font-size: 26px
    font-weight: 600
    text-align: center
    padding: 10px

.raising-bottom-border
  position: relative
  bottom: 0px
  width: 200px
  left: 250px
  height: 6px
  background-color: #ff6d32
  border-top-left-radius: 6px
  border-top-right-radius: 6px
</style>