<template lang="pug">
.login-container
  .login
    Head
    Form
</template>

<script>
import Head from "./Head";
import Form from "./Form/index";

export default {
  components: { Head, Form },
  metaInfo() {
    const title = "Selflane: Login";
    const description =
      "Login to Selflane to access hundreds of restaurants and order food online.";
    return {
      title,
      meta: [{ name: "description", content: description }],
    };
  },
};
</script>

<style lang="sass" scoped>
.login-container
  display: flex
  flex-flow: column
  justify-content: center
  align-items: center
  margin-top: 100px
  margin-bottom: 100px

.login
  width: 95%
  max-width: 390px
</style>
