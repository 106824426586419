<template lang="pug">
v-btn-toggle(
  v-model="review.status",
  @change="change",
  dense,
  :loading="loading"
)
  v-btn.text-capitalize(
    v-for="(item, index) in items",
    :key="index",
    :value="item.value",
    x-small
  ) {{ item.text }}
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["review"],
  data() {
    return {
      loading: false,
      items: [
        { text: "Demote", value: -1 },
        { text: "Normal", value: 0 },
        { text: "Promote", value: 1 },
      ],
    };
  },
  methods: {
    ...mapActions(["updateReview"]),
    async change(status) {
      if (!this.review) return;
      this.loading = true;
      const action = { $set: { status } };
      const result = await this.$api.review.put(this.review._id, action);
      this.updateReview(result);
      this.loading = false;
    },
  },
};
</script>
