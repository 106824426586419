<template lang="pug">
v-list-item(@click="$emit('edit')")
  v-list-item-content
    v-list-item-title {{ item.number | phone }}
  v-list-item-action
    v-btn(icon, @click.stop="remove(item._id)", :loading="loading")
      v-icon(color="grey", small) mdi-delete
</template>

<script>
export default {
  props: ["item"],
  data() {
    return { loading: false };
  },
  methods: {
    handleRemove(id) {
      confirm("Remove this phone?") && this.remove(id);
    },
    async remove(id) {
      const user = this.$auth.user();
      if (!user) return;
      const params = {
        criteria: { _id: user._id },
        action: { $pull: { phones: { _id: id } } },
      };
      this.loading = true;
      try {
        const { data } = await this.axios.post("/users/update", params);
        this.$auth.user(data);
        this.$toast.success("Phone removed");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
