import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import biz from './modules/biz'
import cart from './modules/cart'
import cities from './modules/cities'
import deliv from './modules/deliv'
import giftCards from './modules/giftcards'
import orders from './modules/orders'
import pricings from './modules/pricings'
import punchcards from './modules/punchcards'
import reservations from './modules/reservations'
import reviews from './modules/reviews'
import rewards from './modules/rewards'

/// Biz
import bizAccounts from './modules/Biz/accounts'
import chain from './modules/Biz/chain'
import bizService from './modules/Biz/service'

/// Member
import memberAccounts from './modules/Member/accounts'

/// Stripe
import stripeAccount from './modules/Stripe/account'
import stripeBanks from './modules/Stripe/banks'
import stripePersons from './modules/Stripe/persons'
import stripeSources from './modules/Stripe/sources'

import event from './modules/Event/event'
import giftProgram from './modules/Gift/program'
import influencer from './modules/Influencer/index'
import promotion from './modules/Promotion/index'
import conversation from '/libs/store/order/conversation'

/// libs
/// company
import press_reports from '/libs/store/company/press_reports'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    orders,
    reservations,
    biz,
    deliv,
    punchcards,
    pricings,
    rewards,
    reviews,
    cart,
    cities,
    giftCards,
    bizAccounts,
    bizService,
    chain,
    memberAccounts,
    stripeSources,
    stripeAccount,
    stripeBanks,
    stripePersons,
    giftProgram,
    event,
    promotion,
    influencer,
    conversation,
    // libs
    press_reports
  }
})
export default store