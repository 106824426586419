<template lang="pug">
div
  Items
  Coupon
  GiftPayments
  CartTypeTime.my-3(v-if="hasDish")
  NamePhoneAddress.my-3
  Info
  PaymentBlock.mb-10
  .red--text {{ error }}
  .checkout-bottom.my-3
    CartTotal
    v-btn.checkout-button(
      @click="handleSubmit()",
      :disabled="loading",
      :loading="loading",
      depressed,
      tile,
      color="primary",
      height="100%"
    ) SUBMIT
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Items from "./Items/index";
import PaymentBlock from "./PaymentBlock/index";
import CartTypeTime from "./CartTypeTime";
import NamePhoneAddress from "./NamePhoneAddress/index";
import GiftPayments from "./GiftPayments";
import Coupon from "./Coupon/index";
import CartTotal from "./CartTotal";
import Info from "./Info";
import _ from "underscore";
import { EventBus } from "@/event-bus.js";

export default {
  components: {
    Items,
    PaymentBlock,
    CartTypeTime,
    NamePhoneAddress,
    GiftPayments,
    CartTotal,
    Coupon,
    Info,
  },
  data() {
    return {
      loading: false,
      error: "",
    };
  },
  computed: {
    ...mapGetters(["cartOrder", "cartUrl", "biz", "deliv", "modifiers"]),
    cartDue() {
      if (!this.cartOrder || !this.cartOrder.payment) return 0;
      const payment = this.cartOrder.payment;
      const paid = payment.charges?.reduce((a, b) => a + b.amount, 0) || 0;
      return Math.round((payment.total - paid) * 100) / 100;
    },
    hasDish() {
      return this.cartOrder?.dishes?.length;
    },
    isDelivery() {
      return this.cartOrder?.type === "delivery";
    },
  },
  mounted() {
    // setup initial variables
    const user = this.$auth.user();
    if (user) {
      this.prepareCheckout(user);
      this.loadDelivPartner();
    }
  },
  methods: {
    ...mapActions([
      "prepareCheckout",
      "submitCart",
      "setCard",
      "setDeliv",
      "initDeviceId",
    ]),
    handleSubmit() {
      if (this.cartDue === 0) {
        this.setCard(null);
      }
      if (this.isDelivery) {
        this.cartOrder.deliv = this.biz.orderType.delivery?.deliv;
      }
      this.saveName();
      this.submit();
    },
    async submit() {
      this.loading = true;
      this.error = "";
      try {
        await this.revalidateDishes();
        let currency = "USD";
        if (this.biz?.address?.country == "CA") currency = "CAD";
        const result = await this.submitCart();
        const total = result.payment.total;
        this.$gtag.event("purchase", {
          event_category: "ecommerce",
          event_label: "order",
          value: total,
          currency: currency,
        });
        EventBus.$emit("show-order-submit-success");
      } catch (e) {
        this.error = e.message;
      }
      this.loading = false;
    },
    async revalidateDishes() {
      // make sure biz matches
      await this.initDeviceId();
      await this.loadModifiers();
      const bizId = this.biz?._id;
      const cartSeller = this.cartOrder?.seller?.id;
      if (bizId && bizId != cartSeller) {
        throw new Error(
          "Cart has invalid business, please clear cart and retry"
        );
      }
      let errors = [];
      const dishes = this.cartOrder?.dishes
        .map((dish) => {
          const found = this.biz?.dishes?.find((o) => o._id == dish._id);
          if (!found) {
            errors.push(
              `Dish ${dish.name} is no longer available and is removed.`
            );
            return null;
          }
          let clone = JSON.parse(JSON.stringify(found));
          clone.quantity = dish.quantity;
          clone.wom = dish.wom;
          clone.notes = dish.notes;
          clone.customer_name = dish.customer_name;
          clone.modifiers = JSON.parse(JSON.stringify(dish.modifiers));
          this.matchModifiers(clone);
          this.$options.filters.unitPrice(clone);
          return clone;
        })
        .filter((o) => o);
      this.$store.dispatch("setDishes", dishes);
      if (errors.length) {
        let msg = errors.join("\n");
        msg += "\nPlease update your cart and proceed to checkout again.";
        this.$toast.error(msg);
        throw new Error(msg);
      }
    },
    // clone's modifiers are a subset of the biz's modifiers
    // this is to ensure that the modifiers are still valid, correct price, etc.
    // if a modifier is found in biz's modifiers, then copy it and replace choices' selected and quantity
    // as well as associatedChoices' selected, quantity, and halfhalf
    // if a modifier is not found in biz's modifiers, then remove it
    // modifiers are matched with _id, but choices and associatedChoices are matched with name
    // if a choice or associatedChoice is not found in the modifier, then remove it
    matchModifiers(dish) {
      const modifiers = this.modifiers;
      const dishModifiers = JSON.parse(JSON.stringify(dish.modifiers));
      dish.modifiers = [];
      for (const mod of dishModifiers) {
        const found = modifiers.find((o) => o._id == mod._id);
        if (!found) continue;
        let clone = JSON.parse(JSON.stringify(found));
        clone.choices = clone.choices.map((choice) => {
          const found = mod.choices.find((o) => o.name == choice.name);
          if (!found) return choice;
          let clone = JSON.parse(JSON.stringify(choice));
          clone.selected = found.selected;
          clone.quantity = found.quantity;
          return clone;
        });
        clone.choices = clone.choices.filter((o) => o);
        clone.associatedChoices = clone.associatedChoices.map((choice) => {
          const found = mod.associatedChoices.find(
            (o) => o.name == choice.name
          );
          if (!found) return choice;
          let clone = JSON.parse(JSON.stringify(choice));
          clone.selected = found.selected;
          clone.quantity = found.quantity;
          clone.halfhalf = found.halfhalf;
          return clone;
        });
        clone.associatedChoices = clone.associatedChoices.filter((o) => o);
        dish.modifiers.push(clone);
      }
    },
    async loadModifiers() {
      if (!this.biz?._id) return;
      const params = { criteria: { bizId: this.biz._id } };
      try {
        const data = await this.$api.bizModifier.list(params);
        const result = _.chain(data)
          .reject((o) => !o.modifier?.ol)
          .pluck("modifier")
          .each((o) => (o.choices = o.choices?.filter((c) => c.ol)))
          .reject((o) => !o.choices?.length)
          .value();
        this.$store.dispatch("setModifiers", result);
      } catch (e) {
        console.error(e);
        //
      }
    },
    async loadDelivPartner() {
      this.setDeliv(null);
      const delivery = this.biz?.orderType?.delivery;
      if (delivery?.status && delivery?.deliv) {
        const params = {
          criteria: { _id: delivery.deliv },
          select: "name",
        };
        const result = await this.$api.delivery.retrieve(params);
        this.setDeliv(result);
      }
    },
    async saveName() {
      // save preferred name if not existing yet
      const user = this.$auth.user();
      if (!user) return;
      // skip if not provided in the current order
      const name = this.cartOrder?.customer?.name?.trim();
      if (!name) return;
      const params = {
        criteria: { _id: user._id },
        action: { $set: { "name.preferred": name } },
      };
      const result = await this.$api.user.update(params);
      this.$auth.user(result);
    },
  },
};
</script>

<style lang="sass" scoped>
.checkout-rounded-container
  background-color: white
  border-radius: 12px
  padding: 12px

.checkout-content
  border: 2px solid #FF6D32
  border-radius: 10px 10px 0 0
  background: rgba(28, 31, 52, 0)
  overflow: hidden
  display: flex
  flex-wrap: wrap

.checkout-right
  background: #fffbf6

.checkout-bottom
  display: flex
  border-radius: 29px
  overflow: hidden
  font-size: 17px
  font-weight: 500
  color: #fdf9f4
  height: 58px

.checkout-button
  flex: 1 0
</style>
