<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Emailed Coupon
    v-simple-table
      thead
        tr
          th Received
          th Expiry
          th Code
          th Description
      tbody
        tr(v-for="(item, index) in items", :key="index")
          td {{ item.created | date }}
          td
            span(v-if="item.expiry") {{ item.expiry | date }}
            span(v-else) Never Expire
          td {{ item.code }}
          td {{ item.description }}
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      items: [],
    };
  },
  mounted() {
    EventBus.$on("show-member-email-promo", this.load);
  },
  destroyed() {
    EventBus.$off("show-member-email-promo", this.load);
  },
  methods: {
    async load(bizId, memberId) {
      if (!bizId || !memberId) return;
      // const now = Date.now();
      this.dialog = true;
      const params = { criteria: { biz: bizId, member: memberId } };
      const { data } = await this.axios.post("/memberEmail/list", params);
      this.items = data.sort((a, b) => b.created - a.created);
    },
  },
};
</script>