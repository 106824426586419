<template lang="pug">
v-data-table(
  :headers="headers",
  :items="reservations",
  sort-by="needed",
  sort-desc,
  mobile-breakpoint="0"
)
  template(v-slot:item.biz="{ item }")
    a(@click="toURL(item.seller.url)") {{ item.seller.name }}
  template(v-slot:item.needed="{ item }")
    span {{ item.needed | datetime }}
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["options"],
  data() {
    return {
      headers: [
        { text: "Restaurant", value: "biz", sortable: false },
        { text: "Time", value: "needed", sortable: false },
        { text: "Table", value: "tableId", sortable: false },
        {
          text: "People",
          value: "total_people",
          sortable: false,
          align: "end",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["reservations"]),
  },
  methods: {
    toURL(url) {
      if (!url) return;
      this.$router.push("/bizs/" + url);
    },
  },
};
</script>
