<template lang="pug">
.event-container
  v-row(wrap, no-gutters)
    v-col(cols="12", md="8", order="2", order-md="1")
      v-card-text.detail-section
        div(v-html="event.detail")
    v-col(cols="12", md="4", order="1", order-md="2")
      v-card-text.detail-section-right
        InfoBlock(:event="event")
</template>

<script>
import { mapGetters } from "vuex";
import InfoBlock from "/libs/components/Event/InfoBlock";

export default {
  components: { InfoBlock },
  computed: {
    ...mapGetters(["event"]),
  },
};
</script>

<style lang="sass" scoped>
.detail-section
  padding: 20px

  &-right
    background-color: rgba(238, 237, 237,0.7)
    padding: 20px
    height: 100%
</style>