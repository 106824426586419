function orderType(type) {
  if (type == 'pickup') return 'Pick up'
  if (type == 'dinein') return 'Dine in'
  if (type == 'gc') return 'eGift card'
  if (type == 'delivery') return 'Delivery'
  if (type == 'catering') return 'Catering'
  if (type == 'curbside') return 'Curbside'
  if (type == 'drive') return 'Drive Thru'
  return type
}

export default orderType