<template lang="pug">
.d-flex.flex-row.align-center
  .sl-title Checkout
  v-spacer
  v-btn(@click="toMenu", outlined, small, color="secondary")
    span.text-notransform Back to Menu
    v-icon(small, right) mdi-arrow-left-bold
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: { ...mapGetters(["biz"]) },
  methods: {
    toMenu() {
      if (!this.biz || !this.biz.url) return;
      const url = "/bizs/" + this.biz.url;
      this.$router.push(url);
    },
  },
};
</script>
