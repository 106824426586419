<template lang="pug">
div
  .dish-cell-container(@click="handleClick", :role="role")
    .quantity-block(:style="quantity_style", v-if="!dish.indic") {{ dish.quantity }}&#215;
    .name-block
      .gapped-row
        div {{ dish.name }}
        DishPrints(:dish="dish", :biz="biz", v-if="prints")
      .caption.text--secondary(v-if="dish.t") {{ dish.t | minutesToTime }}
      .item-detail {{ dish.modifiers | modifiers(true) }}
      .item-note(v-if="dish.notes") {{ dish.notes }}
    DishPrice(:dish="dish", showQuantity)
    v-btn(icon, small, @click.stop="$emit('delete')", v-if="editable")
      v-icon(small) mdi-delete
  .sl-dash-divider(v-if="divider")
</template>

<script>
import DishPrice from "./DishPrice";
import DishPrints from "./DishPrints";

export default {
  components: { DishPrice, DishPrints },
  props: {
    dish: { type: Object, required: true },
    editable: { type: Boolean, default: false },
    divider: { type: Boolean, default: false },
    prints: { type: Boolean, default: false },
    biz: { type: Object, default: null },
  },
  computed: {
    quantity_style() {
      if (this.dish.quantity > 1) return "background-color: #bc6c25";
      return "";
    },
    role() {
      return this.editable ? "button" : null;
    },
  },
  methods: {
    handleClick() {
      if (this.editable) this.$emit("click");
    },
  },
};
</script>

<style lang="sass" scoped>
.dish-cell-container
  display: flex
  align-items: first baseline
  gap: 6px
  margin-top: 8px
  margin-bottom: 8px
  font-weight: 500

.gapped-row
  display: flex
  align-items: center
  gap: 6px

.name-block
  flex-grow: 1

.quantity-block
  font-size: 13px
  font-weight: 500
  background-color: rgb(88, 85, 85)
  color: white
  border-radius: 6px
  padding-left: 6px
  padding-right: 6px

.item-detail
  font-size: 14px
  font-weight: 400
  color: rgba(28, 31, 52, 0.6)

.item-note
  font-size: 14px
  font-weight: 400
  color: green
</style>