/**
 * Converts a date to a time string
 * @param {number|string|Date} input
 * @returns {string}
 * @example
 * // returns '12:00 AM'
 * time('2019-01-01')
 * @example
 * // returns '12:00 AM'
 * time(1546300800000)
 */
import moment from 'moment-timezone'

function time(input, timezone) {
  if (input == null) return ''
  let m
  if (typeof input === 'string' && input.match(/^[0-9a-fA-F]{24}$/)) {
    m = moment(parseInt(input.substring(0, 8), 16) * 1000)
  } else {
    m = moment(input)
  }
  if (m.isValid()) {
    if (timezone) m.tz(timezone).format('h:mm A')
    return m.format('h:mm A')
  }
  return input
}

export default time