<template lang="pug">
v-btn(icon, @click.stop="handleRemove()")
  v-icon(color="grey", small) mdi-delete
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["source"],
  data() {
    return { loading: false };
  },
  methods: {
    ...mapActions(["removeStripeSource"]),
    handleRemove() {
      confirm("Remove this card?") && this.remove();
    },
    async remove() {
      if (!this.source) return;
      const user = this.$auth.user();
      const sourceId = this.source.id;
      if (!user) return;
      if (!sourceId) return;
      this.loading = true;
      try {
        await this.$api.stripe.source.delete({
          customerId: user.payments.customer,
          sourceId,
        });
        this.removeStripeSource(this.source);
        this.$toast.success("Card removed");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
