<template lang="pug">
v-text-field(
  type="tel",
  :label="label",
  v-model="content",
  :error-messages="errorMessages",
  v-on="$listeners",
  :solo="solo",
  :placeholder="placeholder"
)
</template>

<script>
export default {
  name: "PhoneField",
  props: {
    value: { type: String, default: "" },
    label: { type: String, default: "Phone" },
    errorMessages: { type: Array, default: () => [] },
    solo: { type: Boolean, default: false },
    placeholder: { type: String, default: "" },
  },
  data() {
    return { content: this.$options.filters.phone(this.value) };
  },
  watch: {
    value(val) {
      this.content = this.$options.filters.phone(val);
    },
    content() {
      const result = this.content.replace(/\D+/g, "");
      if (result !== this.value) this.$emit("input", result);
    },
  },
};
</script>
