<template lang="pug">
div(v-if="city")
  v-container
    v-row(align="center")
      v-btn(icon, to="/")
        v-icon.fa.fa-chevron-circle-left(color="secondary")
      .title {{ this.city.city }}, {{ this.city.state }}
    v-divider.my-5
    BizList(:bizs="bizs", :loading="loading")
</template>

<script>
import BizList from "./../Bizs/List";
import BizUtils from "/libs/utils/biz.js";
import { mapActions } from "vuex";

export default {
  components: { BizList },
  props: ["url"],
  metaInfo() {
    if (this.city) {
      const title = this.city.city + " | Selflane";
      const description = `Find restaurants in ${this.city.city}, ${this.city.state}.`;
      return {
        title,
        meta: [{ name: "description", content: description }],
      };
    }
    return {};
  },
  data() {
    return {
      mediaHost: process.env.VUE_APP_MEDIAHOST,
      city: null,
      loading: false,
      bizs: [],
    };
  },
  computed: {
    title() {
      if (this.city) return this.city.city + " | Selflane";
      return "Selflane";
    },
    keywords() {
      const basic =
        "Selflane,Restaurants,Online Food,Food Delivery,Online Reservation,Point of Sale";
      if (this.city) return `${this.city.city},${this.city.state},${basic}`;
      return basic;
    },
  },
  watch: {
    url(val, oldVal) {
      if (val && val != oldVal) this.load();
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setUserLocation"]),
    async load() {
      this.loading = true;
      try {
        const params = { criteria: { url: this.url } };
        this.city = await this.$api.city.retrieve(params);
        await this.loadBizs();
      } catch (e) {
        this.city = null;
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async loadBizs() {
      if (!this.city) return;
      const params = {
        criteria: {
          "status.byAdmin": 1,
          "status.byUser": 1,
          "address.city": this.city.city,
          "address.state": this.city.state,
        },
        select: "name logo url address cates schedule stats orderType",
      };
      this.loading = true;
      this.bizs = await this.$api.biz.list(params);
      // get distance
      if (navigator?.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const location = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.setUserLocation(location);
          this.bizs = this.bizs.map((o) => {
            o.distance = BizUtils.geoDistance(o.address.geometry, location);
            return o;
          });
          this.bizs.sort((a, b) => a.distance - b.distance);
        });
      }
      this.loading = false;
    },
  },
};
</script>
