
<template lang="pug">
v-container
  Page(title="My Reviews")
    template(v-slot:header)
      YearSelector(@select="load")
    List(:reviews="reviews")
</template>

<script>
import List from "/libs/components/Review/List";
import { mapActions, mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  components: { List },
  computed: {
    ...mapGetters(["reviews"]),
  },
  mounted() {
    this.setReviews([]);
  },
  methods: {
    ...mapActions(["setReviews"]),
    async load(year) {
      const user = this.$auth.user();
      if (!user || !year) return;
      const begin = moment().year(year).startOf("year").valueOf();
      const end = moment().year(year).endOf("year").valueOf();
      const params = {
        criteria: { "author.id": user._id, created: { $gte: begin, $lt: end } },
      };
      try {
        const result = await this.$api.review.list(params);
        this.setReviews(result);
      } catch (e) {
        this.setReviews([]);
      }
    },
  },
};
</script>
