<template lang="pug">
div
  .d-flex.flex-row.align-center
    v-icon.mr-2(small, color="primary") mdi-account
    .body-2 Name
  v-text-field(
    v-model="eventCart.user_name",
    dense,
    placeholder="Full Name",
    hint="Please enter full name",
    persistent
  )
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["eventCart"]),
  },
};
</script>
