<template lang="pug">
.my-3.white(v-if="bizs && bizs.length")
  v-container
    .sl-title Nearby Restaurants
    BizList(:bizs="bizs")
</template>

<script>
import { mapGetters } from "vuex";
import BizList from "@/components/Bizs/ListRow";

export default {
  components: { BizList },
  computed: {
    ...mapGetters(["bizAccounts"]),
    bizs() {
      return this.bizAccounts.filter((o) => o.industry != 1);
    },
  },
};
</script>
