<template lang="pug">
.dish-cell(@click="$emit('select')")
  .left-border(:style="{ background: color }")
  .dish-info
    .dish-name {{ dish.name }}
    .dish-description {{ dish.description }}
    .bottom-items
      DishPrice(:dish="dish")
      DishTags(:dish="dish")
      DishServing(:dish="dish")
      v-spacer
      v-btn(icon, @click.stop="$emit('select', true)", :color="iconColor")
        v-icon(small) {{ icon }}
      .subtitle-2.success--text(v-if="count") {{ count }}
  .dish-image(v-if="picture")
    v-img(:src="picture", height="100%")
</template>

<script>
import { mapGetters } from "vuex";
const mediaHost = process.env.VUE_APP_MEDIAHOST;

export default {
  props: {
    dish: { type: Object, required: true },
    color: { type: String, default: "red" },
  },
  computed: {
    ...mapGetters(["cartOrder"]),
    picture() {
      if (this.dish?.picLink) return mediaHost + this.dish.picLink;
      return null;
    },
    // count of this dish in the cart
    count() {
      const value = this.cartOrder?.dishes
        ?.filter((o) => o._id === this.dish._id)
        ?.reduce((a, b) => a + b.quantity, 0);
      return value || 0;
    },
    icon() {
      return this.count ? "mdi-cart-check" : "mdi-cart-plus";
    },
    iconColor() {
      return this.count ? "success" : "";
    },
  },
};
</script>

<style lang="sass" scoped>
// cell is a rounded box with a left border
// items are lined in a row with a gap of 12px
.dish-cell
  display: flex
  align-items: center
  gap: 12px
  cursor: pointer
  border-radius: 4px
  overflow: hidden
  background: white
  height: 100%
  max-height: 95px

.left-border
  width: 4px
  height: 100%
  opacity: 0.5

// content stack in column with gap of 6px
// padding of 12px on top and bottom
.dish-info
  flex: 1
  overflow: hidden
  padding: 12px 0
  display: flex
  flex-direction: column
  gap: 6px

.dish-image
  flex: 0 0 95px
  height: 100%
  object-fit: cover
  overflow: hidden

.dish-name
  font-weight: 600
  font-size: 15px
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis

.dish-description
  font-size: 14px
  color: rgba(28, 31, 52, 0.6)
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
// action buttons are lined in a row with a gap of 6px
.bottom-items
  font-weight: 500
  font-size: 14px
  display: flex
  gap: 6px
  align-items: center
</style>
