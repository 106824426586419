<template lang="pug">
v-dialog(v-model="dialog", max-width="300px", @keydown.esc="dialog = false")
  template(v-slot:activator="{ on }")
    v-btn(
      icon,
      small,
      color="secondary",
      v-on="on",
      @click="number = order.customer.phone"
    )
      v-icon(small) mdi-pencil
  v-card
    v-card-title Edit Phone
    v-form(@submit.prevent="save")
      v-card-text
        PhoneField(v-model="number")
      v-card-actions
        v-btn(
          color="secondary",
          block,
          text,
          type="submit",
          :loading="loading"
        ) Save
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["order"],
  data() {
    return {
      dialog: false,
      loading: false,
      number: "",
    };
  },
  methods: {
    ...mapActions(["updateOrder"]),
    async save() {
      if (this.number == this.order.customer.phone) {
        this.$toast.info("Same phone number");
        return;
      }
      this.loading = true;
      const params = {
        orderId: this.order._id,
        phone: this.number,
        portal: "customer",
      };
      try {
        const { data } = await this.axios.post("/orders/editPhone", params);
        this.updateOrder(data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>