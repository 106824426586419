<template lang="pug">
div
  .d-flex.flex-row.align-center.my-2
    .subtitle-2
      span Today:
      DayHours.mx-2(:schedule="schedule")
    v-btn(icon, small, @click="show")
      v-icon(small) mdi-calendar-minus
  HoursDialog
</template>

<script>
import { EventBus } from "@/event-bus";
import HoursDialog from "/libs/components/Biz/HoursDialog";

export default {
  components: { HoursDialog },
  props: ["schedule"],
  methods: {
    show() {
      EventBus.$emit("show-hours", this.schedule);
    },
  },
};
</script>
