<template lang="pug">
.d-flex.flex-row.align-center
  v-select.flex-box(
    v-model="mm",
    :items="months",
    menu-props="auto",
    :label="label"
  )
  span.mx-2 /
  v-select.flex-box(v-model="dd", :items="days", menu-props="auto")
</template>

<script>
/**
 * BirthdayPicker
 * @prop {Object} value - { mm: 0, dd: 0 }
 * @prop {String} label - label
 * @event input - { mm: 0, dd: 0 }
 */
import _ from "underscore";

export default {
  name: "BirthdayPicker",
  props: {
    value: {
      type: Object,
      default: () => {
        return { mm: 0, dd: 0 };
      },
      required: true,
    },
    label: { type: String, default: "Birthday" },
  },
  data() {
    return {
      mm: this.value?.mm,
      dd: this.value?.dd,
      months: _.map(_.range(1, 13), (o) => {
        return {
          value: o,
          text: this.$options.filters.month(o),
        };
      }),
    };
  },
  computed: {
    days() {
      if (this.mm == 2) {
        return _.range(1, 29);
      } else if ([4, 6, 9, 11].includes(this.mm)) {
        return _.range(1, 31);
      } else {
        return _.range(1, 32);
      }
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.mm = this.value?.mm || 0;
        this.dd = this.value?.dd || 0;
      },
    },
    mm(newval, oldval) {
      if (newval == oldval) return;
      this.$emit("input", { mm: this.mm, dd: this.dd });
    },
    dd(newval, oldval) {
      if (newval == oldval) return;
      this.$emit("input", { mm: this.mm, dd: this.dd });
    },
  },
};
</script>

<style  lang="sass" scoped>
.flex-box
  flex: 1
</style>
