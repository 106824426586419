const state = {
  deliv: null,
}

const getters = {
  deliv: state => state.deliv,
}

const actions = {
  setDeliv: ({ commit }, data) => { commit('setDeliv', data) }
}

const mutations = {
  setDeliv(state, data) {
    state.deliv = data
  }
}

export default { state, getters, actions, mutations }