<template lang="pug">
div
  Table#coupon-table
  CouponDialog
</template>

<script>
import Table from "./Table";
import CouponDialog from "./CouponDialog.vue";

export default {
  components: { Table, CouponDialog },
};
</script>
