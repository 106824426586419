<template lang="pug">
#banner-container
  v-container.banner
    .py-4
      .banner-title
        span.text-primary Food
        span.ml-2 at your fingertips
      .banner-text Ordering food online just got easier
      .d-flex.flex-row.my-4
        img.foodie-icon(v-for="(icon, index) in icons", :src="icon")
</template>

<script>
export default {
  data() {
    return {
      pizzaFest:
        "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/background%2Fpizza-fest.jpg?alt=media&token=4bf38738-7ce7-4e08-a1f0-46dce6b9e8f7",
      ramenBowls:
        "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/background%2Framen-bowls.jpg?alt=media&token=fd79eb31-e2a2-437e-a6d9-898f83c4431a",
      bkgFile:
        "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/background%2Fthin-pizza.png?alt=media&token=bf086bbb-3c9d-4a88-b670-b5b4025bac7f",
      icons: [
        "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Findex_food_icon01.svg?alt=media&token=61d56684-5e64-4e02-9ce4-1dffec7c7b8b",
        "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Findex_food_icon02.svg?alt=media&token=2317ec55-45cd-45c9-ad0d-174afdd5aefb",
        "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Findex_food_icon03.svg?alt=media&token=5cd58ca5-99b4-455e-91b8-39c4084dd45f",
        "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Findex_food_icon04.svg?alt=media&token=1c52674d-7bd9-44c7-8312-dc258c6de62e",
      ],
    };
  },
};
</script>

<style lang="sass" scoped>
#banner-container
  background-image: url("https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/background%2Findex_pizza_img.png?alt=media&token=f2e35b75-bd22-46aa-9071-e8ff41bdc6bb")
  background-position-x: right
  background-position-y: center
  background-size: 50%
  background-color: white

.banner
  background-image: url("https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/background%2Findex_greens_img%402x.png?alt=media&token=625f51fc-fc93-4df0-a46e-d8f5eb67c4b1")
  background-position: center
  background-size: 30%

  &-title
    font-weight: 600
    font-size: 46px
    font-size: calc(14px + 3vw)
    color: #000016
    margin-bottom: 20px

  &-text
    font-size: 24px
    font-size: calc(10px + 1.5vw)
    color: #1c1f34

.foodie-icon
  height: calc(14px + 2vw)
  width: calc(14px + 2vw)
  margin-right: 23px
  margin-right: calc(7px + 1vw)
</style>
