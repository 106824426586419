/// load qualified coupon for a user for specified order (biz, chain)

<template lang="pug">
div(v-if="isApplicable")
  Label.mb-3(
    icon="mdi-content-cut",
    title="Save with coupons. Please select to apply."
  )
  v-row(wrap, dense)
    v-col(cols="4", md="3", v-for="(item, index) in promotions", :key="index")
      CouponBtn(:promotion="item")
  ul.body-2.my-3(v-if="cartPromotion", column)
    li Requires a minimum purchase of {{ cartPromotion.minimum | currency }}
    li(v-if="cartPromotion.type == 'pct'") Discount is applied to subtotal up to {{ cartPromotion.limit | currency }}
    li Not applicable to gift card, fee, or tip.
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CouponBtn from "./CouponBtn";

export default {
  components: { CouponBtn },
  data() {
    return {
      promotions: [],
    };
  },
  computed: {
    ...mapGetters(["cartOrder", "cartPromotion"]),
    isApplicable() {
      // decide if the promotion can be applied to this order
      // 1) has promotion
      // 2) cart has no gift purchase
      // 3) cart has no gift payment
      return (
        this.promotions?.length &&
        !this.cartOrder.payment?.gift &&
        !this.cartOrder.payment?.charges?.length
      );
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setPromotion"]),
    async load() {
      this.promotions = [];
      if (!this.$auth.user()) return;
      if (!this.cartOrder?.seller?.id) return;
      const userId = this.$auth.user()._id;
      const bizId = this.cartOrder.seller.id;

      const params = { userId: userId, bizId: bizId };
      const { data } = await this.axios.post("/promoclaim/listUserBiz", params);
      this.promotions = data;
      if (!this.promotions?.length) this.setPromotion(null);
    },
  },
};
</script>
