<template lang="pug">
Label(icon="mdi-account")
  v-text-field(
    v-model="cartOrder.customer.name",
    label="Name",
    placeholder="Enter Name",
    hide-details
  )
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: { ...mapGetters(["cartOrder"]) },
};
</script>
