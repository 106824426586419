<template lang="pug">
v-btn(
  rounded,
  depressed,
  small,
  color="#E4E6EB",
  @click="toggle()",
  :loading="loading",
  v-if="biz"
)
  v-icon(small, left, :color="color") mdi-heart
  span {{ biz.stats.fav }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters(["biz"]),
    isFavored() {
      if (!this.biz) return false;
      const preference = this.$auth.user()?.preference;
      return preference?.myfavs?.includes(this.biz._id);
    },
    color() {
      return this.isFavored ? "red" : "grey";
    },
  },
  methods: {
    async toggle() {
      if (!this.biz) return;
      if (!this.$auth.check()) {
        this.$router.push("/login");
        return;
      }
      this.loading = true;
      const userId = this.$auth.user()._id;
      const bizId = this.biz._id;
      let bizData = {
        criteria: { _id: bizId },
        action: { $inc: { "stats.fav": 1 } },
      };
      let userData = {
        criteria: { _id: userId },
        action: { $addToSet: { "preference.myfavs": bizId } },
      };
      if (this.isFavored) {
        bizData.action = { $inc: { "stats.fav": -1 } };
        userData.action = { $pull: { "preference.myfavs": bizId } };
      }
      const { stats } = await this.$api.biz.update(bizData);
      if (stats) this.biz.stats = stats;

      const user = await this.$api.user.update(userData);
      this.$auth.user(user);
      this.loading = false;
    },
  },
};
</script>
