<template lang="pug">
a(@click="save")
  v-img(:src="icon", width="160")
</template>

<script>
export default {
  props: ["event", "ticket", "time_items", "ticket_item"],
  data() {
    return {
      icon: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2FAdd_to_Apple_Wallet_rgb_US-UK.svg?alt=media&token=a4265892-dab5-4573-a512-4fa357accfbc",
    };
  },
  methods: {
    save() {
      console.log("save");
      let data = {
        passTypeIdentifier: "ticket",
        teamIdentifier: "CLU6U824VM",
        description: "Ticket for a local event",
        logoText: this.event.title,
        ticket: {
          primaryFields: [
            {
              key: "offer",
              value: this.ticket_item.name,
              label: this.ticket.ticket_number,
            },
          ],
        },
      };
      console.log(data);
    },
  },
};
</script>