<template lang="pug">
v-row
  v-col(
    cols="12",
    sm="6",
    md="4",
    v-for="(item, index) in items",
    :key="index"
  )
    Cell(:item="item")
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Cell from "./Cell";

export default {
  components: { Cell },
  computed: {
    ...mapGetters(["press_reports"]),
    items() {
      return this.press_reports?.toSorted((a, b) => b.date - a.date);
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setPressReports"]),
    async load() {
      const params = { criteria: {} };
      const result = await this.$api.corp.pressReport.list(params);
      this.setPressReports(result);
    },
  },
};
</script>
