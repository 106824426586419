<template lang="pug">
v-select(
  outlined,
  hide-details,
  v-model="content",
  :items="items",
  dense,
  append-icon="",
  menu-props="auto"
)
</template>

<script>
export default {
  props: ["value", "items"],
  data() {
    return { content: this.value };
  },
  watch: {
    value() {
      this.content = this.value;
    },
    content(newval, oldval) {
      if (newval == oldval) return;
      if (newval) {
        this.$emit("input", newval);
      }
    },
  },
};
</script>