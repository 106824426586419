<template lang="pug">
v-container
  .headline.text-center.mb-5 FAQs
  v-expansion-panels(focusable)
    v-expansion-panel(
      v-for="(group, gindex) in items",
      :key="gindex + 'group'"
    )
      v-expansion-panel-header.subtitle-2 {{ group.title }}
      v-expansion-panel-content
        div(v-for="(item, index) in group.items", :key="index + 'item'") {{ item }}
</template>

<script>
import NavGroups from "@/components/JSON/gift-cards-faqs.json";

export default {
  data() {
    return {
      items: NavGroups,
    };
  },
};
</script>