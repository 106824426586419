<template lang="pug">
table(v-if="cartOrder")
  tr(v-if="cartOrder.payment.dish > 0")
    td Subtotal
    td.text-right {{ cartOrder.payment.dish | currency }}
  TaxAndFee
  tr(v-if="cartOrder.payment.gift > 0")
    td Gift
    td.text-right {{ cartOrder.payment.gift | currency }}
  tr(v-if="cartOrder.payment.fee > 0")
    td Delivery Fee
    td.text-right {{ cartOrder.payment.fee | currency }}
  tr(
    v-for="(item, index) in cartOrder.payment.selflane.items",
    :key="index",
    v-if="cartOrder.payment.selflane && item.name != 'service_fee'"
  )
    td.text-capitalize {{ item.name | selflaneFeeName }}
    td.text-right {{ item.value | currency }}
  tr(v-if="cartOrder.payment.tip > 0")
    td Tip
      v-tooltip(bottom)
        template(v-slot:activator="{ on }")
          v-icon.ml-2(v-on="on", small, color="grey") mdi-information
        span Pick up order tip goes to restaurant staff, delivery order tip goes to driver.
    td.text-right {{ cartOrder.payment.tip | currency }}
  tr.success--text.font-weight-medium(
    v-for="(item, index) in cartOrder.payment.deduction.items",
    :key="index + 'deduction'",
    v-if="cartOrder.payment.deduction"
  )
    td.text-capitalize {{ item.name }}
    td.text-right {{ -item.value | currency }}
  tr
    td.font-weight-medium Total
    td.text-right.font-weight-medium {{ cartOrder.payment.total | currency }}
  tr.green--text(v-if="saved > 0")
    td.font-weight-medium Saved
    td.text-right.font-weight-medium {{ -saved | currency }}
</template>

<script>
import { mapGetters } from "vuex";
import TaxAndFee from "./TaxAndFee";

export default {
  components: { TaxAndFee },
  filters: {
    selflaneFeeName(val) {
      return val.replace("_", " ");
    },
  },
  computed: {
    ...mapGetters(["cartOrder", "cartPromotion"]),
    saved() {
      /// saved subtotal by promotion
      if (!this.cartPromotion) return 0;
      if (this.cartOrder.payment.gift > 0) return 0;
      if (this.cartPromotion.minimum > this.cartOrder.payment.dish) return 0;
      /// calculate in two types: fix/pct
      let amount = 0;
      if (this.cartPromotion.type === "fix") {
        amount = Math.round(this.cartPromotion.fix * 100) / 100;
      } else {
        const tobeapplied = Math.min(
          this.cartOrder.payment.dish,
          this.cartPromotion.limit
        );
        amount =
          Math.round((tobeapplied * this.cartPromotion.pct * 100) / 100) / 100;
      }
      amount = Math.min(
        amount,
        this.cartOrder.payment.dish + this.cartOrder.payment.tax
      );
      return amount;
    },
  },
};
</script>

<style lang="sass" scoped>
table
  width: 100%
  font-size: 15px

table tr
  margin-bottom: 4px
</style>