const state = {
  punchcards: []
}

const getters = {
  punchcards: state => state.punchcards
}

const actions = {
  setPunchcards: ({ commit }, data) => { commit('setPunchcards', data) },
  updatePunchcard: ({ commit }, data) => { commit('updatePunchcard', data) },
  resetPunchcards: ({ commit }) => { commit('resetPunchcards') }
}

const mutations = {
  setPunchcards(state, punchcards) {
    state.punchcards = punchcards
  },
  updatePunchcard(state, punchcard) {
    state.punchcards = state.punchcards.map(o => {
      return (o._id === punchcard._id) ? punchcard : o
    })
  },
  resetPunchcards(state) {
    state.punchcards = []
  }
}

export default { state, getters, actions, mutations }