<template lang="pug">
div(v-if="isClosed || isPaused")
  v-alert.mb-10(type="error", dense, v-if="isClosed") Temporarily Closed. {{ biz.schedule.temporary_close.reason }}
  v-alert.mb-10(type="info", dense, v-else-if="isPaused") Business has paused accepting orders. Next open: {{ next_open | datetime(null, timezone) }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["biz", "timezone"]),
    isClosed() {
      return this.biz?.schedule?.temporary_close?.status == true;
    },
    next_open() {
      return this.biz?.schedule?.next_open || 0;
    },
    isPaused() {
      return this.next_open > Date.now();
    },
  },
};
</script>

<style lang="sass" scoped>
#checkout-cart
  max-width: 600px
  margin-left: auto
  margin-right: auto
</style>