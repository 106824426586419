<template lang="pug">
.mb-3.text-center
  .sl-title Your Gift Card Balance
  .title.success--text(v-for="(item, index) in items", :key="index") {{ item.value | currency }} {{ item.currency }}
  v-btn.text-capitalize(
    v-if="items && items.length",
    @click="showActivities()",
    text,
    color="secondary"
  ) Show Activities
  v-btn.text-capitalize(
    v-if="items && items.length",
    @click="showTransfer()",
    text,
    color="secondary"
  ) Send Gift Cards
  v-btn.text-capitalize(@click="claimTransfer()", text, color="secondary") Claim Gift Card
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      gfbalance: null,
    };
  },
  computed: {
    items() {
      if (!this.gfbalance) return [];
      let items = [];
      if (this.gfbalance.balance_usd && this.gfbalance.balance_usd > 0) {
        items.push({
          currency: "USD",
          value: this.gfbalance.balance_usd / 100,
        });
      }
      if (this.gfbalance.balance_cad && this.gfbalance.balance_cad > 0) {
        items.push({
          currency: "CAD",
          value: this.gfbalance.balance_cad / 100,
        });
      }
      return items;
    },
  },
  mounted() {
    this.load();
    EventBus.$on("reload-gfbalance", this.load);
  },
  destroyed() {
    EventBus.$off("reload-gfbalance", this.load);
  },
  methods: {
    async load() {
      if (!this.$auth.check()) return;
      const userId = this.$auth.user()._id;
      if (!userId) return;
      const params = {
        criteria: {
          user: userId,
        },
      };
      const res = await this.axios.post("/gfbalance/balance/retrieve", params);
      this.gfbalance = res.data;
    },
    showActivities() {
      EventBus.$emit("show-gfbalance-activities");
    },
    showTransfer() {
      EventBus.$emit("show-gfbalance-transfer", this.gfbalance);
    },
    claimTransfer() {
      EventBus.$emit("show-gfbalance-transfer-claim", this.gfbalance);
    },
  },
};
</script>