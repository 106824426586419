<template lang="pug">
div
  v-list-item
    v-list-item-content
      v-list-item-subtitle Preferred Name
      v-list-item-title {{ preferred }}
    v-list-item-action
      v-btn(icon, @click="edit()", :loading="loading")
        v-icon(small) mdi-pencil
  v-dialog(v-model="dialog", width="300", @keydown.esc="dialog = false")
    v-card
      v-card-title
      v-form(@submit.prevent="submit")
        v-card-text
          v-text-field(
            label="Preferred Name",
            hint="Name used at restaurant",
            persistent-hint,
            v-model.trim="name",
            :error-messages="nameErrors",
            :counter="15",
            @blur="$v.name.$touch()",
            autofocus
          )
        v-card-actions
          v-btn(
            block,
            :disabled="$v.$invalid",
            type="submit",
            color="secondary",
            :loading="loading"
          ) save
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: { name: { required, maxLength: maxLength(15) } },
  data() {
    return {
      dialog: false,
      name: "",
      loading: false,
    };
  },
  computed: {
    preferred() {
      const user = this.$auth.user();
      if (!user) return "";
      return user.name.preferred;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Required");
      !this.$v.name.maxLength && errors.push("No more than 15 charactors");
      return errors;
    },
  },
  methods: {
    edit() {
      this.dialog = true;
      this.name = this.preferred;
      this.$v.$reset();
    },
    async submit() {
      const user = this.$auth.user();
      if (!user) return;
      this.name = this.name.trim();
      this.loading = true;
      const params = {
        criteria: { _id: user._id },
        action: { $set: { "name.preferred": this.name } },
      };
      try {
        const { data } = await this.axios.post("/users/update", params);
        this.dialog = false;
        this.$auth.user(data);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
