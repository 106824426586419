<template lang="pug">
.sticky.menu-top-bar
  DropMenu(:menus="menus", @select="handleClick")
  v-slide-group.right-group(
    :show-arrows="show_arrow",
    center-active,
    v-model="selectedIndex",
    @click:next="next",
    @click:prev="prev"
  )
    v-slide-item(v-for="(item, index) in items", :key="index")
      button.button(
        :class="{ active: index == selectedIndex }",
        @click="handleClick(item)"
      ) {{ item.name }}
</template>

<script>
import DropMenu from "./DropMenu";
import _ from "underscore";

export default {
  components: { DropMenu },
  props: ["menus"],
  data() {
    return { selectedIndex: 0, show_arrow: true };
  },
  computed: {
    items() {
      const data = _.chain(this.menus)
        .map((menu) => {
          return _.map(menu.courses, (course) => {
            const name = course.course.name;
            const id = course.id;
            const n = course.n;
            return { name, id, n };
          });
        })
        .flatten()
        .value();
      return data;
    },
  },
  methods: {
    handleClick(item) {
      this.scrollTo(item.id);
      this.selectedIndex = item.n;
    },
    prev() {
      let n = this.selectedIndex - 1;
      if (n < 0) return;
      let id;
      this.menus.forEach((menu) => {
        menu.courses.forEach((course) => {
          if (course.n === n) id = course.id;
        });
      });
      if (id) {
        this.scrollTo(id);
        this.selectedIndex = n;
      }
    },
    next() {
      let n = this.selectedIndex + 1;
      let id;
      this.menus.forEach((menu) => {
        menu.courses.forEach((course) => {
          if (course.n === n) id = course.id;
        });
      });
      if (id) {
        this.scrollTo(id);
        this.selectedIndex = n;
      }
    },
    scrollTo(id) {
      if (!id) return;
      const el = document.getElementById(id);
      if (!el) return;
      const y = el.getBoundingClientRect().top + window.scrollY - 200;
      window.scrollTo({ top: y, behavior: "smooth" });
    },
  },
};
</script>

<style lang="sass" scoped>
.sticky
  position: -webkit-sticky
  position: sticky
  top: 60px
  z-index: 4
  background: #F0F2F5
  border-bottom: 1px solid #E7E7E7
  margin-bottom: 12px

.menu-top-bar
  display: flex
  align-items: center
  justify-content: space-between
  overflow: hidden

.button
  font-size: 16px
  font-weight: 400
  color: #494949
  padding-top: 11px
  padding-bottom: 6px
  margin-right: 22px
  max-width: 160px
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  border-bottom: 5px solid #F0F2F5

.active
  font-weight: 500
  color: #202020
  border-bottom: 5px solid #202020

.right-group
  flex: 1
  overflow: hidden
</style>