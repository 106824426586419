<template lang="pug">
.head
  img(:src="icon")
  .head-title Welcome to Selflane
  .sl-secondary-text We help local businesses to serve better
  .left-corner
  .right-corner
</template>

<script>
export default {
  data() {
    return {
      icon: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Fsignin_smile.svg?alt=media&token=f00123d2-0180-4636-86bd-12eb2949083c",
    };
  },
};
</script>

<style lang="sass" scoped>
.head
  background-color: #fafafa
  border-top-left-radius: 4px
  border-top-right-radius: 4px
  height: 113px
  margin-left: 14px
  margin-right: 14px
  text-align: center
  position: relative
  padding-top: 37px

  &-title
    font-weight: 500
    font-size: 24px

img
  position: absolute
  top: -48px
  left: 50%
  margin-left: -55px

.left-corner
  position: absolute
  bottom: 0
  left: -10px
  width: 10px
  height: 8px
  background: linear-gradient( to bottom right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, #e5e5e5 50%, #e5e5e5 100% )

.right-corner
  position: absolute
  bottom: 0
  right: -10px
  width: 10px
  height: 8px
  background: linear-gradient( to bottom left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, #e5e5e5 50%, #e5e5e5 100% )
</style>