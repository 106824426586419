<template lang="pug">
v-container.py-0(v-if="biz")
  .info-box
    .d-flex.flex-row.justify-center(v-if="isNotPublic")
      v-alert.mb-3(dense, type="error") This business is currently not public, you won't be able to place orders with it.
    LimitedAccess(:biz="biz")
    .d-flex.flex-row.justify-center(v-if="isClosed")
      v-alert(type="error", dense) Temporarily Closed. {{ biz.schedule.temporary_close.reason }}
    Logo(:logo="biz.logo")
    POSNotice(v-if="usePOS")
    h1.title {{ biz.name }}
    .my-description.body-2 {{ biz.description }}
    Contact
    Schedule(:schedule="biz.schedule")
    OrderType.my-2(:type="biz.orderType")
    DelivTier.my-2
    TopActions
</template>

<script>
import Schedule from "./Schedule";
import { mapGetters } from "vuex";
import Contact from "./Contact";
import OrderType from "./OrderType";
import DelivTier from "./DelivTier";
import Logo from "./Logo";
import TopActions from "./TopActions/index";
import POSNotice from "./POSNotice";
import LimitedAccess from "/libs/components/Biz/LimitedAccess";

export default {
  components: {
    Logo,
    Contact,
    Schedule,
    OrderType,
    DelivTier,
    TopActions,
    POSNotice,
    LimitedAccess,
  },
  data() {
    return { distance: 0 };
  },
  computed: {
    ...mapGetters(["biz", "bizService"]),
    isNotPublic() {
      return !this.biz?.status?.byAdmin;
    },
    isClosed() {
      return this.biz?.schedule?.temporary_close?.status;
    },
    usePOS() {
      return this.bizService?.pos?.status;
    },
  },
  methods: {
    reserve() {
      this.$router.push("/bizs/" + this.biz.url + "/reserve");
      this.$vuetify.goTo("#bizMainCon");
    },
  },
};
</script>

<style lang="sass" scoped>
.info-box
  position: relative
  background-color: white
  border-top-left-radius: 6px
  border-top-right-radius: 6px
  padding: 16px
  padding-top: 66px
// limit description to 3 lines
.my-description
  display: -webkit-box
  -webkit-line-clamp: 3
  -webkit-box-orient: vertical
  overflow: hidden
</style>
