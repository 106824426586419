<template lang="pug">
v-btn(color="primary", text, block, @click="handler()", :loading="loading") Order This Again
</template>

<script>
import _ from "underscore";
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["order"],
  data() {
    return {
      loading: false,
      modifiers: [],
    };
  },
  computed: { ...mapGetters(["cartOrder", "cartUrl"]) },
  methods: {
    ...mapActions(["addDishToCart"]),
    async handler() {
      if (!this.order || !this.order.seller || !this.order.seller.id) return;
      const bizId = this.order.seller.id;
      try {
        // load modifiers
        const bizModifiers = await this.$api.bizModifier.list({
          criteria: { bizId: bizId, "modifier.ol": { $ne: false } },
        });
        this.modifiers = _.chain(bizModifiers)
          .pluck("modifier")
          .each((o) => {
            o.choices = o.choices?.filter((c) => c.ol);
          })
          .reject((o) => !o.choices?.length)
          .value();
        this.$store.dispatch("setModifiers", this.modifiers);
        // load biz
        const biz = await this.$api.biz.retrieve({ criteria: { _id: bizId } });
        this.reorder(biz);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    reorder(biz) {
      const unitPrice = this.$options.filters.unitPrice;
      if (!this.order || !biz) return;
      if (this.order.dishes.length === 0) {
        this.$toast.error("No dishes found in this order");
        return;
      }
      _.each(this.order.dishes, (dish) => {
        // ensure dish still exist
        const found = biz.dishes.find((o) => o._id === dish._id);
        if (found) {
          const unavailable = found.ooo || found.hide;
          if (!unavailable) {
            let clone = JSON.parse(JSON.stringify(found));
            clone.quantity = dish.quantity;
            this.matchModifiers(clone, dish);
            unitPrice(clone);
            this.addDishToCart({ biz: biz, dish: clone });
          }
        }
      });
      if (!this.cartUrl) {
        this.$toast.error(
          "Invalid cart url. Plesae click the cart icon to retry."
        );
        return;
      }
      this.$emit("close");
      if (this.$route.path !== this.cartUrl) this.$router.push(this.cartUrl);
    },
    /**
     * Match selected choices with the current modifier setup and prices
     */
    matchModifiers(dish_prinstine, dish_used) {
      dish_prinstine.modifiers = [];
      const usedChoices = _.chain(dish_used.modifiers)
        .pluck("choices")
        .flatten()
        .value();
      const usedSubChoices = _.chain(dish_used.modifiers)
        .pluck("associatedChoices")
        .flatten()
        .value();
      _.each(dish_prinstine.modifierIDs, (id) => {
        let found = this.modifiers.find((o) => o._id === id);
        if (found && found.choices) {
          found = JSON.parse(JSON.stringify(found));
          _.each(found.choices, (choice) => {
            const matched_choice = usedChoices.find(
              (o) => o.name == choice.name
            );
            if (matched_choice) {
              choice.quantity = matched_choice.quantity || 1;
              choice.selected = matched_choice.selected;
            }
          });
          _.each(found.associatedChoices, (subchoice) => {
            const matched_choice = usedSubChoices.find(
              (o) => o.name == subchoice.name
            );
            if (matched_choice) {
              subchoice.selected = matched_choice.selected;
              subchoice.halfhalf = matched_choice.halfhalf;
              subchoice.quantity = matched_choice.quantity;
            }
          });
          // ensure at least one choice is selected in required modifiers
          if (found.required) {
            const firstSelected = found.choices.find((o) => o.selected);
            if (!firstSelected && found.choices && found.choices.length) {
              found.choices[0].selected = true;
            }
          }
          dish_prinstine.modifiers.push(found);
        }
      });
    },
  },
};
</script>
