<template lang="pug">
div
  v-card.mb-10(v-if="event && biz", outlined)
    v-card-text
      Info
      Items
      PaymentBlock.mb-5
      v-row.mb-5
        v-col(cols="6")
          Name
        v-col(cols="6")
          PhoneField(v-model="eventCart.user_phone")
      .d-flex.flex-row
        v-btn.px-10(
          @click="handleSubmit()",
          :loading="loading",
          depressed,
          color="primary",
          :disabled="!validCart"
        ) SUBMIT
        v-spacer
        v-btn(@click="resetEventCart()", icon)
          v-icon mdi-delete
  v-dialog(v-model="dialog", persistent, max-width="450")
    v-card
      v-toolbar(color="success", flat, dark)
        .subtitle-2 Thank You!
        v-spacer
        v-btn.text-capitalize(outlined, to="/user/wallet/event", rounded) Go to My Tickets
      v-card-text
        div You're purchased {{ total_purchased_tickets }} tickets.
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Info from "./Info";
import Items from "./Items";
import Name from "./Name";
import PaymentBlock from "./PaymentBlock";

export default {
  components: { Info, Items, PaymentBlock, Name },
  props: ["id"],
  data() {
    return {
      loading: false,
      dialog: false,
      order: null,
    };
  },
  computed: {
    ...mapGetters(["biz", "event", "eventCart"]),
    total_purchased_tickets() {
      return this.order?.items?.reduce((a, b) => a + b.quantity, 0) || 0;
    },
    validCart() {
      return (
        this.eventCart &&
        this.eventCart.event_id &&
        this.eventCart.event_items?.length &&
        this.eventCart.user_name &&
        this.eventCart.user_phone &&
        this.eventCart.user_phone.length > 7
      );
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setBiz", "setEvent", "resetEventCart"]),
    async load() {
      if (!this.id) return;
      const params = { event_id: this.id };
      try {
        const url = "/event/entries/retrieve";
        const { data } = await this.axios.post(url, params);
        this.setEvent(data);
      } catch (e) {
        //
      }
      this.loadBiz();
    },
    async loadBiz() {
      if (!this.event) return;
      const params = { criteria: { _id: this.event.biz_id } };
      try {
        const { data } = await this.axios.post("/bizs/retrieve", params);
        this.setBiz(data);
      } catch (e) {
        //
      }
    },
    async handleSubmit() {
      this.loading = true;
      try {
        const url = "/event/orders/create";
        const { data } = await this.axios.post(url, this.eventCart);
        this.order = data;
        this.resetEventCart();
        this.dialog = true;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
