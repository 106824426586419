<template lang="pug">
.my-line-items(v-if="type")
  .order-type(v-if="type.pickup.status") Pick up / {{ type.pickup.beforehand | leadtime }}
  .order-type(v-if="type.curbside && type.curbside.status") Curbside / {{ type.curbside.beforehand | leadtime }}
  .order-type(v-if="hasDelivery") Deliver / {{ type.delivery.beforehand | leadtime }}
</template>

<script>
export default {
  props: ["type"],
  computed: {
    hasDelivery() {
      return this.type?.delivery?.status && !this.type?.delivery?.deliv;
    },
  },
};
</script>

<style lang="sass" scoped>
// flex, allow wrap, gap 10px
.my-line-items
  display: flex
  flex-wrap: wrap
  gap: 10px
  row-gap: 10px
.order-type
  font-size: 14px
  display: inline-block
  font-weight: 500
  border-bottom: 2px solid
</style>
