const state = {
  stripeSources: []
}

const getters = {
  stripeSources: state => state.stripeSources
}

const actions = {
  setStripeSources: ({ commit }, data) => { commit('setStripeSources', data) },
  addStripeSource: ({ commit }, data) => { commit('addStripeSource', data) },
  updateStripeSource: ({ commit }, data) => { commit('updateStripeSource', data) },
  removeStripeSource: ({ commit }, data) => { commit('removeStripeSource', data) }
}

const mutations = {
  setStripeSources(state, stripeSources) {
    state.stripeSources = stripeSources
  },
  addStripeSource(state, data) {
    state.stripeSources.push(data)
  },
  updateStripeSource(state, data) {
    state.stripeSources = state.stripeSources.map(o => {
      return (o.id === data.id) ? data : o
    })
  },
  removeStripeSource(state, data) {
    state.stripeSources = state.stripeSources.filter(o => o.id !== data.id)
  }
}

export default { state, getters, actions, mutations }