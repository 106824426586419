<template lang="pug">
.my-3.white(v-if="recentBizs && recentBizs.length")
  v-container
    .sl-title Recently Viewed
    BizList(:bizs="recentBizs")
</template>

<script>
import { mapGetters } from "vuex";
import BizList from "@/components/Bizs/ListRow";

export default {
  components: { BizList },
  computed: {
    ...mapGetters(["recentBizs"]),
  },
};
</script>
