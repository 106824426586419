<template lang="pug">
div(v-if="orders && orders.length > 0")
  .subtitle-2.my-3 Recent Orders
  Cell.mb-3(:order="item", v-for="item in orders", :key="item._id")
</template>

<script>
import Cell from "./../Cell/index";

export default {
  components: { Cell },
  props: ["orders"],
};
</script>
