<template lang="pug">
div
  v-col(align="center")
    div(ref="googleLoginBtn")
    .sl-secondary-text.my-3 simple and secure with Google signin.
      a.ml-1(@click="toggleWhy") why?
  .d-flex.flex-row.align-center
    .or-divider
    .sl-secondary-text.mx-5 or with email
    .or-divider
  v-dialog(v-model="dialog", width="400")
    v-card
      v-card-title Sign in with Google
      v-card-text We ask for your email address to set up an account. To make sure you own the email address, you would need to verify it via an email sent by us. Using Google sign in bypasses this verification process. You also don't need to set up a new password for login. We don't take any information from your Google account other than the email address and your public name.
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return { dialog: false };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    handleSignin(user) {
      const tokenId = user.credential;
      const url = "/auth/google/cftoken" + "?id_token=" + tokenId;
      let redirect = { name: "Home" };
      if (this.$auth.redirect()) {
        redirect = this.$auth.redirect().from;
      }
      this.$auth.login({
        url: url,
        method: "GET",
        fetchUser: true,
        redirect: redirect,
        rememberMe: true,
        success() {
          EventBus.$emit("login");
        },
      });
    },
    toggleWhy() {
      this.dialog = true;
    },
    initialize() {
      const clientId = process.env.VUE_APP_GOOGLE_CLIENT;
      // Wait for google to load
      if (!window.google) {
        setTimeout(() => {
          this.initialize();
        }, 500);
        return;
      }
      window.google.accounts.id.initialize({
        client_id: clientId,
        callback: this.handleSignin,
        auto_select: true,
      });
      window.google.accounts.id.renderButton(this.$refs.googleLoginBtn, {
        text: "signin_with", // or 'signup_with' | 'continue_with' | 'signin'
        size: "large", // or 'small' | 'medium'
        width: 280, // max width 400
        theme: "filled_blue", // or 'filled_black' |  'filled_blue'
        shape: "pill", // or 'pill'
        logo_alignment: "left", // or 'center'
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.or-divider
  border-bottom: 1px solid #dddddd
  flex: 1
</style>