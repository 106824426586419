/**
 * Returns the month name
 * @param {number} month - The month number
 * @param {boolean} short - If true, return the short version of the month
 * @returns {string} The month name
 * @example
 * // returns 'January'
 * month(1)
 */

export default handler

function handler(month, short) {
  const d = new Date()
  d.setMonth(month - 1)
  return d.toLocaleString('default', { month: short ? 'short' : 'long' })
}