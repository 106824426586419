/// Promotion banner on Selflane Homepage /// Dedicated to Chains

<template lang="pug">
.white
  v-container(v-if="chains && chains.length")
    .d-flex.flex-row.align-center.justify-space-between
      .sl-title Featured Chains
    v-slide-group.mt-2(:show-arrows="show_arrow", ref="chains")
      v-slide-item(v-for="(item, index) in chains", :key="index")
        Cell(:chain="item")
</template>

<script>
import Cell from "./Cell";
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  components: { Cell },
  data() {
    return {
      chains: [],
      show_arrow: true,
    };
  },
  computed: {
    ...mapGetters(["selectedCity"]),
  },
  mounted() {
    this.load();
    EventBus.$on("set-selected-city", this.load);
  },
  destroyed() {
    EventBus.$off("set-selected-city", this.load);
  },
  methods: {
    async load() {
      if (!this.selectedCity) return;
      this.chains = [];
      const { country, states } = this.selectedCity;
      const params = {
        criteria: { level: 1, country, states },
        select: "logo url name",
      };
      this.chains = await this.$api.chain.account.list(params);
      this.chains.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
};
</script>
