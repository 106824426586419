<template lang="pug">
.d-flex.flex-row.align-center 
  .sl-title.my-5 Password
  v-spacer
  Update
</template>

<script>
import Update from "./Update";
export default {
  components: { Update },
};
</script>
