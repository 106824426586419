<template lang="pug">
v-list-item(@click="select")
  v-list-item-content
    v-list-item-title {{ name }}
    v-list-item-subtitle
      span(v-if="item.address")
        span.mr-1(v-if="item.industry != 4") {{ item.address.line1 }},
        span {{ item.address.city }}
      span.ml-3(v-if="distance") {{ distance | readableDistance(unit) }}
  v-list-item-action
    v-icon(:color="iconColor") {{ icon }}
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ChainBizItem",
  props: ["item", "distance"],
  computed: {
    ...mapGetters(["biz"]),
    isSelected() {
      return this.biz?._id == this.item?._id;
    },
    name() {
      return this.item.short_name || this.item.name;
    },
    unit() {
      if (this.item?.address?.country == "CA") return "km";
      return "mi.";
    },
    icon() {
      return this.isSelected ? "mdi-check-circle" : "mdi-chevron-right";
    },
    iconColor() {
      return this.isSelected ? "success" : "";
    },
  },
  methods: {
    ...mapActions(["setConfirmedBiz"]),
    select() {
      this.setConfirmedBiz(this.item._id);
      const url = "/bizs/" + this.item.url;
      const currentPath = this.$route.path;
      if (url != currentPath) this.$router.push(url);
      this.$emit("select");
    },
  },
};
</script>
