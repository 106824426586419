<template lang="pug">
v-card(v-if="member", outlined, height="100%")
  v-toolbar(flat, dense)
    a.subtitle-2(v-if="biz", @click="toURL(biz.url)") {{ biz.name }}
    v-spacer
    v-menu
      template(v-slot:activator="{ on }")
        v-icon(color="secondary", v-on="on") mdi-dots-horizontal
      v-list.py-0(dense)
        v-list-item(@click="showActivities()") Activities
        v-list-item(@click="showPointShop()") Point Shop
        v-list-item(@click="showCoupons()") Emailed Coupons
  v-card-text
    .text-center
      .caption Points Balance
      .title {{ member.balance }}
      img(:src="icon", @click.stop="showQRCode()", role="button")
      .my-5
      BizAddressPhone(:biz="biz")
      .caption Since {{ member.created | date }}
</template>

<script>
import { EventBus } from "@/event-bus.js";
import BizAddressPhone from "./BizAddressPhone";
import { mapGetters } from "vuex";

export default {
  components: { BizAddressPhone },
  props: ["member"],
  data() {
    return {
      icon: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Fqrcode_square.svg?alt=media&token=183eaa78-2ea7-4f7c-84ea-39333837b4b8",
    };
  },
  computed: {
    ...mapGetters(["bizAccounts"]),
    biz() {
      return this.bizAccounts.find((o) => o._id == this.member.biz);
    },
  },
  methods: {
    toURL(url) {
      if (!url) return;
      this.$router.push("/bizs/" + url);
    },
    showActivities() {
      if (!this.member) return;
      EventBus.$emit("show-member-activities", this.member._id);
    },
    showQRCode() {
      if (!this.member) return;
      EventBus.$emit(
        "show-qrcode",
        "sl:member:" + this.member._id,
        "Member QR Code"
      );
    },
    showPointShop() {
      if (!this.member) return;
      EventBus.$emit("show-point-shop", this.member.biz);
    },
    showCoupons() {
      if (!this.member) return;
      EventBus.$emit(
        "show-member-email-promo",
        this.member.biz,
        this.member._id
      );
    },
  },
};
</script>
