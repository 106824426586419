<template lang="pug">
.cell-container(v-on="$listeners")
  .body-2.text--secondary {{ item.ddd }}
  .time-day(
    :class="{ selected: selected, today: item.today, disabled: item.disabled }"
  ) {{ item.dayofmonth }}
</template>

<script>
export default {
  name: "TimeDayCell",
  props: ["item", "selected"],
};
</script>

<style lang="sass" scoped>
// flex align column
.cell-container
  display: flex
  flex-direction: column
  align-items: center
  cursor: pointer
  gap: 8px

.time-day
  width: 40px
  height: 40px
  border-radius: 20px
  line-height: 40px
  font-size: 17px
  font-weight: 500
  text-align: center

  &.selected
    background-color: #FF6D32
    color: white

  &.today
    border: 1px #FF6D32 solid
  // strike through
  &.disabled
    cursor: not-allowed
    opacity: 0.5
    text-decoration: line-through
</style>
