export default function (input) {
  if (input == '01') return 'Sale'
  else if (input == '02') return 'Return'
  else if (input == '03') return 'Auth'
  else if (input == '04') return 'Post Auth'
  else if (input == '05') return 'Forced'
  else if (input == '06') return 'Adjust'
  else if (input == '08') return 'Activate'
  else if (input == '10') return 'Add'
  else if (input == '12') return 'Deactivate'
  else if (input == '16') return 'Void'
  else if (input == '17') return 'Void/Sale'
  else if (input == '18') return 'Void/Return'
  else if (input == '23') return 'Balance'
  else return ''
}