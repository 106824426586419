<template lang="pug">
v-row(wrap)
  v-col(cols="12", md="3", v-for="(item, index) in items", :key="index")
    Cell(:item="item")
</template>

<script>
import Cell from "./Cell";
import { mapActions } from "vuex";

export default {
  components: { Cell },
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setBizAccounts"]),
    async load() {
      const now = Date.now();
      const params = {
        criteria: {
          "sale_time.begin": { $lte: now },
          "sale_time.end": { $gte: now },
        },
      };
      const { data } = await this.axios.post("/event/entries/list", params);
      this.items = data;
      await this.loadBizs();
    },
    async loadBizs() {
      if (!this.items) return;
      const ids = this.items.map((o) => o.biz_id);
      const params = {
        criteria: { _id: { $in: ids }, select: "name address" },
      };
      try {
        const { data } = await this.axios.post("/bizs/list", params);
        this.setBizAccounts(data);
      } catch (e) {
        //
      }
    },
  },
};
</script>