<template lang="pug">
.resetpwd-container
  .form-container
    .form-title Reset Password
    .sl-secondary-text Please enter your email address to receive instructions for resetting password.
    v-form(@submit.prevent="submit")
      v-text-field(
        label="E-mail",
        v-model.trim="email",
        :error-messages="emailErrors",
        @blur="$v.email.$touch()",
        required,
        prepend-icon="email"
      )
      div(v-if="isReset")
        v-text-field(
          label="Code",
          v-model="code",
          v-mask="\"### ### ###\"",
          :error-messages="codeErrors",
          required,
          @blur="$v.code.$touch()",
          persistent-hint,
          hint="Found in email we just sent",
          prepend-icon="code"
        ) 
        v-text-field(
          name="password",
          type="password",
          label="New Password",
          v-model="password",
          :error-messages="passwordErrors",
          required,
          @blur="$v.password.$touch()",
          prepend-icon="lock_outline"
        ) 
      v-btn(
        block,
        type="submit",
        :disabled="loading || $v.$invalid",
        :loading="loading",
        color="primary"
      ) submit
      v-alert.mt-3(
        type="success",
        border="left",
        icon="mdi-information",
        dense,
        :value="gmailhint",
        transition="scale-transition"
      ) You can use Google Sign In
      span.caption.red--text(v-if="error") {{ error }}
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";
import { EventBus } from "@/event-bus.js";

export default {
  mixins: [validationMixin],
  validations() {
    if (!this.isReset) {
      return {
        email: { required, email },
      };
    } else {
      return {
        email: { required, email },
        password: { required, minLength: minLength(6) },
        code: { required },
      };
    }
  },
  data() {
    return {
      email: "",
      password: "",
      code: "",
      loading: false,
      error: null,
      isReset: false,
    };
  },
  computed: {
    gmailhint() {
      if (this.email) {
        return this.email.indexOf("gmail") > -1;
      }
      return false;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password is required");
      !this.$v.password.minLength &&
        errors.push("Password should be at least 6 characters.");
      return errors;
    },
    codeErrors() {
      const errors = [];
      if (!this.$v.code.$dirty) return errors;
      !this.$v.code.required && errors.push("Code is required");
      return errors;
    },
  },
  methods: {
    submit() {
      if (this.isReset) this.reset();
      else this.forget();
    },
    forget() {
      this.error = null;
      const options = { criteria: { email: this.email } };
      this.loading = true;
      this.axios
        .post("/users/forgetPWD", options)
        .then(() => {
          this.loading = false;
          this.isReset = true;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) this.error = err.response.data;
        });
    },
    async reset() {
      this.error = null;
      const options = {
        criteria: { email: this.email },
        code: this.code.replace(/[^\d]/g, ""),
        password: this.password,
      };
      this.loading = true;
      try {
        await this.axios.post("/users/resetPWD", options);
        this.login();
      } catch (e) {
        if (e.response) this.error = e.response.data;
      }
      this.loading = false;
    },
    login() {
      this.loading = true;
      this.error = null;
      let redirect = { name: "Home" };
      this.$auth.login({
        headers: { "Content-Type": "application/json" },
        data: { email: this.email, password: this.password },
        redirect: redirect,
        rememberMe: true,
        success() {
          this.loading = false;
          EventBus.$emit("login");
        },
        error(err) {
          this.loading = false;
          if (err.response) {
            this.error = err.response.data;
          } else {
            this.error = "Failed";
          }
        },
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.resetpwd-container
  display: flex
  flex-flow: column
  justify-content: center
  align-items: center
  margin-top: 100px
  margin-bottom: 100px

.form-container
  background: #ffffff
  border-radius: 4px
  overflow: hidden
  padding: 30px
  max-width: 390px
  width: 95%

.form-title
  text-align: center
  font-weight: 500
  font-size: 30px
  margin-bottom: 7px
</style>