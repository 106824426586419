const state = {
  reservations: []
}

const getters = {
  reservations: state => state.reservations
}

const actions = {
  setReservations: ({ commit }, data) => { commit('setReservations', data) },
  updateReservation: ({ commit }, data) => { commit('updateReservation', data) }
}

const mutations = {
  setReservations(state, data) {
    state.reservations = data
  },
  updateReservation(state, data) {
    state.reservations = state.reservations.map(o => {
      return (o._id === data._id) ? data : o
    })
  }
}

export default { state, getters, actions, mutations }
