<template lang="pug">
v-dialog(
  v-model="dialog",
  width="500",
  v-if="memberAccounts && memberAccounts.length"
)
  template(v-slot:activator="{ on }")
    v-btn.text-notransform(v-on="on", outlined, rounded, color="secondary") Manage Email Subscription
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Manage Member Email Subscription
    v-simple-table
      thead
        tr
          th Biz
          th Balance
          th.text-right Subscription
      tbody
        tr(v-for="(item, index) in memberAccounts", :key="index")
          td {{ bizName(item) }}
          td {{ item.balance }}
          td.text-right
            v-btn.text-notransform(
              small,
              rounded,
              depressed,
              v-if="item.subscribe",
              @click="unsubscribe(item)"
            ) unsubscribe
            v-btn(small, rounded, depressed, v-else, @click="subscribe(item)") subscribe
    v-card-text
      .caption Control email subscription for member's benefits.
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters(["memberAccounts", "bizAccounts"]),
  },
  methods: {
    ...mapActions(["updateMemberAccount"]),
    bizName(member) {
      return this.bizAccounts?.find((o) => o._id == member.biz)?.name || "Biz";
    },
    async unsubscribe(member) {
      if (!member) return;
      const params = { memberId: member._id };
      try {
        const { data } = await this.axios.post("/member/unsubscribe", params);
        this.updateMemberAccount(data);
        this.$toast.success("Unsubscribed");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    async subscribe(member) {
      if (!member) return;
      const params = { memberId: member._id };
      try {
        const { data } = await this.axios.post("/member/subscribe", params);
        this.updateMemberAccount(data);
        this.$toast.success("Subscribed");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>