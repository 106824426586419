<template lang="pug">
.info-body(v-if="biz")
  InfoBox
</template>

<script>
import { mapGetters } from "vuex";
import InfoBox from "./InfoBox";

export default {
  components: { InfoBox },
  computed: { ...mapGetters(["biz"]) },
};
</script>

<style lang="sass" scoped>
.info-body
  background-color: #1C1F34
  padding-top: 70px
  box-shadow: 0em 0em 0.4em rgba(0,0,0,0.3)
</style>
