<template lang="pug">
div
  v-row(wrap)
    v-col(
      cols="6",
      sm="4",
      md="3",
      v-for="(item, index) in promotions",
      :key="index"
    )
      CouponCell(
        :promotion="item",
        :claims="claims",
        :bizs="bizs",
        :chains="chains",
        @claimed="loadClaims"
      )
  .caption.my-10 Coupons are one time discounts issued by restaurants. They usually come with a spending requirement and an expiry date.
</template>

<script>
import CouponCell from "./CouponCell/index";

export default {
  components: { CouponCell },
  data() {
    return {
      loading: false,
      promotions: [],
      claims: [],
      bizs: [],
      chains: [],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async loadChains() {
      const ids = this.promotions?.map((o) => o.chain);
      this.chains = [];
      if (!ids?.length) return;
      const params = { criteria: { _id: { $in: ids } }, select: "name logo" };
      this.chains = await this.$api.chain.account.list(params);
    },
    async loadBizs() {
      const ids = this.promotions?.map((o) => o.biz);
      this.bizs = [];
      if (!ids?.length) return;
      const params = {
        criteria: { _id: { $in: ids } },
        select: "name address logo",
      };
      this.bizs = await this.$api.biz.list(params);
    },
    async load() {
      const now = Date.now();
      const params = { criteria: { expiry: { $gt: now } } };
      this.promotions = await this.$api.promotion.list(params);
      await this.loadBizs();
      await this.loadChains();
      await this.loadClaims();
      this.loading = false;
    },
    async loadClaims() {
      this.claims = [];
      if (!this.$auth.check()) return;
      if (!this.promotions) return;
      const ids = this.promotions.map((o) => o._id);
      const params = {
        criteria: { promotion: { $in: ids }, user: this.$auth.user()._id },
        select: "promotion applied",
      };
      const { data } = await this.axios.post("/promoclaim/list", params);
      this.claims = data;
    },
  },
};
</script>