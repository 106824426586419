<template lang="pug">
div(v-if="review")
  .review-title {{ review.title }}
  .text--secondary.body-2.mb-2 {{ review._id | date }}
    span.ml-2(v-if="!isSame") {{ bizName }}
  .body-2(v-html="review.content")
  .review-reply(v-if="review.replyt")
    div {{ review.reply }}
    .text--secondary.body-2 Replied {{ review.replyt | date }}
  .d-flex.flex-row.align-center.mt-1.flex-wrap
    v-rating(v-model="review.stars", readonly, small, dense)
    v-spacer
    LikeBtn(:review="review")
    IconBtn(
      title="Edit",
      icon="mdi-pencil",
      @click="edit",
      :outlined="false",
      text,
      v-if="isAuthor"
    )
    IconBtn(
      title="Order",
      icon="mdi-note",
      @click="showOrder",
      :outlined="false",
      :text="true",
      v-if="isManager || isAuthor"
    )
    Reply(:review="review", v-if="isManager")
    Share(:review="review", v-if="isManager")
    Status.ml-2(:review="review", v-if="isInternal")
    Delete(:review="review", v-if="isInternal")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";
import LikeBtn from "./../LikeBtn";
import Delete from "./Delete";
import Reply from "./Reply";
import Share from "./Share";
import Status from "./Status";

export default {
  components: { LikeBtn, Reply, Share, Status, Delete },
  props: {
    review: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { app_name: process.env.VUE_APP_NAME || "Selflane" };
  },
  computed: {
    ...mapGetters(["bizAccounts", "biz"]),
    isSame() {
      return this.review?.biz == this.biz?._id;
    },
    isManager() {
      return ["Selflane Business", "Selflane Internal"].includes(this.app_name);
    },
    isInternal() {
      return this.app_name == "Selflane Internal";
    },
    isAuthor() {
      const author = this.review?.author?.id;
      if (!author) return false;
      return author == this.$auth.user()?._id;
    },
    bizName() {
      return this.bizAccounts.find((o) => o._id == this.review.biz)?.name;
    },
  },
  methods: {
    showOrder() {
      EventBus.$emit("show-order-detail", this.review.order);
    },
    edit() {
      this.$emit("edit");
    },
  },
};
</script>

<style lang="sass" scoped>
.review-title
  font-size: 16px
  font-weight: 500

.review-reply
  position: relative
  background: linear-gradient(to bottom right, #f6f8fe, #eceefb)
  padding: 10px
  margin-top: 16px
  border-radius: 6px

  &:after
    content: ""
    position: absolute
    bottom: 100%
    left: 20px
    width: 0
    height: 0
    border-bottom: solid 10px #f5f5f8
    border-left: solid 5px transparent
    border-right: solid 10px transparent
</style>
