<template lang="pug">
span(v-if="servings > 1", :title="`Serves ${servings}`")
  v-icon(small) mdi-account-multiple
  .servings {{ servings }}
</template>

<script>
export default {
  props: {
    dish: { type: Object, required: true },
  },
  computed: {
    servings() {
      return this.dish.servings || 0;
    },
  },
};
</script>

<style  lang="sass" scoped>
.servings
  display: inline
  color: gray
  margin-left: 4px
  font-weight: 500
  font-size: 14px
</style>
