<template lang='pug'>
v-card(flat)
  v-toolbar(flat, dense)
    a.subtitle-2(v-if="biz", @click="toURL(biz.url)") {{ biz.name }}
    v-spacer
    v-btn.text-notransform(
      @click="showGiftCard()",
      outlined,
      small,
      rounded,
      color="secondary"
    ) Send
  v-divider
  v-card-text
    .text-center
      .title {{ card.balance | currency }}
      Download(:card="card")
      .caption(v-if="card.to && card.to.length") Sent to {{ card.to }}
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";
import Download from "/libs/components/Gift/Download";

export default {
  components: { Download },
  props: ["card"],
  computed: {
    ...mapGetters(["bizAccounts"]),
    biz() {
      return this.bizAccounts?.find((o) => o._id == this.card.issuer);
    },
  },
  methods: {
    toURL(url) {
      if (!url) return;
      this.$router.push("/bizs/" + url);
    },
    showGiftCard() {
      if (!this.card) return;
      EventBus.$emit("show-gift-card", this.card._id);
    },
  },
};
</script>
