import { render, staticRenderFns } from "./DishCell.vue?vue&type=template&id=60872bee&scoped=true&lang=pug"
import script from "./DishCell.vue?vue&type=script&lang=js"
export * from "./DishCell.vue?vue&type=script&lang=js"
import style0 from "./DishCell.vue?vue&type=style&index=0&id=60872bee&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.12_css-loader@6.11.0_webpack@5.95.0__lodash@4.17.21__jo3gmbkipzayyszbp6ysy2mmli/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60872bee",
  null
  
)

export default component.exports