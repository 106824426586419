<template lang="pug">
v-simple-table(dense)
  thead
    tr
      th Date
      th Sender
      th.text-right Amount
  tbody
    tr(v-for="(item, index) in transfers", :key="index")
      td {{ item.created | date }}
      td {{ item.sender }}
      td.text-right {{ (item.amount / 100) | currency }}
        span.ml-1.text-uppercase {{ item.currency }}
</template>

<script>
export default {
  props: ["transfers"],
};
</script>