const state = {
  reviews: []
}

const getters = {
  reviews: state => state.reviews
}

const actions = {
  setReviews: ({ commit }, data) => { commit('setReviews', data) },
  updateReview: ({ commit }, data) => { commit('updateReview', data) },
  addReview: ({ commit }, data) => { commit('addReview', data) }
}

const mutations = {
  setReviews(state, reviews) {
    state.reviews = reviews
  },
  updateReview(state, review) {
    state.reviews = state.reviews.map(o => {
      return (o._id === review._id) ? review : o
    })
  },
  addReview(state, review) {
    state.reviews.unshift(review)
  }
}

export default { state, getters, actions, mutations }