/// Promotion banner on Selflane Homepage 
/// Dedicated to Point of Interst

<template lang="pug">
.white.my-3(v-if="pois && pois.length")
  v-container
    .sl-title Point of Interest
    v-slide-group.mt-2(:show-arrows="show_arrow", ref="pois")
      v-slide-item(v-for="(item, index) in pois", :key="index")
        Cell(:poi="item")
</template>

<script>
import Cell from "./Views/Cell";
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  components: { Cell },
  data() {
    return {
      pois: [],
      show_arrow: true,
    };
  },
  computed: {
    ...mapGetters(["selectedCity"]),
  },
  mounted() {
    this.load();
    EventBus.$on("set-selected-city", this.load);
  },
  destroyed() {
    EventBus.$off("set-selected-city", this.load);
  },
  methods: {
    async load() {
      if (!this.selectedCity) return;
      this.pois = [];
      const params = {
        criteria: {
          "address.country": this.selectedCity.country,
          "address.state": this.selectedCity.state,
        },
        select: "logo url name",
      };
      this.pois = await this.$api.poi.list(params);
      this.pois.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
};
</script>
