<template lang="pug">
div
  Head
  Cart.my-5
  FAQs.my-5
  Activities
  Transfer
  ClaimTransfer
</template>

<script>
import Head from "./Head";
import Cart from "./Cart/index";
import FAQs from "./FAQs/index";
import Activities from "./Activities/index";
import Transfer from "./Transfer/index";
import ClaimTransfer from "./ClaimTransfer";

export default {
  components: { Head, Cart, FAQs, Activities, Transfer, ClaimTransfer },
  metaInfo() {
    const title = "Gift Cards | Selflane";
    const description =
      "Buy gift cards from hundreds of restaurants and send them to your friends and family.";
    return {
      title,
      meta: [{ name: "description", content: description }],
    };
  },
};
</script>