<template lang="pug">
div
  #map(ref="map")
  .my-5
    .section-description {{ event.address.name }}
    .section-description {{ event.address | address }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["event"]),
    center() {
      if (this.event && this.event.address) return this.event.address.geometry;
      return null;
    },
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(this.$refs.map, {
        center: this.center,
        zoom: 14,
        mapTypeControl: false,
        streetViewControl: false,
        styles: [
          { featureType: "poi.business", stylers: [{ visibility: "off" }] },
        ],
      });
      if (this.center) {
        this.map.setCenter(this.center);
        // eslint-disable-next-line no-undef
        new google.maps.Marker({
          position: this.center,
          map: this.map,
          clickable: false,
        });
      }
    },
  },
};
</script>


<style lang="sass" scoped>
#map
  height: 250px
  width: 100%
  background: gray

.section-description
  text-align: center
  font-size: 15px
  font-weight: 600
  color: #402F5A
</style>
