<template lang="pug">
v-card(shaped, v-if="items && items.length", flat)
  v-toolbar(dense, flat, color="navy", dark)
    .sl-title Coupons
  v-simple-table
    tbody
      tr(
        v-for="(item, index) in filtered",
        :key="index",
        @click="show(item)",
        role="button"
      )
        td
          .subtitle-2(v-if="item.type === 'fix'") {{ -item.fix | currency }}
          .subtitle-2(v-else) {{ -item.pct }}%
          .caption.text--secondary Expires on {{ item.expiry | date }}
        td.text-right
          ClaimAction(:item="item")
  v-card-actions(v-if="showMore")
    v-btn(text, color="primary", @click="all = true", block, small) More
  v-card-actions(v-else-if="all")
    v-btn(text, color="primary", @click="all = false", block, small) Less
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";
import ClaimAction from "./ClaimAction";
import { EventBus } from "@/event-bus.js";

export default {
  components: { ClaimAction },
  data() {
    return {
      limit: 3,
      all: false,
    };
  },
  computed: {
    ...mapGetters(["promotions", "claims"]),
    items() {
      return _.chain(this.promotions)
        .map((o) => {
          let user_claim = 0;
          const found = this.claims.find((claim) => claim.promotion == o._id);
          if (found) {
            if (found.applied > 0) user_claim = 2;
            else user_claim = 1;
          }
          o.user_claim = user_claim;
          return o;
        })
        .reject((o) => o.user_claim == 2)
        .value();
    },
    filtered() {
      if (this.all) return this.items;
      return this.items.slice(0, this.limit);
    },
    showMore() {
      if (this.all) return false;
      return this.items.length > this.limit;
    },
  },
  methods: {
    show(item) {
      EventBus.$emit("show-coupon", item);
    },
  },
};
</script>
