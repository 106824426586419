<template lang="pug">
div
  v-progress-linear(indeterminate, v-if="loading", color="secondary")
  .pa-3
    .page-head
      .page-title {{ title }}
      .link-box.link-box-right
        v-btn.text-capitalize(
          v-if="items",
          v-for="(item, index) in items",
          :key="index",
          small,
          active-class="secondary",
          :active="item.url === $route.path",
          :to="item.url",
          rounded,
          depressed
        ) {{ item.title }}
        v-btn.text-capitalize(
          v-if="doc",
          :href="doc",
          target="_blank",
          small,
          rounded,
          depressed
        )
          v-icon(left, small) mdi-book
          span Docs
        slot(name="header")
    v-divider.my-3
    div(v-if="avatar || name")
      .greeting-container
        v-avatar(v-if="avatar", size="48")
          img(:src="avatar")
        div(v-if="name") Hi, {{ name }}
        v-btn(
          icon,
          small,
          v-if="profile_url",
          :to="profile_url",
          color="grey lighten-1"
        )
          v-icon(small) mdi-pencil
      v-divider.my-3
    router-view(v-if="!loading")
    slot
</template>

<script>
export default {
  props: {
    avatar: { type: String, default: null }, // user avatar, for greeting
    name: { type: String, default: null }, // user name, for greeting
    profile_url: { type: String, default: null }, // user profile url, for greeting
    prefix: { type: String, default: null },
    title: { type: String, default: null },
    items: { type: Array, default: () => [] },
    doc: { type: String, default: null },
    loading: Boolean,
    description: { type: String, default: null },
  },
  metaInfo() {
    const prefix = this.prefix || process.env.VUE_APP_NAME || "Selflane";
    const title = prefix + ": " + this.title;
    const description =
      this.description ||
      "Selflane is a restaurant management platform that helps you manage your restaurant and grow your business.";
    return {
      title,
      meta: [{ name: "description", content: description }],
    };
  },
};
</script>

<style lang="sass" scoped>
.page-head
  display: flex
  flex-direction: row
  align-items: baseline
  gap: 12px
.page-title
  font-size: 1.3rem
  font-weight: 500
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  flex: 1
.greeting-container
  display: flex
  flex-direction: row
  align-items: center
  gap: 12px
  font-weight: 500
</style>
