<template lang="pug">
v-container
  v-btn(outlined, small, color="secondary", @click="toMenu()")
    v-icon(left) mdi-chevron-left
    span back to menu
  v-col(v-if="!settingup", align="center")
    div(v-if="member")
      .subtitle-2 Great! You're already a member of the business.
      MemberCard(:member="member", :bizs="[biz]")
    v-card.my-3(width="500", v-else)
      v-card-text
        v-form(@submit.prevent="submit")
          v-row(wrap)
            v-col(cols="6")
              v-text-field(
                label="First Name",
                v-model="firstName",
                :error-messages="firstNameErrors",
                @blur="$v.firstName.$touch()"
              )
            v-col(cols="6")
              v-text-field(
                label="Last Name",
                v-model="lastName",
                :error-messages="lastNameErrors",
                @blur="$v.lastName.$touch()"
              )
          v-row(wrap)
            v-col(cols="6")
              PhoneField(
                v-model="phone",
                :error-messages="phoneErrors",
                @blur="$v.phone.$touch()"
              )
            v-col(cols="6")
              v-text-field(
                label="Email",
                v-model="email",
                :error-messages="emailErrors",
                @blur="$v.email.$touch()"
              )
          BirthdayPicker(v-model="birthday")
          BirthdayPicker(v-model="anniv", label="Anniversary (optional)")
          v-btn(
            block,
            color="secondary",
            type="submit",
            :loading="loading",
            :disabled="$v.$invalid"
          ) Sign Up Member
          .caption.my-2 {{ biz.name }} use Selflane to power both online and in-store sales. Membership program is offered for customers to collect points and exchange for coupons and merchadises. You can collect points from purchases made either online or in-store.
          .caption By entering birthday and anniverary date, we can send out special promotions for your celebrations.
  MemberActivities
  MemberPointShop
</template>

<script>
import { mapGetters } from "vuex";
import MemberCard from "@/components/Member/Accounts/Card.vue";
import MemberActivities from "@/components/Member/Activities";
import MemberPointShop from "@/components/Member/PointShop";
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";

export default {
  components: { MemberCard, MemberActivities, MemberPointShop },
  mixins: [validationMixin],
  validations: {
    firstName: { required },
    lastName: { required },
    phone: { required, minLength: minLength(10) },
    email: { required, email },
  },
  data() {
    return {
      settingup: false,
      member: null,
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      birthday: { mm: 0, dd: 0 },
      anniv: { mm: 0, dd: 0 },
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.required && errors.push("First Name is required");
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.required && errors.push("Last Name is required");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      !this.$v.phone.minLength &&
        errors.push("Phone should be 10 digits US number");
      !this.$v.phone.required && errors.push("Phone is required");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
  },
  mounted() {
    this.setup();
  },
  methods: {
    toMenu() {
      if (this.biz) {
        this.$router.push("/bizs/" + this.biz.url);
        this.$vuetify.goTo("#bizMainCon");
      }
    },
    async setup() {
      if (!this.$auth.check()) return;
      this.settingup = true;
      const user = this.$auth.user();
      if (!user) return;
      if (!this.biz) return;

      const params = { criteria: { user: user._id, biz: this.biz._id } };
      try {
        this.member = await this.$api.member.retrieve(params);
      } catch (e) {
        //
      }
      this.settingup = false;
      this.email = user.email;
      if (user.phones && user.phones.length) {
        this.phone = user.phones[0].number;
      }
      if (user.name) {
        this.firstName = user.name.first;
        this.lastName = user.name.last;
      }
    },
    async submit() {
      if (!this.biz) return;
      this.loading = true;
      const params = {
        bizId: this.biz._id,
        phone: this.phone,
        email: this.email,
        name: { first: this.firstName, last: this.lastName },
        bd: this.birthday,
        anniv: this.anniv,
      };
      try {
        this.member = await this.$api.member.create(params);
        this.$toast.success("Success");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
