const state = {
  stripeAccount: null
}

const getters = {
  stripeAccount: state => state.stripeAccount,
}

const actions = {
  setStripeAccount: ({ commit }, data) => { commit('setStripeAccount', data) }
}

const mutations = {
  setStripeAccount(state, data) {
    state.stripeAccount = data
  }
}

export default { state, getters, actions, mutations }