/**
 * Truncate a string to a given length.
 */

function truncate(str, length = 15, ending = '') {
  if (!str || typeof str !== 'string') return '';
  if (str.length <= length) return str;
  return str.substring(0, length) + ending;
}

module.exports = truncate;