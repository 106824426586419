
const state = {
  conversation: null
}

const getters = {
  conversation: state => state.conversation
}

const actions = {
  setConversation: ({ commit }, data) => { commit('setConversation', data) },
}

const mutations = {
  setConversation(state, data) {
    state.conversation = data
  },
}

export default { state, getters, actions, mutations }
