<template lang="pug">
span(:class="color") {{ result | dayschedule }}
</template>

<script>
import moment from "moment-timezone";
import BizUtils from "/libs/utils/biz.js";

export default {
  props: {
    schedule: { type: Object, required: true },
    time: { type: Object, default: () => moment() },
  },
  computed: {
    result() {
      if (this.schedule?.temporary_close?.status) {
        return { status: false, schedule: [] };
      }
      return BizUtils.getDaySchedule(this.schedule, this.time);
    },
    color() {
      return this.result?.status == true ? "" : "error--text";
    },
  },
};
</script>
