<template lang="pug">
v-btn(color="primary", text, small, @click="show") Details
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  props: ["order"],
  methods: {
    show() {
      if (!this.order) return;
      EventBus.$emit("show-order-detail", this.order._id);
    },
  },
};
</script>