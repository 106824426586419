<template lang="pug">
.company-body
  .links-body.navy
    v-container
      .selflane-title SELFLANE
      .press-title {{ title }}
      .text-center
        v-btn(large, @click="pressEmail", color="primary") {{ email }}
  v-container
    .page-title.my-10 Latest News
    Items.mb-10
</template>

<script>
import Items from "/libs/components/Company/Press/Items";

export default {
  components: { Items },
  metaInfo() {
    const title = "Press & Media";
    const description = "Selflane News, Press Releases, and Media Resources.";
    return { title, meta: [{ name: "description", content: description }] };
  },
  data() {
    return {
      title: "Press & Media",
      email: "press@selflane.com",
    };
  },
  methods: {
    pressEmail() {
      window.location.href = `mailto:${this.email}?subject=News%20for%20Selflane`;
    },
  },
};
</script>

<style lang="sass" scoped>
.links-body
  color: white
  padding-top: 80px
  padding-bottom: 80px
  text-align: center

.selflane-title
  font-size: 20px
  font-weight: 600
  letter-spacing: 2px

.press-title
  font-size: 46px
  font-weight: 800
  line-height: 150px

.page-title
  font-size: 38px
  font-weight: 500
  text-align: center
</style>
