const state = {
  pricings: []
}

const getters = {
  pricings: state => state.pricings
}

const actions = {
  setPricings: ({ commit }, data) => { commit('setPricings', data) },
  updatePricing: ({ commit }, data) => { commit('updatePricing', data) },
  addPricing: ({ commit }, data) => { commit('addPricing', data) },
  removePricing: ({ commit }, data) => { commit('removePricing', data) }
}

const mutations = {
  setPricings(state, pricings) {
    state.pricings = pricings
  },
  updatePricing(state, pricing) {
    state.pricings = state.pricings.map(o => {
      return (o._id === pricing._id) ? pricing : o
    })
  },
  addPricing(state, pricing) {
    state.pricings.push(pricing)
  },
  removePricing(state, pricing) {
    state.pricings = state.pricings.filter(o => o._id !== pricing._id)
  }
}

export default { state, getters, actions, mutations }