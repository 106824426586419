/**
 * Convert a month to an array of dates
 * @param {String} month - YYYY-MM
 * @returns {Array} An array of dates [YYYY-MM-DD]
 */

export default handler

function handler(month) {
  const [year, monthNum] = month.split('-')
  const d = new Date(year, monthNum - 1)
  const dates = []
  while (d.getMonth() === monthNum - 1) {
    dates.push(d.toISOString().slice(0, 10))
    d.setDate(d.getDate() + 1)
  }
  return dates
}