<template lang="pug">
v-card(v-if="weekly_specials && weekly_specials.length", shaped, flat)
  v-toolbar(dense, flat, color="navy", dark)
    .sl-title Weekly Special
  v-list(two-line)
    v-list-item(v-for="(item, index) in weekly_specials", :key="index")
      v-list-item-content
        v-list-item-title {{ item.days | day(true) }}
        v-list-item-subtitle {{ item.name }}
        v-list-item-subtitle {{ item.description }}
      v-list-item-action.text-right.green--text.title {{ item.pct }}% off
  v-card-text.text-center Qualified discount will be applied automatically
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["weekly_specials"]),
  },
};
</script>
