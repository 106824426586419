<template lang="pug">
div
  v-btn(
    @click.stop="claim",
    v-if="status == 'unclaimed'",
    small,
    depressed,
    rounded,
    color="secondary",
    :loading="loading"
  )
    span.text-notransform Claim Me
  v-chip(dark, small, color="success", v-else-if="status == 'claimed'") Claimed
  v-chip(dark, small, color="success", v-else-if="status == 'applied'") Applied
</template>

<script>
export default {
  props: ["promotion", "promoclaim"],
  data() {
    return { loading: false };
  },
  computed: {
    status() {
      if (!this.promoclaim) return "unclaimed";
      if (this.promoclaim.promotion == this.promotion?._id) {
        if (this.promoclaim.applied > 0) return "applied";
        return "claimed";
      }
      return "unclaimed";
    },
  },
  methods: {
    // claim a promotion
    // require user to be logged in
    async claim() {
      const user = this.$auth.user();
      if (!this.$auth.check()) {
        this.$toast.info("Please login to claim this promotion");
        this.$router.push("/login");
        return;
      }
      const params = { promoId: this.promotion._id, userId: user._id };
      this.loading = true;
      try {
        const { data } = await this.axios.post("/promotion/claim", params);
        this.$toast.success("Promotion claimed");
        this.$emit("claim", data);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>