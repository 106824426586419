<template lang='pug'>
.item(v-if="amount", :class="style")
  .item-name {{ title }}
  span {{ amount | currency }}
</template>

<script>
export default {
  props: ["title", "amount"],
  computed: {
    style() {
      if (this.amount < 0) return "green--text";
      if (this.title == "Total") return "font-weight-bold black--text";
      return "";
    },
  },
};
</script>

<style lang="sass" scoped>
.item
  display: flex
  align-items: center
  font-size: 13px

  &-name
    flex-grow: 1
</style>