/// list businesses in a row

<template lang="pug">
v-slide-group.mt-2(:show-arrows="show_arrow")
  v-slide-item(v-for="biz in bizs", :key="biz._id")
    Cell(:biz="biz")
</template>

<script>
import Cell from "./Cell/row";

export default {
  components: { Cell },
  props: ["bizs", "loading"],
  data() {
    return {
      show_arrow: true,
    };
  },
};
</script>
