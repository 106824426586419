<template lang="pug">
v-card(flat, height="100%")
  v-toolbar(flat, dense)
    .subtitle-2 {{ item.channel }}
    v-spacer
    IconBtn(@click="edit()", v-if="isInternal")
  v-img(:src="item.picture", height="240px", width="100%", cover)
  v-card-text.body-1
    .font-weight-medium {{ item.title }}
    .my-2 {{ item.date | date({ dynamic: true }) }}
    .my-2 {{ item.content }}
    a(:href="item.external_link", target="_blank", v-if="item.external_link") Read More
</template>

<script>
import { EventBus } from "@/event-bus.js";
export default {
  props: ["item"],
  data() {
    return { app_name: process.env.VUE_APP_NAME || "Selflane" };
  },
  computed: {
    isInternal() {
      return this.app_name == "Selflane Internal";
    },
  },
  methods: {
    edit() {
      EventBus.$emit("edit-press-report", this.item);
    },
  },
};
</script>

<style lang="sass" scoped>
a
  text-decoration: none
  font-weight: 500
</style>
