const state = {
  orders: []
}

const getters = {
  orders: state => state.orders
}

const actions = {
  setOrders: ({ commit }, data) => { commit('setOrders', data) },
  updateOrder: ({ commit }, data) => { commit('updateOrder', data) }
}

const mutations = {
  setOrders(state, data) {
    state.orders = data
  },
  updateOrder(state, data) {
    state.orders = state.orders.map(o => {
      return (o._id === data._id) ? data : o
    })
  }
}

export default { state, getters, actions, mutations }
