<template lang="pug">
#map(ref="map")
</template>

<script>
import { mapGetters } from "vuex";
import BizUtils from "/libs/utils/biz.js";

export default {
  data() {
    return { customerMarker: null };
  },
  computed: {
    ...mapGetters(["biz", "cartOrder", "cartGeoService", "cartMaxRange"]),
    bizGeo() {
      return this.biz?.address?.geometry;
    },
    customerGeo() {
      return this.cartOrder?.customer?.address?.geometry;
    },
    customerGeoStr() {
      if (this.customerGeo)
        return `${this.customerGeo.lat},${this.customerGeo.lng},${this.cartOrder.type}`;
      return "";
    },
    geoDistance() {
      if (!this.biz || this.cartOrder.type !== "delivery") return 0;
      if (
        this.cartOrder.customer.address &&
        this.cartOrder.customer.address.geometry
      ) {
        const result = BizUtils.geoDistance(
          this.biz.address.geometry,
          this.cartOrder.customer.address.geometry
        );
        return result;
      }
      return 10;
    },
    isCanada() {
      return this.biz?.address?.country == "CA";
    },
    label() {
      if (this.isCanada) return "Max Radius (km)";
      return "Max Radius (miles)";
    },
    distance() {
      if (!this.cartMaxRange) return 0;
      if (this.isCanada) return this.cartMaxRange * 1000;
      return this.cartMaxRange * 1609.34;
    },
    polygonPath() {
      if (this.biz && this.biz.zone && this.biz.zone.polygon)
        return this.biz.zone.polygon;
      return [];
    },
    zoneStatus() {
      return (
        this.biz &&
        !this.biz.deliv &&
        this.biz.orderType.delivery.status &&
        this.biz.zone.status
      );
    },
  },
  watch: {
    customerGeoStr(val, oVal) {
      if (val != oVal) this.drawCustomer();
    },
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(this.$refs.map, {
        maxZoom: 16,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        styles: [
          { featureType: "poi.business", stylers: [{ visibility: "off" }] },
        ],
      });
      // draw biz with a biz-building icon
      const icon = "https://maps.google.com/mapfiles/kml/pal2/icon2.png";
      // eslint-disable-next-line no-undef
      new google.maps.Marker({
        position: this.bizGeo,
        map: this.map,
        icon,
        clickable: false,
      });
      this.drawCustomer();
    },
    drawCustomer() {
      // reset
      if (this.customerMarker) this.customerMarker.setMap(null);
      if (!this.customerGeo || this.cartOrder.type !== "delivery") {
        this.map.setCenter(this.bizGeo);
        this.map.setZoom(14);
        return;
      }
      const iconURL = "https://maps.google.com/mapfiles/ms/micons/blue-dot.png";
      // eslint-disable-next-line no-undef
      this.customerMarker = new google.maps.Marker({
        position: this.customerGeo,
        icon: { url: iconURL },
        clickable: false,
      });
      if (this.customerMarker) this.customerMarker.setMap(this.map);
      // eslint-disable-next-line no-undef
      let bounds = new google.maps.LatLngBounds();
      bounds.extend(this.bizGeo);
      bounds.extend(this.customerGeo);
      this.map.fitBounds(bounds);
      this.drawPolygon();
    },
    drawPolygon() {
      if (this.polygon) this.polygon.setMap(null);
      if (this.circle) this.circle.setMap(null);
      if (this.zoneStatus) {
        // eslint-disable-next-line no-undef
        this.polygon = new google.maps.Polygon({
          path: this.polygonPath,
          strokeColor: "#0345fc",
          strokeOpacity: 1.0,
          strokeWeight: 2,
          fillColor: "#bccbf5",
          fillOpacity: 0.35,
          map: this.map,
        });
        return;
      }
      if (!this.distance) return;
      // eslint-disable-next-line no-undef
      this.circle = new google.maps.Circle({
        strokeColor: "#0345fc",
        strokeOpacity: 1.0,
        strokeWeight: 2,
        fillColor: "#bccbf5",
        fillOpacity: 0.35,
        map: this.map,
        center: this.bizGeo,
        radius: this.distance,
      });
    },
  },
};
</script>


<style lang="sass" scoped>
#map
  height: 250px
  width: 100%
  background: gray
</style>
