<template lang="pug">
table.my-3(v-if="eventCart")
  AmountTableRow(name="Subtotal", :amount="eventCart.payment.subtotal")
  AmountTableRow(name="Tax", :amount="eventCart.payment.tax", mandatory)
  AmountTableRow(name="Total", :amount="eventCart.payment.total", bold)
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["eventCart"]),
  },
};
</script>

<style lang="sass" scoped>
table
  width: 100%
  font-size: 14px
</style>