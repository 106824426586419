<template lang="pug">
div
  .sl-title.my-5 Name
  v-card(flat)
    v-list.py-0(dense)
      Fullname
      Preferred
</template>

<script>
import Fullname from "./Fullname";
import Preferred from "./Preferred";

export default {
  components: { Fullname, Preferred },
};
</script>
