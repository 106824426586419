<template lang="pug">
.pos-notice
  v-dialog(v-model="dialog", width="500")
    template(v-slot:activator="{ on }")
      v-btn.text-capitalize(v-on="on", depressed, color="#E4E6EB", x-small) We Use Selflane POS
    v-card
      v-toolbar(flat, dense)
        .subtitle-2 Selflane POS Store
      v-card-text
        div {{ context }}
        v-row(v-if="hiddenMenu || hiddenCourse", dense, justify="center")
          v-col.text-center(cols="6", v-if="hiddenMenu")
            .subtitle-2 In-store Only Menus
            div {{ hiddenMenu }}
          v-col.text-center(cols="6", v-if="hiddenCourse")
            .subtitle-2 In-store Only Courses
            div {{ hiddenCourse }}
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      context:
        "You are looking at the same menus as we use in the store. Your online orders print to our kitchen right away. Selflane is commission-free, unlike other services that take profit from restaurants.",
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    hiddenMenu() {
      return this.biz.menus
        ?.filter((o) => o.controlled)
        ?.map((o) => o.name)
        ?.join(", ");
    },
    hiddenCourse() {
      return this.biz.courses
        ?.filter((o) => o.controlled)
        ?.map((o) => o.name)
        ?.join(", ");
    },
  },
};
</script>

<style lang="sass" scoped>
.pos-notice
  position: absolute
  right: 16px
  top: 16px
</style>
