<template lang="pug">
v-menu.left-btn(offset-y)
  template(v-slot:activator="{ on }")
    v-btn(icon, v-on="on")
      v-icon(small) mdi-format-list-bulleted
  v-list.py-0(dense, max-height="300")
    .white(v-for="group in menus", :key="group.menu._id")
      v-subheader {{ group.menu.name }}
      v-list-item(
        v-for="cItem in group.courses",
        :key="cItem.course._id",
        @click="handleClick(cItem)"
      )
        v-list-item-content
          v-list-item-title {{ cItem.course.name }}
</template>

<script>
export default {
  props: ["menus"],
  methods: {
    handleClick(item) {
      this.$emit("select", item);
    },
  },
};
</script>

<style lang="sass" scoped>
.left-btn
  flex: 0 0 30px
  overflow: hidden
</style>