<template lang="pug">
div
  CurrentOrders(:orders="today_orders")
  PastOrders(:orders="past_orders")
  EmptyView(v-if="isEmpty", text="No Orders to Show")
</template>

<script>
import { mapGetters } from "vuex";
import CurrentOrders from "./CurrentOrders";
import PastOrders from "./PastOrders";
import moment from "moment-timezone";

export default {
  components: { CurrentOrders, PastOrders },
  props: ["loading"],
  computed: {
    ...mapGetters(["orders"]),
    isEmpty() {
      return this.orders.length == 0 && !this.loading;
    },
    cutoff() {
      return moment().startOf("day").valueOf();
    },
    today_orders() {
      return this.orders?.filter((o) => o.needed > this.cutoff);
    },
    past_orders() {
      return this.orders?.filter((o) => o.needed <= this.cutoff);
    },
  },
};
</script>
