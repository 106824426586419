<template lang="pug">
.chain-biz-list
  div(v-for="(group_state, index) in groups", :key="index + 'state'")
    .title {{ group_state.state }}
    div(
      v-for="(group_city, cindex) in group_state.cities",
      :key="cindex + 'city'"
    )
      .subtitle-2.my-3 {{ group_city.city }}
      v-list.py-0(dense)
        div(v-for="(item, bindex) in group_city.items", :key="item._id")
          v-divider(v-if="bindex != 0")
          Cell(:item="item", :distance="item.distance", @hover="handleHover")
</template>

<script>
import Cell from "./Cell";

export default {
  components: { Cell },
  props: ["groups"],
  methods: {
    handleHover(item) {
      this.$emit("hover", item);
    },
  },
};
</script>

<style lang="sass" scoped>
.chain-biz-list
  height: 600px
  overflow: scroll
</style>
