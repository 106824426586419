<template lang="pug">
div(v-if="visible")
  .d-flex.flex-row.align-center
    #tip-field
      .body-2.mb-2 Add Tip to {{ tipTarget }}
      v-text-field(
        color="secondary",
        step="0.01",
        min="0",
        v-model.number="cartOrder.payment.tip",
        hide-details,
        dense,
        prefix="$",
        @change="setTip(cartOrder.payment.tip)"
      )
    v-row(dense)
      v-col(cols="4", v-for="(item, index) in tip_items", :key="index")
        v-card.pa-2.text-center(@click="setTip(item.value)", outlined)
          .subtitle-2 {{ item.pct }}%
          .subtitle-2 {{ item.value | currency }}
  v-alert.my-3(v-if="hasExcessiveTip", color="warning", dark) You entered an excessive amount of tip. Please correct it if it is not intended.
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters(["cartOrder", "biz"]),
    tip() {
      return this.cartOrder.payment.tip || 0;
    },
    tippable() {
      return this.cartOrder.payment.dish || 0;
    },
    visible() {
      if (this.biz?.settings?.tip_online == false) return false;
      if (this.tip > 0) return true;
      return this.cartOrder.dishes.length > 0;
    },
    tipTarget() {
      if (this.cartOrder.type == "delivery") return "Driver";
      return "Staff";
    },
    tip_items() {
      return [10, 15, 20].map((pct) => {
        return { pct, value: Math.round(this.tippable * pct) / 100 };
      });
    },
    hasExcessiveTip() {
      return this.tip > 10 && this.tip > this.tippable * 0.4;
    },
  },
  methods: { ...mapActions(["setTip"]) },
};
</script>

<style lang="sass" scoped>
#tip-field
  width: 130px
  padding-right: 20px
</style>
