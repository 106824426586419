<template lang="pug">
IconBtn(
  :color="color",
  :title="title",
  icon="mdi-thumb-up",
  @click="toggle(review)",
  :outlined="false",
  :text="true",
  :loading="loading"
)
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    review: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { loading: false };
  },
  computed: {
    isLike() {
      const userId = this.$auth.user()?._id;
      return userId && this.review.liked?.includes(userId);
    },
    color() {
      if (this.isLike) return "red darken-2";
      return "grey";
    },
    title() {
      return `${this.review.liked?.length}`;
    },
  },
  methods: {
    ...mapActions(["updateReview"]),
    async toggle(review) {
      if (!this.$auth.user()) {
        this.$toast.error("Please login to like this review");
        return;
      }
      const userId = this.$auth.user()._id;
      this.loading = true;
      let action;
      if (this.isLike) {
        action = { $pull: { liked: userId } };
      } else {
        action = { $addToSet: { liked: userId } };
      }
      try {
        const result = await this.$api.review.put(review._id, action);
        this.updateReview(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
