<template lang="pug">
div
  Cell(v-for="(item, index) in event.items", :key="index", :item="item")
</template>

<script>
import Cell from "./Cell.vue";
import { mapGetters } from "vuex";

export default {
  components: { Cell },
  computed: {
    ...mapGetters(["event"]),
  },
};
</script>