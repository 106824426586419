<template lang="pug">
.section-container.pb-3
  TitleBlock.mb-5
  InfoBlock(:event="event")
</template>

<script>
import { mapGetters } from "vuex";
import TitleBlock from "/libs/components/Event/TitleBlock";
import InfoBlock from "/libs/components/Event/InfoBlock";

export default {
  components: { TitleBlock, InfoBlock },
  computed: { ...mapGetters(["biz", "event"]) },
};
</script>

<style lang="sass" scoped>
.section-container
  border-bottom: 1px solid #DBDAE3
</style>