<template lang="pug">
div
  .my-3.white(v-for="(group, index) in groups", :key="index")
    v-container
      .sl-title {{ [group.cate] | cates }}
      BizList(:bizs="group.bizs")
</template>

<script>
import { mapGetters } from "vuex";
import BizList from "@/components/Bizs/ListRow";
import _ from "underscore";

export default {
  components: { BizList },
  computed: {
    ...mapGetters(["bizAccounts"]),
    groups() {
      const result = _.chain(this.bizAccounts)
        .pluck("cates")
        .flatten()
        .countBy()
        .map((o, k) => {
          return {
            cate: k,
            count: o,
          };
        })
        .sortBy((o) => {
          return -o.count;
        })
        .reject((o) => o.count < 6)
        .map((o) => {
          const bizs = _.filter(
            this.bizAccounts,
            (biz) => biz.cates.indexOf(o.cate) != -1
          );
          return {
            cate: o.cate,
            bizs: bizs,
          };
        })
        .value();
      return result;
    },
  },
};
</script>
