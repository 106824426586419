<template lang="pug">
v-container
  Page(title="My Reservations")
    template(v-slot:header)
      YearSelector(@select="setYear")
    List
</template>

<script>
import List from "@/components/Reservation/List";
import { mapActions } from "vuex";
import moment from "moment-timezone";

export default {
  components: { List },
  data() {
    return {
      year: null,
    };
  },
  mounted() {
    this.setReservations([]);
  },
  methods: {
    ...mapActions(["setReservations"]),
    setYear(year) {
      if (year) this.year = year;
      this.load();
    },
    async load() {
      if (!this.$auth.user()) return null;
      if (!this.year) return;
      const id = this.$auth.user()._id;
      const begin = moment().year(this.year).startOf("year").valueOf();
      const end = moment().year(this.year).endOf("year").valueOf();
      const params = {
        criteria: {
          "customer.id": id,
          needed: { $gte: begin, $lt: end },
        },
        select: "seller created needed status tableId total_people",
        limit: 50,
      };
      try {
        const result = await this.$api.reservation.list(params);
        this.setReservations(result);
      } catch (e) {
        //
      }
    },
  },
};
</script>
