<template lang="pug">
.d-flex.my-3
  v-icon(v-if="modifier.required", title="required", small) mdi-checkbox-blank-circle
  v-icon(v-else, title="optional", small) mdi-checkbox-blank-circle-outline
  .subtitle-2.mx-2.text-truncate {{ modifier.name }}
  v-spacer
  .body-2 {{ modifier.required ? "Select " : "Optional " }} {{ maxSelection }}
</template>

<script>
export default {
  name: "DishModifierTitle",
  props: ["modifier"],
  computed: {
    maxSelection() {
      if (this.modifier.maxSelection >= 50) {
        return "any";
      } else if (this.modifier.maxSelection == 1) {
        return "1";
      }
      return `up to ${this.modifier.maxSelection}`;
    },
  },
};
</script>