<template lang="pug">
.d-flex.flex-row
  .subtitle-2.success--text(v-for="(item, index) in items", :key="index") {{ item.value | currency }} {{ item.currency }}
</template>

<script>
export default {
  props: ["gfbalance"],
  computed: {
    items() {
      if (!this.gfbalance) return [];
      let items = [];
      if (this.gfbalance.balance_usd && this.gfbalance.balance_usd > 0) {
        items.push({
          currency: "USD",
          value: this.gfbalance.balance_usd / 100,
        });
      }
      if (this.gfbalance.balance_cad && this.gfbalance.balance_cad > 0) {
        items.push({
          currency: "CAD",
          value: this.gfbalance.balance_cad / 100,
        });
      }
      return items;
    },
  },
};
</script>