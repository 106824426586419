<template lang="pug">
div
  div
    .d-flex.flex-row.align-center(@click="toggle", role="button")
      v-icon.mr-1(small, :color="color") {{ icon }}
      .course-name {{ name }}
    .course-description(v-if="visible && description") {{ description }}
  v-row(wrap, dense, v-if="visible")
    v-col(
      cols="12",
      sm="6",
      v-for="(dish, index) in data.dishes",
      :key="index"
    )
      Cell(:dish="dish", @select="select(dish, $event)", :color="color")
</template>

<script>
import _ from "underscore";
import { EventBus } from "@/event-bus.js";
import Cell from "./Cell";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { Cell },
  props: ["data", "modifiers"],
  data() {
    return {
      visible: true,
      colors: ["#8ACDEA", "#26408B", "#8C4843"],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    name() {
      const text = this.data?.course?.name;
      if (this.data?.course?.classification == 3) return text + " (Alcohol)";
      return text;
    },
    description() {
      return this.data?.course?.description;
    },
    icon() {
      if (this.visible) return "mdi-minus";
      return "mdi-plus";
    },
    color() {
      const n = this.data.n || 0;
      const index = n % this.colors.length;
      return this.colors[index];
    },
  },
  methods: {
    ...mapActions(["addDishToCart"]),
    toggle() {
      this.visible = !this.visible;
    },
    select(dish, directToCart) {
      dish.modifiers = [];
      _.chain(dish.modifierIDs)
        .uniq()
        .each((id) => {
          const found = this.modifiers.find((o) => o._id === id);
          if (found?.choices?.length) dish.modifiers.push(found);
        });
      const clone = JSON.parse(JSON.stringify(dish));
      clone.quantity = 1;
      this.$options.filters.unitPrice(clone);
      if (directToCart) {
        if (!clone.modifiers?.length) {
          this.addDishToCart({ biz: this.biz, dish: clone });
          this.$toast.open({
            message: "Added to cart",
            duration: 1000,
            position: "bottom",
          });
          return;
        }
      }
      EventBus.$emit("show-dish", clone);
    },
  },
};
</script>

<style lang="sass" scoped>
.course-name
  font-weight: 600
  font-size: 16px
  margin-top: 12px
  margin-bottom: 12px
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis

.course-description
  font-size: 14px
  color: rgba(28, 31, 52, 0.6)
  max-lines: 3
  margin-bottom: 12px
</style>