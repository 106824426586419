<template lang="pug">
div(v-if="chain")
  v-img(
    :src="parallax",
    width="100%",
    max-height="500",
    cover,
    v-if="parallax"
  )
  v-container(fluid)
    v-row.my-3(dense, wrap)
      v-col(cols="12", sm="3")
        Logo(:logo="chain.logo")
      v-col(cols="12", sm="9")
        h1.title {{ chain.name }}
        .mt-3 {{ chain.shortd }}
    v-row(dense, wrap)
      v-col(cols="12", sm="4", md="3")
        List(:groups="groups", @hover="hoverItem")
      v-col.hidden-xs-only(cols="12", sm="8", md="9")
        Map(:items="bizs", :selected="selected")
</template>

<script>
import _ from "underscore";
import BizUtils from "/libs/utils/biz.js";
import Logo from "./Logo";
import List from "./List";
import Map from "./Map";
import { mapActions } from "vuex";

export default {
  components: { Logo, List, Map },
  props: ["url"],
  metaInfo() {
    if (this.chain) {
      const title = this.chain.name + " | Selflane";
      const description = this.chain.shortd;
      return {
        title,
        meta: [{ name: "description", content: description }],
      };
    }
    return {};
  },
  data() {
    return {
      mediaHost: process.env.VUE_APP_MEDIAHOST,
      chain: null,
      bizs: [],
      loading: false,
      selected: null,
    };
  },
  computed: {
    title() {
      if (this.chain) return this.chain.name + " | Selflane";
      return "Selflane";
    },
    // Group business by state and city
    // Sort by distance
    groups() {
      return _.chain(this.bizs)
        .sortBy((o) => o.distance)
        .groupBy((o) => o.address.state)
        .map((o, state) => {
          const cities = _.chain(o)
            .groupBy((item) => item.address.city)
            .map((items, city) => {
              return { city, items };
            })
            .value();
          return { state, cities };
        })
        .value();
    },
    parallax() {
      if (this.chain?.parallax) return this.mediaHost + this.chain.parallax;
      return null;
    },
  },
  watch: {
    url(val, oldVal) {
      if (val && val != oldVal) this.load();
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setUserLocation"]),
    hoverItem(item) {
      this.selected = item;
    },
    async load() {
      this.chain = null;
      this.loading = true;
      try {
        const params = { criteria: { url: this.url } };
        this.chain = await this.$api.chain.account.retrieve(params);
        await this.loadBizs();
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async loadBizs() {
      if (!this.chain) return;
      const options = {
        criteria: {
          "status.byAdmin": 1,
          "status.byUser": 1,
          _id: { $in: this.chain.bizs },
        },
        select: "name short_name url address industry",
      };
      this.bizs = await this.$api.biz.list(options);
      this.selectFirst();
      this.calcDistance();
    },
    calcDistance() {
      navigator.geolocation.getCurrentPosition((position) => {
        const location = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.setUserLocation(location);
        this.bizs = _.chain(this.bizs)
          .map((item) => {
            const distance = BizUtils.geoDistanceInMeter(
              item.address.geometry,
              location
            );
            item.distance = distance;
            return item;
          })
          .sortBy("distance")
          .value();
        this.selectFirst();
      });
    },
    // Select the first biz in the list
    selectFirst() {
      if (this.bizs?.length) {
        this.selected = this.bizs[0];
      }
    },
  },
};
</script>
