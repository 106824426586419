<template lang="pug">
div(v-if="cartOrder")
  #cart-time(@click="open")
    .d-flex.flex-row.align-center
      .time-slot(:class="{ 'red--text': !validity.status }") {{ cartOrder.needed | datetime("lll", timezone) }}
      .time-slot.blue--text.text--darken-3(v-if="isFuture") Future
      v-spacer
      v-icon mdi-chevron-right
    v-alert(type="error", v-if="!validity.status") {{ validity.error }}
  TimeSelector(ref="timeSelector", @save="save")
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment-timezone";
import TimeSelector from "./TimeSelector/index";
import BizUtils from "/libs/utils/biz.js";

export default {
  components: { TimeSelector },
  computed: {
    ...mapGetters(["cartOrder", "cartGeoService", "biz", "timezone"]),
    isFuture() {
      const needed = this.cartOrder?.needed;
      if (!needed) return false;
      const end = moment().tz(this.timezone).endOf("day").valueOf();
      return needed > end;
    },
    validity() {
      const result = BizUtils.validateTypeTime(
        this.cartOrder.type,
        this.cartOrder.needed,
        this.biz,
        this.cartGeoService
      );
      return result;
    },
  },
  methods: {
    ...mapActions(["setCartNeeded"]),
    open() {
      this.$refs.timeSelector.open(this.cartOrder.needed);
    },
    save(value) {
      this.setCartNeeded(value);
    },
  },
};
</script>

<style lang="sass" scoped>
#cart-time
  cursor: pointer

.time-slot
  font-size: 17px
  font-weight: 500
  line-height: 52px
  margin-right: 12px
  white-space: nowrap
</style>
