<template lang="pug">
div
  v-navigation-drawer(
    v-model="drawer",
    app,
    temporary,
    v-if="$auth.check()",
    width="250"
  )
    v-toolbar(dense, flat, dark, color="primary")
      v-icon $selflane
      .selflane-title Selflane
    v-list.py-0(dense, expand)
      v-list-item(v-for="item in items", :key="item.title", :to="item.to")
        v-list-item-action
          v-icon {{ item.icon }}
        v-list-item-content
          v-list-item-title {{ item.title }}
      v-list-item(@click="logout")
        v-list-item-content
          v-list-item-title Sign Out
          v-list-item-subtitle {{ $auth.user().email }}
        v-list-item-action
          v-icon mdi-logout
  TopBar(@toggleDrawer="drawer = !drawer")
  SearchBiz
</template>

<script>
import TopBar from "./TopBar/index";
import SearchBiz from "./SearchBiz";

export default {
  components: { TopBar, SearchBiz },
  data() {
    return {
      drawer: null,
      items: [
        { title: "Profile", icon: "mdi-account", to: "/user/profile" },
        { title: "Favorites", icon: "mdi-heart", to: "/user/favorites" },
        {
          title: "Orders",
          icon: "mdi-silverware-fork-knife",
          to: "/user/orders",
        },
        {
          title: "Reservations",
          icon: "mdi-table-chair",
          to: "/user/reservations",
        },
        { title: "Reviews", icon: "mdi-comment", to: "/user/reviews" },
        { title: "Wallet", icon: "mdi-wallet", to: "/user/wallet" },
        { title: "Work", icon: "mdi-calendar", to: "/user/work" },
      ],
    };
  },
  methods: {
    logout() {
      this.$auth.logout();
    },
  },
};
</script>

<style lang="sass" scoped>
.selflane-title
  font-weight: 600
  font-size: 18px
  color: white
  margin-left: 10px
</style>
