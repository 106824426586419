<template lang="pug">
v-container
  Detail(:order="order")
</template>

<script>
import Detail from "@/components/Order/Detail/index";

export default {
  components: { Detail },
  props: ["id"],
  data() {
    return {
      order: null,
      loading: false,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      if (!this.id) return;
      this.loading = true;
      const params = { criteria: { _id: this.id } };
      try {
        this.order = await this.$api.order.retrieve(params);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>