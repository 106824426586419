<template lang="pug">
.dish-prints
  span(v-for="(item, index) in items", :key="index")
    v-icon(x-small) {{ item.icon }}
    span.caption.secondary--text(v-if="item.note") {{ item.note }}
  v-icon(x-small, v-if="items.length == 0") mdi-printer-off
  span(style="font-weight: 600; font-size: 12px") {{ group }}
</template>

<script>
export default {
  props: {
    dish: { type: Object, required: true },
    biz: { type: Object, default: null },
  },
  data() {
    return {
      allItems: [
        { icon: "mdi-silverware-fork-knife", value: 0 },
        { icon: "mdi-silverware-fork-knife", value: 2, note: 2 },
        { icon: "mdi-silverware-fork-knife", value: 3, note: 3 },
        { icon: "mdi-glass-wine", value: 1 },
        { icon: "mdi-sticker", value: 4 },
      ],
    };
  },
  computed: {
    items() {
      return this.allItems.filter((o) => this.dish?.prints?.includes(o.value));
    },
    group() {
      if (!this.dish.printingGroup) return null;
      const found = this.biz?.printingGroups?.find(
        (group) => group._id == this.dish.printingGroup
      );
      return found?.name;
    },
  },
};
</script>

<style lang="sass" scoped>
.dish-prints
  display: flex
  gap: 3px
  align-items: first baseline
</style>
