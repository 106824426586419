<template lang="pug">
Label(icon="mdi-card-account-details", v-if="required")
  v-text-field(
    v-model="cartOrder.customer.access",
    :label="title",
    :placeholder="title",
    :hint="note"
  )
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["cartOrder", "biz"]),
    title() {
      return this.biz?.orderType?.limited_access?.title;
    },
    required() {
      return this.biz?.orderType?.limited_access?.status && this.title;
    },
    note() {
      return (
        this.biz.orderType.limited_access.note ||
        "This business has limited access restriction. Please make sure you are accessible to the property and service."
      );
    },
  },
};
</script>
