<template lang="pug">
v-container(v-if="promotions && promotions.length")
  .sl-title Coupon Island
  v-slide-group(:show-arrows="show_arrow", ref="coupon_island")
    v-slide-item(v-for="(item, index) in promotions", :key="index")
      CouponCell(
        :promotion="item",
        :claims="claims",
        @claimed="loadClaims",
        :bizs="bizs",
        :chains="chains"
      )
</template>

<script>
import _ from "underscore";
import CouponCell from "./CouponCell/index";
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";

export default {
  components: { CouponCell },
  data() {
    return {
      loading: false,
      promotions: [],
      claims: [],
      chains: [],
      bizs: [],
      show_arrow: true,
    };
  },
  computed: {
    ...mapGetters(["selectedCity"]),
  },
  mounted() {
    this.load();
    EventBus.$on("set-selected-city", this.load);
  },
  destroyed() {
    EventBus.$off("set-selected-city", this.load);
  },
  methods: {
    async load() {
      if (!this.selectedCity) return;
      const now = Date.now();
      const params = {
        criteria: {
          expiry: { $gt: now },
          country: this.selectedCity.country,
          states: this.selectedCity.state,
        },
      };
      const res = await this.axios.post("/promotion/list", params);
      this.loading = false;
      this.promotions = _.chain(res.data)
        .reject((o) => o.claimed >= o.total)
        .shuffle()
        .value();
      this.loadClaims();
      this.loadChains();
      this.loadBizs();
    },
    async loadChains() {
      const ids = _.pluck(this.promotions, "chain");
      this.chains = [];
      if (!ids || !ids.length) return;
      const params = { criteria: { _id: { $in: ids } }, select: "name logo" };
      const { data } = await this.axios.post("/chain/list", params);
      this.chains = data;
    },
    async loadBizs() {
      const bizIds = _.pluck(this.promotions, "biz");
      this.bizs = [];
      if (!bizIds || bizIds.length == 0) return;
      const params = {
        criteria: { _id: { $in: bizIds } },
        select: "name address logo",
      };
      const { data } = await this.axios.post("/bizs/list", params);
      this.bizs = data;
    },
    async loadClaims() {
      this.claims = [];
      const userId = this.$auth.user()?._id;
      if (!userId || !this.promotions) return;
      const ids = _.pluck(this.promotions, "_id");
      const params = {
        criteria: { promotion: { $in: ids }, user: userId },
        select: "promotion applied",
      };
      const { data } = await this.axios.post("/promoclaim/list", params);
      this.claims = data;
    },
    toLeft() {
      const width = this.$refs.promotions.clientWidth;
      this.$refs.promotions.scrollLeft -= width;
    },
    toRight() {
      const width = this.$refs.promotions.clientWidth;
      this.$refs.promotions.scrollLeft += width;
    },
  },
};
</script>
