<template lang="pug">
v-simple-table(dense)
  thead
    tr
      th Date
      th Recipient
      th.text-right Amount
      th.text-right Status
  tbody
    tr(v-for="(item, index) in transfers", :key="index")
      td {{ item.created | date }}
      td {{ item.recipient_email }}
      td.text-right {{ (item.amount / 100) | currency }}
        span.ml-1.text-uppercase {{ item.currency }}
      td.text-right
        span(v-if="item.claimed") Claimed
        span(v-else) Pending
</template>

<script>
export default {
  props: ["transfers"],
};
</script>