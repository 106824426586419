<template lang="pug">
v-dialog(v-model="dialog", width="500px", scrollable)
  v-card(v-if="dish")
    v-toolbar(dense, flat, color="background")
      .subtitle-2 {{ dish.name }}
      v-spacer
      DishTags(:dish="dish")
      DishServing.ml-1(:dish="dish")
    v-card-text(style="max-height: 500px")
      Head(:dish="dish")
      div(v-for="(item, mID) in dish.modifiers", :key="mID")
        ModifierTitle(:modifier="item")
        ChoiceBtn(
          v-for="(c, cID) in item.choices",
          :key="cID",
          @click="selectChoice(mID, cID)",
          @selectQuantity="selectQuantity($event, mID, cID)",
          :choice="c"
        )
        div(
          v-if="hasSelected(item) && item.associatedChoices && item.associatedChoices.length"
        )
          v-row(wrap, dense)
            v-col(
              cols="6",
              v-for="(sub, subID) in item.associatedChoices",
              :key="sub.name"
            )
              button.choice-btn.subchoice(
                :class="{ 'choice-selected': sub.selected, 'choice-unselected-default': !sub.selected && sub.isDefault, 'choice-selected-default': sub.selected && sub.isDefault }",
                @click="selectSubChoice(mID, subID)"
              )
                span.text-truncate {{ sub.name }}
              v-row(v-if="sub.selected")
                v-col(v-if="item.associatedChoiceAllowHalfhalf")
                  HalfHalfPicker(v-model="sub.halfhalf", @change="updatePrice")
                v-col(v-if="item.associatedChoiceAllowQuantity")
                  SizePicker(v-model="sub.quantity", @change="updatePrice")
    .body-2.pa-2.red--text(
      v-for="(error, index) in errors",
      :key="index + 'index'"
    ) {{ error }}
    v-card-actions.actions-box
      v-icon(color="success", @click="decrease()") mdi-minus-circle-outline
      DishPrice(:dish="dish", showQuantity)
      v-icon(color="success", @click="increase()") mdi-plus-circle
      v-spacer
      v-btn(dark, depressed, rounded, color="success", @click="save()") Save
        v-icon(small, right) mdi-cart-arrow-down
</template>

<script>
import _ from "underscore";
import { mapActions, mapGetters } from "vuex";
import Head from "./Head";
import ModifierTitle from "./ModifierTitle";
import ChoiceBtn from "./ChoiceBtn";
import { EventBus } from "@/event-bus.js";

export default {
  components: { Head, ModifierTitle, ChoiceBtn },
  data() {
    return {
      dialog: false,
      dish: null,
      dishId: null,
      errors: [],
    };
  },
  computed: { ...mapGetters(["biz"]) },
  mounted() {
    EventBus.$on("show-dish", this.open);
  },
  destroyed() {
    EventBus.$off("show-dish", this.open);
  },
  methods: {
    ...mapActions(["addDishToCart", "editDishAt"]),
    open(dish, dishId) {
      this.dialog = true;
      this.dish = JSON.parse(JSON.stringify(dish));
      this.dishId = dishId;
    },
    hasSelected(modifier) {
      return modifier.choices.some((o) => o.selected && o.hsc != true);
    },
    updatePrice() {
      this.$options.filters.unitPrice(this.dish);
    },
    selectChoice(mID, cID) {
      this.errors = [];
      // 1) if selected, unselect
      // 2) if not-yet-selected, unselect any overflow, then select
      if (mID > this.dish.modifiers.length - 1) return;
      const modifier = this.dish.modifiers[mID];
      if (cID > modifier.choices.length - 1) return;
      const choice = modifier.choices[cID];
      if (choice.selected) choice.selected = false;
      else {
        const selected = _.filter(modifier.choices, "selected");
        if (selected && selected.length >= modifier.maxSelection) {
          const toRemove = selected.length - modifier.maxSelection + 1;
          let removed = 0;
          modifier.choices.forEach((o) => {
            if (removed < toRemove && o.selected) {
              o.selected = false;
              removed++;
            }
          });
        }
        choice.quantity = choice.quantity || 1;
        choice.selected = true;
      }
      const result = this.validateModifier(modifier);
      if (result) {
        choice.quantity = 1;
        choice.selected = false;
        this.errors = [result];
        return;
      }
      this.errors = [];
      this.updatePrice();
    },
    selectQuantity(data, mID, cID) {
      const modifier = this.dish.modifiers[mID];
      this.dish.modifiers[mID].choices[cID].quantity = data;
      const result = this.validateModifier(modifier);
      if (result) {
        this.dish.modifiers[mID].choices[cID].quantity = 1;
        this.dish.modifiers[mID].choices[cID].selected = false;
        this.errors = [result];
        return;
      }
      this.errors = [];
      this.updatePrice();
    },
    validateModifier(modifier) {
      const quantity = modifier.choices
        .filter((o) => o.selected)
        .reduce((a, b) => a + (b.quantity || 1), 0);
      if (quantity > modifier.maxSelection) {
        return `You can only select a total of ${modifier.maxSelection} ${modifier.name}.`;
      }
      return;
    },
    selectSubChoice(mID, cID) {
      // 1) if selected, unselect
      // 2) if not-yet-selected, unselect any overflow, then select
      if (mID > this.dish.modifiers.length - 1) return;
      const modifier = this.dish.modifiers[mID];
      if (cID > modifier.associatedChoices.length - 1) return;
      const choice = modifier.associatedChoices[cID];
      if (choice.selected) choice.selected = false;
      else {
        const selected = modifier.associatedChoices.filter((o) => o.selected);
        // find max allowed
        const fsc = modifier.choices.find((o) => o.selected);
        if (!fsc) return;
        const maxSelection = fsc.associatedChoiceMax;
        if (selected && selected.length >= maxSelection) {
          const toRemove = selected.length - maxSelection + 1;
          let removed = 0;
          _.each(modifier.associatedChoices, (o) => {
            if (removed < toRemove && o.selected) {
              o.selected = false;
              removed++;
            }
          });
        }
        choice.selected = true;
      }
      this.updatePrice();
    },
    increase() {
      if (this.dish && this.dish.quantity < 100) this.dish.quantity++;
      this.updatePrice();
    },
    decrease() {
      if (this.dish && this.dish.quantity > 1) this.dish.quantity--;
      this.updatePrice();
    },
    validate() {
      this.updatePrice();
      this.errors = [];
      if (this.dish.quantity < 1) this.errors.push("Invalid quantity");
      _.each(this.dish.modifiers, (modifier) => {
        // check choices quantity
        const result = this.validateModifier(modifier);
        if (result) this.errors.push(result);

        const modifierName = modifier.name || "option";
        const choices = modifier.choices.filter((o) => o.selected);
        if (choices && choices.length > 0) {
          if (choices.length > modifier.maxSelection) {
            this.errors.push(
              modifierName + ": select up to " + modifier.maxSelection
            );
          }
          const subchoices = modifier.associatedChoices.filter(
            (o) => o.selected
          );
          if (
            subchoices &&
            subchoices.length > choices[0].associatedChoiceMax
          ) {
            this.errors.push("Too many subchoices selected");
          }
        } else if (modifier.required) {
          this.errors.push(modifierName + " is required");
        }
      });
    },
    save() {
      // validate dish and biz here
      // add to cart
      this.validate();
      if (this.errors.length) return;
      this.dialog = false;
      if (this.dishId !== undefined) {
        this.editDishAt({ dish: this.dish, index: this.dishId });
        this.$toast.open({
          message: "Cart updated",
          duration: 1000,
          position: "bottom",
        });
      } else {
        this.addDishToCart({ biz: this.biz, dish: this.dish });
        this.$toast.open({
          message: "Added to cart",
          duration: 1000,
          position: "bottom",
        });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.choice-btn
  display: inline-block
  border: 1px solid #dddddd
  border-radius: 10px
  overflow: hidden
  font-size: 13px
  font-weight: 500
  margin-right: 8px

.subchoice
  width: 100%

.choice-selected
  background-color: #FF6D32
  color: #fdf9f4
  font-weight: 500
  border: none

.choice-unselected-default
  border: 1px solid #FF6D32

.choice-selected-default
  border: none
  background-color: #37ba60
  color: #fdf9f4

button:focus
  outline: 0

input:focus
  outline: 0

.actions-box
  background-color: #F0F2F5
  display: flex
  gap: 6px
  align-items: center
</style>
