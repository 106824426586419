function orderStatus(status) {
  if (typeof (status) !== 'number') return ''
  switch (status) {
    case 0: return 'Created'
    case 1: return 'Processing'
    case 2: return 'Ready'
    case 3: return 'Fulfilled'
    case -1: return 'Canceled and Refunded'
  }
  return ''
}

export default orderStatus
