<template lang="pug">
v-select(v-model="content", :items="items", hide-details, dense, :dark="dark")
</template>

<script>
import _ from "underscore";
export default {
  name: "HalfHalfPicker",
  props: {
    value: { type: Number, required: true },
    min: { type: Number, default: 1 },
    max: { type: Number, default: 6 },
    dark: { type: Boolean, default: false },
  },
  data() {
    return {
      content: this.value,
    };
  },
  computed: {
    // map min to max with text '{number}x'
    items() {
      return _.map(_.range(this.min, this.max), (i) => ({
        value: i,
        text: i + "x",
      }));
    },
  },
  watch: {
    value() {
      this.content = this.value;
    },
    content(newval, oldval) {
      if (newval == oldval) return;
      this.$emit("input", this.content);
      this.$emit("change");
    },
  },
};
</script>