<template lang="pug">
v-container
  Page(title="My Favorites")
    BizList(:bizs="bizs", :loading="loading")
</template>

<script>
import BizList from "@/components/Bizs/List";

export default {
  components: { BizList },
  data() {
    return {
      bizs: [],
      loading: false,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      if (!this.$auth.user()) return;
      const myfavs = this.$auth.user().preference.myfavs;
      const params = {
        criteria: {
          _id: { $in: myfavs },
          "status.byAdmin": 1,
          "status.byUser": 1,
        },
        select: "name logo url address cates schedule stats",
      };
      this.loading = true;
      try {
        this.bizs = await this.$api.biz.list(params);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
