<template lang='pug'>
div(v-if="order")
  PaymentGroup(:payment="order.payment", :order="order")
  div(v-for="(item, index) in order.splitChecks", :key="index")
    PaymentGroup(
      :payment="item.payment",
      :order="order",
      :checkId="item._id",
      :checkIndex="index"
    )
  .green--text {{ order.note }}
  VoidCharge
</template>

<script>
import PaymentGroup from "./PaymentGroup";
import VoidCharge from "./VoidCharge";

export default {
  components: { PaymentGroup, VoidCharge },
  props: { order: { type: Object, required: true } },
};
</script>
