<template lang="pug">
div
  .d-flex.flex-row.align-center.justify-space-between.my-5
    .sl-title Addresses
    IconBtn(@click="edit()", title="Add", icon="mdi-plus")
  v-card(flat, v-if="addresses && addresses.length")
    Cell(
      v-for="(item, index) in addresses",
      :key="index",
      :address="item",
      @edit="edit(item)"
    )
  UserAddressForm
</template>

<script>
import Cell from "./Cell";
import { EventBus } from "@/event-bus.js";

export default {
  components: { Cell },
  computed: {
    addresses() {
      return this.$auth.user()?.addresses;
    },
  },
  methods: {
    edit(data) {
      EventBus.$emit("user.address:edit", data);
    },
  },
};
</script>
