import BrandResources from './BrandResources/index'
import MerchantAgreement from './MerchantAgreement'
import Press from './Press/External'
import Privacy from './Privacy/index'
import Company from './Selflane/index'
import Terms from './Terms/index'

export default [
  { path: '/company', component: Company },
  { path: '/merchant-agreement', component: MerchantAgreement },
  { path: '/terms', component: Terms },
  { path: '/terms-of-service', component: Terms },
  { path: '/privacy', component: Privacy },
  { path: '/brandresources', component: BrandResources },
  { path: '/press', component: Press }
]