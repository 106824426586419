<template lang="pug">
.coupon-container
  .coupon-top-bar
  .coupon
    .top
      Logo(:promotion="promotion", :bizs="bizs", :chains="chains")
      ClaimBtn(
        :promotion="promotion",
        :promoclaim="promoclaim",
        @claim="$emit('claimed')"
      )
    CouponText(:promotion="promotion")
</template>

<script>
import Logo from "@/components/Promotion/Logo";
import CouponText from "./CouponText";
import ClaimBtn from "@/components/Promotion/ClaimBtn";

export default {
  components: { Logo, CouponText, ClaimBtn },
  props: ["promotion", "claims", "bizs", "chains"],
  computed: {
    promoclaim() {
      return this.claims.find((o) => o.promotion == this.promotion._id);
    },
  },
};
</script>

<style lang="sass" scoped>
.coupon-container
  margin-left: 10px
  margin-right: 10px
  margin-bottom: 10px

.coupon-top-bar
  background: #d8d8d8
  border-radius: 4px
  margin-left: -5px
  margin-right: -5px
  height: 8px

.coupon
  width: 220px
  margin-top: -4px
  margin-bottom: -4px
  padding: 16px 16px 8px 16px
  background: #ffffff
  border-bottom-left-radius: 12px
  border-bottom-right-radius: 12px
  box-shadow: 0 8px 20px -2px rgba(18, 21, 36, 0.08)

.top
  display: flex
  justify-content: space-between
  align-items: center
</style>
