// Format date
// Input could be
// 1. Date object
// 2. PAX YYYYMMddHHmmss format
// if dynamic is true, return 'Today', 'Yesterday', 'Tomorrow', 'MMM D', or format if none of the above

import moment from 'moment-timezone'

function date(input, options = {}) {
  if (input == null) return ''
  const {
    format = 'M/D/YYYY',
    timezone = moment.tz.guess(),
    dynamic = false
  } = options
  let m
  if (typeof input === 'string' && input.match(/^[0-9]{14}$/)) {
    m = moment(input, 'YYYYMMDDHHmmss')
  } else if (typeof input == 'number' && input.toString().length === 14) {
    m = moment(input.toString(), 'YYYYMMDDHHmmss')
  } else if (typeof input === 'string' && input.match(/^[0-9a-fA-F]{24}$/)) {
    m = moment(parseInt(input.substring(0, 8), 16) * 1000).tz(timezone)
  } else {
    m = moment(input).tz(timezone)
  }
  if (m.isValid()) {
    if (dynamic) {
      const now = moment().tz(timezone || moment.tz.guess())
      if (m.isSame(now, 'day')) {
        return m.format('Today')
      } else if (m.isSame(now.clone().subtract(1, 'day'), 'day')) {
        return m.format('Yesterday')
      } else if (m.isSame(now.clone().add(1, 'day'), 'day')) {
        return m.format('Tomorrow')
      } else if (m.isSame(now, 'year')) {
        return m.format('MMM D')
      } else {
        return m.format(format)
      }
    }
    return m.format(format)
  }
  return input
}

export default date
