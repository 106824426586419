function cates(list) {
  const result = list?.map(item => {
    const num = Number(item)
    switch (num) {
      case 1:
        return 'American'
      case 2:
        return 'Chinese'
      case 3:
        return 'French'
      case 4:
        return 'Japanese'
      case 5:
        return 'Korean'
      case 6:
        return 'Greek'
      case 7:
        return 'Indian'
      case 8:
        return 'Italian'
      case 9:
        return 'Mexican'
      case 10:
        return 'Thai'
      case 11:
        return 'Vietnamese'
      case 12:
        return 'Brazilian'
      case 13:
        return 'Cajun/Creole'
      case 14:
        return 'Caribbean'
      case 15:
        return 'Cuban'
      case 16:
        return 'Greek and Mediterranean'
      case 17:
        return 'Hawaiian'
      case 18:
        return 'Brewpub'
      case 19:
        return 'Vegan'
      case 20:
        return 'Peruvian'
      case 21:
        return 'Asian Fusion'
      case 22:
        return 'Ice Cream Parlor'
      case 23:
        return 'Spanish'
      case 24:
        return 'New Mexican'
      case 25:
        return 'Canadian'
      case 26:
        return 'Coffeehouse'
      case 27:
        return 'Kosher'
      case 9999:
        return 'Other'
      default:
        return ''
    }
  })
  return result.join(', ')
}

export default cates