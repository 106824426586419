import Vue from 'vue'
import vuetify from '/libs/plugins/vuetify'
import '/libs/style/main.sass'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import store from './store'
import VueAuth from '@websanova/vue-auth'
import Croppa from 'vue-croppa'
import VueGtag from 'vue-gtag'
import * as filters from '/libs/filters/index'
import 'vue-croppa/dist/vue-croppa.css'
import VueMeta from 'vue-meta'
import VueMask from 'v-mask'
const baseURL = process.env.VUE_APP_MAIN_API
import VueSocialSharing from 'vue-social-sharing'
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import RegisterComponents from './main-components'
import '/libs/plugins/mixins'

Vue.use(VueMask);
Vue.use(VueAxios, axios)
Vue.use(ToastPlugin)
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
Vue.axios.defaults.baseURL = baseURL
Vue.axios.defaults.withCredentials = true

Vue.use(Croppa)
Vue.use(VueGtag, {
  config: {
    id: 'UA-111753270-5'
  },
  enabled: process.env.NODE_ENV === 'production',
}, router)

Vue.config.productionTip = false
Vue.router = router
Vue.use(VueAuth, {
  auth: {
    request: function (req, token) {
      this.options.http._setHeaders.call(this, req, {
        Authorization: 'Bearer ' + token
      })
    },
    response: function () {
      return 'selflane.com'
    }
  },
  http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
  router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
  loginData: {
    url: '/users/login',
    fetchUser: true
  },
  logoutData: {
    url: '/users/logout',
    method: 'get',
    redirect: '/',
    makeRequest: true
  },
  fetchData: {
    url: '/users/get',
    method: 'GET',
    enabled: true
  },
  rememberMe: true,
  refreshData: {
    enabled: false
  },
  parseUserData: (data) => {
    return data
  },
  googleOauth2Data: {
    params: {
      redirect_uri: baseURL + '/auth/google/cftoken'
    }
  }
})
Vue.use(VueSocialSharing)

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

RegisterComponents(Vue)

// a clean http instance for 3rd party (googleaip) calls
const axios2 = axios.create({
  withCredentials: false
})
window.axios2 = axios2
window.axios = Vue.axios
window.toast = Vue.$toast

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')