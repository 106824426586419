<template lang="pug">
Page(title="Brand Resources")
  div Hundreds of main street businesses use Selflane’s software to process sales online and in-store, handle deliveries, manage staff, and organize their businesses. We bring together everything required to run a local business in one system.
  div(v-for="(item, index) in items", :key="index")
    .subtitle-2.my-5 {{ item.title }}
    .img-box
      v-img(
        :height="item.height",
        :width="item.width",
        :src="item.icon",
        contain
      )
    .d-flex.flex-row.align-center.my-3
      .body-2.mr-3 Download
      a.mr-3(
        v-for="(file, findex) in item.files",
        :key="findex + 'file'",
        :href="file.path",
        download
      ) {{ file.name }}
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          title: "iOS App Logo",
          icon: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/brand_resources%2FiOS_app%2Fselflane_ios.png?alt=media&token=11f8a4f3-9f57-4672-96a1-430505fa9ae2",
          height: 160,
          width: 160,
          files: [
            {
              name: "png",
              path: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/brand_resources%2FiOS_app%2Fselflane_ios.png?alt=media&token=11f8a4f3-9f57-4672-96a1-430505fa9ae2",
            },
            {
              name: "eps",
              path: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/brand_resources%2FiOS_app%2FSelflane.eps?alt=media&token=04c614d4-4553-4006-8589-540e384b4935",
            },
          ],
        },
        {
          title: "Selflane Name Logo",
          icon: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/brand_resources%2Flogos%2FSelflane.png?alt=media&token=84af762d-c5b3-4377-a524-affa3a5ecba1",
          height: 71,
          width: 264,
          files: [
            {
              name: "png",
              path: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/brand_resources%2Flogos%2FSelflane.png?alt=media&token=84af762d-c5b3-4377-a524-affa3a5ecba1",
            },
          ],
        },
        {
          title: "Selflane Online Order Logo (light)",
          icon: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/brand_resources%2Fonline_order%2FSelflane%20OO%20WB.png?alt=media&token=ddf58400-b517-418f-8a13-dd47e510bbb3",
          height: 125,
          width: 298,
          files: [
            {
              name: "png",
              path: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/brand_resources%2Fonline_order%2FSelflane%20OO%20WB.png?alt=media&token=ddf58400-b517-418f-8a13-dd47e510bbb3",
            },
          ],
        },
        {
          title: "Selflane Online Order Logo (dark)",
          icon: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/brand_resources%2Fonline_order%2FSelflane%20OO%20PB.png?alt=media&token=3593d5be-fc72-4d4e-88e6-1c147f574780",
          height: 125,
          width: 298,
          files: [
            {
              name: "png",
              path: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/brand_resources%2Fonline_order%2FSelflane%20OO%20PB.png?alt=media&token=3593d5be-fc72-4d4e-88e6-1c147f574780",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="sass" scoped>
.img-box
  border-radius: 8px
  overflow: hidden
</style>