<template lang="pug">
v-btn(icon, color="error", @click="handleDelete()", :loading="loading")
  v-icon(small) mdi-delete
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    review: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions(["removeReview"]),
    handleDelete() {
      confirm("Do you really want to delete this review?") && this.deleteOne();
    },
    async deleteOne() {
      this.loading = true;
      try {
        const result = await this.$api.review.delete(this.review._id);
        this.removeReview(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
