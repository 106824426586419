<template lang="pug">
v-container
  Page(title="My Orders")
    template(v-slot:header)
      YearSelector(@select="load")
    OrderView(:loading="loading")
    DelivTracking
    .text-center.my-10
      FAQ
</template>

<script>
import OrderView from "@/components/Order/index";
import DelivTracking from "@/components/Order/DelivTracking/index";
import FAQ from "@/components/Order/FAQ";
import { mapActions } from "vuex";
import moment from "moment-timezone";

export default {
  components: { OrderView, DelivTracking, FAQ },
  data() {
    return { loading: false };
  },
  destroyed() {
    this.setOrders([]);
  },
  methods: {
    ...mapActions(["setOrders"]),
    async load(year) {
      if (!this.$auth.user()) return this.setOrders([]);
      const id = this.$auth.user()._id;
      const begin = moment().year(year).startOf("year").unix() * 1000;
      const end = moment().year(year).endOf("year").unix() * 1000;
      const params = {
        criteria: { "customer.id": id, needed: { $gte: begin, $lt: end } },
        select:
          "seller customer needed status orderNumber payment.total deliv type",
      };
      this.loading = true;
      try {
        const result = await this.$api.order.list(params);
        this.setOrders(result);
      } catch (e) {
        this.setOrders([]);
      }
      this.loading = false;
    },
  },
};
</script>
