<template lang="pug">
v-dialog(v-model="dialog", width="400", @keydown.esc="dialog = false")
  template(v-slot:activator="{ on }")
    v-btn.text-capitalize(v-on="on", small, outlined, rounded) How does it work?
  v-card
    v-toolbar(dense, flat)
      .subtitle-2 How Does Gift Card Work
    v-card-text
      p We send funds to the restaurant immediately for all gift card purchases. These purchases are non-refundable.
      p You can use the gift card either online or in-store for the designated restaurant. You can also send your gift cards to other people by email (post-purchase).
      p You can add multiple gift cards in an order and later on send them to different people.
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
};
</script>