<template lang="pug">
v-card(v-if="$auth.check() && orders.length && !cartCount", shaped, flat)
  v-toolbar(flat, dense, color="navy", dark)
    .sl-title Quick reorder from history
  v-list.py-0(v-for="(order, index) in orders", :key="index", dense)
    v-list-item(@click="show(order)")
      v-list-item-content
        v-list-item-title {{ order.seller.name }}
        v-list-item-subtitle {{ order._id | datetime("MMM D") }}
    v-divider(v-if="index !== orders.length - 1")
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      orders: [],
    };
  },
  computed: {
    ...mapGetters(["biz", "cartCount"]),
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      if (!this.$auth.check() || !this.biz) return;
      const id = this.$auth.user()._id;
      const params = {
        criteria: { "customer.id": id, "seller.id": this.biz._id },
        select: "seller needed",
        limit: 3,
      };
      this.orders = await this.$api.order.list(params);
    },
    show(item) {
      if (item?._id) {
        EventBus.$emit("show-order-detail", item._id);
      }
    },
  },
};
</script>
