<template lang="pug">
v-alert(dense, color="warning", dark, v-if="isLimited") {{ note }}
</template>

<script>
export default {
  props: {
    biz: { type: Object, default: null },
  },
  computed: {
    isLimited() {
      return this.biz?.orderType?.limited_access?.status;
    },
    note() {
      return (
        this.biz.orderType.limited_access.note ||
        "This business has limited access restriction. Please make sure you are accessible to the property and service."
      );
    },
  },
};
</script>