<template lang="pug">
div
  .title {{ order.seller.name }}
  .detail
    div {{ order.seller.phone | phone }}
    div {{ order.seller.address | address("", order.seller.industry) }}
      v-chip.ml-2(v-if="order.seller.industry", small, outlined) {{ order.seller.industry | industry }}
  .sl-dash-divider.my-3
  .detail
    .d-flex.flex-row
      v-icon.mr-2(small) mdi-tag-outline
      div \#{{ order.orderNumber }} | {{ ordererType }} |
        span.ml-1.text-capitalize {{ order.type | orderType }} |
        span.ml-1(:class="{ 'red--text': order.status == -1 }") {{ order.status | orderStatus }}
    .d-flex.flex-row
      v-icon.mr-2(small) mdi-clock-check-outline
      span {{ order.needed | datetime("MMM D, YYYY h:mm A", timezone) }} (estimated, {{ timezone }})
    .d-flex.flex-row
      v-icon.mr-2(small) mdi-account
      span {{ order.customer.name }} {{ order.customer.phone | phone }}
    .d-flex.flex-row.align-start(v-if="isDelivery")
      v-icon.mr-2(small) mdi-map-marker
      div
        div {{ order.customer.address | address("street") }}
        div {{ order.customer.address | address("city, state") }}
        .success--text {{ order.customer.address.note }}
</template>

<script>
import moment from "moment-timezone";

export default {
  props: ["order"],
  computed: {
    ordererType() {
      if (this.order && this.order.orderer) {
        if (this.order.orderer.type == "0") return "Online";
        if (this.order.orderer.type == "2") return "Third Party";
      }
      return "In Store";
    },
    isDelivery() {
      return this.order.type == "delivery";
    },
    timezone() {
      return this.order?.seller?.address?.timezone || moment.tz.guess();
    },
  },
};
</script>

<style lang="sass" scoped>
.title
  font-weight: 500
  font-size: 18px

.detail
  font-size: 14px
  color: rgba(28, 31, 52, 0.6)
</style>
