<template lang="pug">
.coupon-text.mt-2(@click="toDetail()")
  .name {{ promotion.name }}
  .d-flex.flex-row.justify-space-between.align-center
    .name
      span(v-if="promotion.type === 'fix'") {{ -promotion.fix | currency }}
      span(v-else) {{ -promotion.pct }}%
    .body-2.text--secondary Exp: {{ promotion.expiry | date }}
</template>

<script>
export default {
  props: ["promotion"],
  methods: {
    toDetail() {
      if (!this.promotion) return;
      this.$router.push("/couponisland/" + this.promotion.code);
    },
  },
};
</script>

<style lang="sass" scoped>
.coupon-text
  cursor: pointer

.name
  font-weight: 500
  font-size: 16px
  color: #1c1f34
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
</style>
