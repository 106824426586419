const state = {
  chain: null
}

const getters = {
  chain: state => state.chain,
}

const actions = {
  setChain: ({ commit }, data) => { commit('setChain', data) }
}

const mutations = {
  setChain(state, data) {
    state.chain = data
  }
}

export default { state, getters, actions, mutations }