<template lang="pug">
div
  .title-style Reviews
  List.my-5(:reviews="reviews")
</template>

<script>
import List from "/libs/components/Review/List";
import { mapGetters } from "vuex";

export default {
  components: { List },
  computed: { ...mapGetters(["reviews"]) },
};
</script>

<style lang="sass" scoped>
.title-style
  display: inline
  font-weight: 500
  font-size: 24px
  padding-bottom: 4px
  border-bottom: solid 3px #FF6D32
  margin-bottom: 20px
</style>
