const state = {
  promotions: [],
  claims: []
};

const getters = {
  promotions: state => state.promotions,
  claims: state => state.claims
};

const actions = {
  setPromotions: ({ commit }, data) => { commit("setPromotions", data); },
  addPromotion: ({ commit }, data) => { commit("addPromotion", data); },
  updatePromotion: ({ commit }, data) => { commit("updatePromotion", data); },
  setClaims: ({ commit }, data) => { commit("setClaims", data); },
  addClaim: ({ commit }, data) => { commit("addClaim", data); },
  updateClaim: ({ commit }, data) => { commit("updateClaim", data); },
};

const mutations = {
  setPromotions(state, data) {
    state.promotions = data;
  },
  addPromotion(state, data) {
    state.promotions.push(data);
  },
  updatePromotion(state, data) {
    state.promotions = state.promotions.map(o => {
      return (o._id === data._id) ? data : o;
    });
  },
  setClaims(state, data) {
    state.claims = data;
  },
  addClaim(state, data) {
    state.claims.push(data);
  },
  updateClaim(state, data) {
    state.claims = state.claims.map(o => {
      return (o._id === data._id) ? data : o;
    })
  },
};

export default { state, getters, actions, mutations };