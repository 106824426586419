<template lang="pug">
v-dialog(v-model="dialog", max-width="300px", @keydown.esc="dialog = false")
  template(v-slot:activator="{ on }")
    v-btn(icon, small, color="secondary", v-on="on")
      v-icon(small) mdi-pencil
  v-card
    v-card-title Edit Name
    v-form(@submit.prevent="save")
      v-card-text
        v-text-field(label="Name", v-model="name")
      v-card-actions
        v-btn(
          text,
          block,
          color="secondary",
          type="submit",
          :loading="loading"
        ) save
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["order"],
  data() {
    return {
      dialog: false,
      name: "",
      loading: false,
    };
  },
  methods: {
    ...mapActions(["updateOrder"]),
    async save() {
      if (this.name == this.order.customer.name) {
        return this.$toast.error("Same name");
      }
      this.loading = true;
      const params = {
        orderId: this.order._id,
        name: this.name,
        portal: "customer",
      };
      try {
        const res = await this.axios.post("/orders/editName", params);
        this.updateOrder(res.data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>