<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  Detail(:order="order", :showAction="showAction", @close="handleClose")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Detail from "./Detail/index";

export default {
  components: { Detail },
  data() {
    return {
      order: null,
      dialog: false,
      loading: false,
      showAction: true,
    };
  },
  mounted() {
    EventBus.$on("show-order-detail", this.load);
  },
  destroyed() {
    EventBus.$off("show-order-detail", this.load);
  },
  methods: {
    async load(orderId) {
      if (!orderId) return;
      this.dialog = true;
      const params = { criteria: { _id: orderId } };
      try {
        this.order = await this.$api.order.retrieve(params);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    handleClose() {
      this.dialog = false;
    },
  },
};
</script>
