<template lang="pug">
.my-3.white(v-if="bizs && bizs.length")
  v-container
    .sl-title New to Selflane
    BizList(:bizs="bizs")
</template>

<script>
import { mapGetters } from "vuex";
import BizList from "@/components/Bizs/ListRow";
import _ from "underscore";
import moment from "moment-timezone";

export default {
  components: { BizList },
  computed: {
    ...mapGetters(["bizAccounts"]),
    bizs() {
      const three_month = moment().subtract(3, "months").unix() * 1000;
      return _.chain(this.bizAccounts)
        .filter((o) => o && o.online_start > three_month)
        .sortBy("online_start")
        .value()
        .reverse();
    },
  },
};
</script>
