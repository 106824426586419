import _ from 'underscore'
import moment from 'moment-timezone'
import BizUtils from "/libs/utils/biz.js";

const state = {
  deviceId: '',
  confirmedBiz: null, // biz Id of the biz that user has confirmed to order
  userLocation: null, // lat, lng of user's current location
  bag: null,
  weekly_specials: [],
  geoService: {},
  cart: {
    giftProgram: {},
    biz: {},
    order: {
      seller: {},
      customer: {},
      orderer: { type: 0 },
      dishes: [],
      gifts: [],
      deliv: undefined,
      payment: {
        dish: 0,
        tax: 0,
        fee: 0,
        gift: 0,
        tip: 0,
        deduction: { total: 0, items: [] },
        selflane: { total: 0, items: [] },
        total: 0,
        card: {},
        charges: []
      },
      summary: [],
      type: undefined,
      needed: 0,
      note: '',
      proxy: 'Vue',
      promo: null
    },
    promotion: null,
  },
  cartAddressResult: null,
  due: 0,
  restaurantTotal: 0,
  serviceFeeRate: 0,
  minServiceFee: 0,
  inclusiveRange: 0,
  maxRange: 0,
  unitFee: 0,
  deliveryFee: 2.99
}

const getters = {
  deviceId: state => state.deviceId,
  confirmedBiz: state => state.confirmedBiz,
  userLocation: state => state.userLocation,
  bag: state => state.bag,
  cartGeoService: state => state.geoService,
  weekly_specials: state => state.weekly_specials,
  cartOrder: state => state.cart.order,
  cartAddressResult: state => state.cartAddressResult,
  cartBiz: state => state.cart.biz,
  cartPromotion: state => state.cart.promotion,
  cartCount: (state) => {
    const dish = state.cart?.order?.dishes?.reduce((a, b) => a + b.quantity, 0) || 0
    const gift = state.cart?.order?.gifts?.length || 0
    return dish + gift
  },
  /// return the home page url for a business
  cartUrl: (state) => {
    const url = state.cart?.biz?.url
    return url ? `/bizs/${url}` : ''
  },
  cartDue: state => state.due,
  cartRestaurantTotal: state => state.restaurantTotal,
  cartServiceFeeRate: state => state.serviceFeeRate,
  cartMinServiceFee: state => state.minServiceFee,
  cartInclusiveRange: state => state.inclusiveRange,
  cartMaxRange: state => state.maxRange,
  cartUnitFee: state => state.unitFee
}

const actions = {
  initDeviceId: ({ commit }) => { commit('initDeviceId') },
  setConfirmedBiz: ({ commit }, data) => { commit('setConfirmedBiz', data) },
  setUserLocation: ({ commit }, data) => { commit('setUserLocation', data) },
  setBag: ({ commit }, data) => { commit('setBag', data) },
  setWeeklySpecials: ({ commit }, data) => { commit('setWeeklySpecials', data) },
  addDishToCart: ({ commit }, data) => { commit('addDishToCart', data) },
  removeDishAt: ({ commit }, data) => { commit('removeDishAt', data) },
  setDishes: ({ commit }, data) => { commit('setDishes', data) },
  editDishAt: ({ commit }, data) => { commit('editDishAt', data) },
  addGiftToCart: ({ commit }, data) => { commit('addGiftToCart', data) },
  removeGiftAt: ({ commit }, data) => { commit('removeGiftAt', data) },
  validateAddress: ({ commit, }) => { commit('validateAddress') },
  setFeeStructure: ({ commit, }) => { commit('setFeeStructure') },
  updateCartPayment: ({ commit, rootState }) => { commit('updateCartPayment', rootState) },
  resetCart: ({ commit }) => { commit('resetCart') },
  prepareCheckout: ({ commit }, data) => { commit('prepareCheckout', data) },
  prepareCartNeeded: ({ commit }, data) => { commit('prepareCartNeeded', data) },
  setTip: ({ commit }, data) => { commit('setTip', data) },
  setPhone: ({ commit }, data) => { commit('setPhone', data) },
  setAddress: ({ commit }, data) => { commit('setAddress', data) },
  setCard: ({ commit }, data) => { commit('setCard', data) },
  setNote: ({ commit }, data) => { commit('setNote', data) },
  setPromotion: ({ commit }, data) => { commit('setPromotion', data) },
  applyGC: ({ commit }, data) => { commit('applyGC', data) },
  removeGC: ({ commit }, data) => { commit('removeGC', data) },
  setCartBiz: ({ commit }, data) => { commit('setCartBiz', data) },
  setCartGeoService: ({ commit }, data) => { commit('setCartGeoService', data) },
  setCartNeeded: ({ commit }, data) => { commit('setCartNeeded', data) },
  submitCart: async ({ commit, state }) => {
    // validate cart
    let errors = []
    const order = state.cart.order
    const biz = state.cart.biz
    const hasAlcohol = order.summary.some(o => o.classification == 3)
    if (!hasSeller(order.seller)) errors.push('Invalid seller')
    if (!hasCustomer(order.customer)) errors.push('Name is required')
    const limited_access = biz.orderType?.limited_access
    const access_title = limited_access?.title
    const requireAccessID = limited_access?.status && access_title
    if (requireAccessID) {
      order.customer.access = order.customer?.access?.trim()
      if (!order.customer?.access) errors.push(`Please provide ${access_title}`)
    } else {
      order.customer.access = undefined
    }
    if (!order.customer?.phone) errors.push('Phone is required')
    if (!hasType(order.type)) errors.push('Invalid type')
    if (order.type === 'delivery') {
      if (!state.cartAddressResult || !state.cartAddressResult.status) {
        errors.push(state.cartAddressResult.message)
      }
      if (!order.deliv)
        if (!hasMinimumCharge(order.payment)) errors.push('Delivery order requires a minimum subtotal of $15 before tax and tips. Please either add more selections or change to pick up')
      if (hasAlcohol) {
        const minimumTip = Math.round(order.payment.dish * 10) / 100
        if (order.payment.tip < minimumTip) {
          errors.push('A minimum 10% ($' + minimumTip + ') tip on an alcohol delivery order is required')
        }
      }
    }
    if (!hasCharge(order.payment)) errors.push('Requires a minimum of $5 order')
    if (!hasPaymentMethod(order.payment)) errors.push('Please provide a credit card payment method')
    if (!hasItems(order)) errors.push('Empty cart')
    if (errors.length) throw new Error(errors.join('\n'))

    if (order.dishes && order.dishes.length) {
      const result = BizUtils.validateTypeTime(order.type, order.needed, biz, state.geoService)
      if (result.status === false) errors.push(result.error)
      else {
        errors = errors.concat(validateDishes(order, biz))
      }
    }
    if (errors.length) throw new Error(errors.join('\n'))

    if (order.type !== 'delivery') {
      order.customer.address = {}
    }
    order.promo = state.cart.promotion?._id

    const timezone = order?.seller?.address?.timezone || moment.tz.guess()
    order.neededStr = moment.tz(order.needed, timezone).format('YYYY-MM-DD HH:mm')
    order.orderer.type = 0
    order.device = {
      id: state.deviceId,
      model: 'Web',
      appName: 'Vue'
    }
    try {
      const { data } = await window.axios.post('/orders/createOnline', order)
      commit('resetCart')
      return data
    } catch (e) {
      throw new Error(e.response?.data || e.message || 'Failed to pass server validation')
    }

    function hasSeller(seller) {
      return [seller.id, seller.url, seller.name].every(str => str)
    }

    function hasCustomer(customer) {
      return [customer.id, customer.name, customer.email].every(str => str)
    }

    function hasItems(order) {
      return (order.dishes?.length || order.gifts?.length)
    }

    function hasCharge(payment) {
      return (payment && payment.total && payment.total > 5)
    }

    function hasMinimumCharge(payment) {
      return (payment && payment.dish && payment.dish >= 15)
    }

    function hasPaymentMethod(payment) {
      const paid = payment.charges.reduce((a, b) => a + b.amount, 0)
      const due = payment.total - paid;
      if (due > 0) {
        return [payment.card?.id, payment.card?.last4, payment.card?.brand].every(str => str)
      }
      return true;
    }

    function hasType(type) {
      return ['pickup', 'delivery', 'dinein', 'curbside'].includes(type)
    }

    function validateDishes(order, biz) {
      const errors = []
      const availableMenus = []
      _.each(biz.menus, menu => {
        if (!menu.selectedTime) {
          availableMenus.push(menu._id)
        } else {
          const valid = validateMenu(menu, moment(order.needed))
          if (valid) availableMenus.push(menu._id)
        }
      })
      _.each(order.dishes, dish => {
        const crosscheck = _.intersection(availableMenus, dish.menus)
        if (crosscheck === undefined || crosscheck.length === 0) {
          errors.push(dish.name + ' is not available at the selected time')
        }
      })
      return errors
    }
  }
}

// validate both in today and the day before
// menu is Biz.Menu object
// time is moment object
function validateMenu(menu, time) {
  let minute = time.hour() * 60 + time.minute()
  let day = time.day()
  let validDay = menu.date?.includes(day)
  let validMinute = BizUtils.validateSchedule(minute, menu.schedule)
  if (validDay && validMinute) return true
  day -= 1
  if (day < 0) day = 6
  minute += 24 * 60
  validDay = menu.date?.includes(day)
  validMinute = BizUtils.validateSchedule(minute, menu.schedule)
  return (validDay && validMinute)
}

const mutations = {
  async initDeviceId(state) {
    if (state.deviceId) return
    try {
      const { data } = await window.axios.get('/shopping/uuid/generate')
      state.deviceId = data.uuid
    } catch (e) {
      // fail silently
    }
  },
  setConfirmedBiz(state, data) { state.confirmedBiz = data },
  setUserLocation(state, data) { state.userLocation = data },
  setBag(state, data) { state.bag = data },
  setWeeklySpecials(state, data) {
    state.weekly_specials = data
  },
  addDishToCart(state, data) {
    const { dish, biz } = data
    const cartBiz = state.cart.biz?._id

    if (!cartBiz) {
      this.dispatch('setCartBiz', biz)
    } else if (cartBiz !== biz._id) {
      this.dispatch('resetCart')
      this.dispatch('addDishToCart', data)
      return
    }
    state.cart.order.dishes.push(dish)
    this.dispatch('updateCartPayment')
  },
  removeDishAt(state, index) {
    state.cart.order.dishes.splice(index, 1)
    this.dispatch('updateCartPayment')
  },
  editDishAt(state, data) {
    if (data.index < state.cart.order.dishes.length) {
      state.cart.order.dishes[data.index] = JSON.parse(JSON.stringify(data.dish))
      state.cart.order.dishes = JSON.parse(JSON.stringify(state.cart.order.dishes))
      this.dispatch('updateCartPayment')
    }
  },
  setDishes(state, dishes) {
    state.cart.order.dishes = dishes
    this.dispatch('updateCartPayment')
  },
  addGiftToCart(state, data) {
    const { value, biz, giftProgram } = data
    if (!value || !biz) return
    const cartBiz = state.cart.biz?._id

    state.cart.giftProgram = giftProgram
    if (!cartBiz) {
      this.dispatch('setCartBiz', biz)
    } else if (cartBiz !== biz._id) {
      this.dispatch('resetCart')
      this.dispatch('addGiftToCart', data)
      return
    }
    if (!state.cart.order.gifts) {
      state.cart.order.gifts = [{ amount: value }];
    } else {
      state.cart.order.gifts.push({ amount: value });
    }
    this.dispatch('updateCartPayment')
  },
  removeGiftAt(state, index) {
    if (state.cart.order.gifts && state.cart.order.gifts.length > index) {
      state.cart.order.gifts.splice(index, 1);
      this.dispatch('updateCartPayment')
    }
  },
  validateAddress(state) {
    const order = state.cart.order
    if (order.type != 'delivery') {
      state.cartAddressResult = { status: true, message: '' }
      return
    }
    if (!order.customer?.address?.geometry?.lat) {
      state.cartAddressResult = { status: false, message: 'Missing address information' }
      return
    }

    const biz = state.cart.biz
    const country = biz.address?.country?.trim() || 'US'
    let unit = 'mi.'
    if (country != "US" && country != "United States") unit = 'km'
    const result = BizUtils.geoDistance2(
      order.seller.address.geometry,
      order.customer.address.geometry,
      unit
    );
    const distance = result.value

    // self delivery
    if (!biz.orderType.delivery?.deliv) {
      if (biz.zone?.status) {
        const status = BizUtils.insidePolygon(order.customer.address.geometry, biz.zone.polygon)
        state.cartAddressResult = {
          status, distance,
          distanceUnit: unit,
          message: status ? "" : 'Outside of delivery zone'
        }
        return
      }
    }

    let message = ''
    if (distance > state.maxRange) {
      message = `Delivery address should be in ${state.maxRange} ${unit} radius.`
    } else if (distance > state.inclusiveRange) {
      message = `Radius beyond ${state.inclusiveRange} ${unit} will have extra charge.`
    }
    if (distance) message += ` Yours is ${distance} ${unit}`
    state.cartAddressResult = {
      status: distance <= state.maxRange,
      distance, distanceUnit: unit, message
    }
  },
  updateCartPayment(state, rootState) {
    this.dispatch('setFeeStructure')
    this.dispatch('validateAddress')

    let dishTotal = state.cart.order.dishes?.reduce((a, b) => a + getDishValue(b), 0) || 0
    state.cart.order.payment.dish = round2(dishTotal)

    /// Selflane Fees
    state.cart.order.payment.selflane = { total: 0, items: [] }
    const isDelivery = state.cart.order.type == 'delivery'
    const is3rdDelivery = isDelivery && state.cart.biz.orderType?.delivery?.deliv
    /// 1. set service fee
    let serviceFee = 0
    if (is3rdDelivery) {
      serviceFee = Math.max(Math.round(dishTotal * state.serviceFeeRate) / 100, state.minServiceFee)
    } else {
      const payment = state.cart.order.payment
      const subtotal = payment.dish + payment.tip + payment.tax
      serviceFee = Math.max(Math.round(subtotal * state.serviceFeeRate) / 100, state.minServiceFee)
    }
    state.cart.order.payment.selflane.items.push({ name: 'service_fee', value: serviceFee })

    /// 2. set delivery fee
    state.cart.order.payment.fee = 0
    if (is3rdDelivery) {
      state.cart.order.payment.selflane.items.push({ name: 'delivery_fee', value: state.deliveryFee })
    } else if (isDelivery) {
      state.cart.order.payment.fee = state.deliveryFee
    }

    /// 3. set distance surcharge
    if (is3rdDelivery && state.cart.order.seller.address && state.cart.order.customer.address) {
      let distance = state.cartAddressResult.distance
      if (distance > state.inclusiveRange) {
        const distanceSurcharge = Math.ceil(distance - state.inclusiveRange) * state.unitFee
        state.cart.order.payment.selflane.items.push({ name: 'distance_surcharge', value: distanceSurcharge })
      }
    }
    state.cart.order.payment.selflane.total = state.cart.order.payment.selflane.items.reduce((a, b) => a + b.value, 0)

    /// Deductions
    state.cart.order.payment.deduction = { total: 0, items: [] }
    /// 1. delivery discount
    if (isDelivery && state.cart.biz?.delivTiers) {
      let clones = JSON.parse(JSON.stringify(state.cart.biz.delivTiers))
      const found = clones.findLast(o => dishTotal >= o.threshold)
      if (found) {
        const value = Math.min(found.deduction, state.deliveryFee)
        state.cart.order.payment.deduction.items.push({ name: 'Delivery discount', value: value })
      }
    }
    /// 2. gift card discount
    state.cart.order.payment.gift = state.cart.order.gifts?.reduce((a, b) => a + b.amount, 0) || 0
    if (state.cart.order.payment.gift > 0) {
      if (state.cart.giftProgram?.discount?.status && state.cart.giftProgram.discount.minimum <= state.cart.order.payment.gift) {
        const value = Math.round(state.cart.order.payment.gift * state.cart.giftProgram.discount.pct) / 100
        state.cart.order.payment.deduction.items.push({ name: 'Gift card discount', value: value })
      }
    }

    /// 3. weekly special
    if (state.weekly_specials?.length && state.cart.order.needed) {
      const day = moment(state.cart.order.needed).day()
      // find first qualified special
      const found = state.weekly_specials.find(o => {
        if (o.biz != state.cart.biz._id) return false
        return o.days.includes(day)
      })
      if (found && found.pct > 0 && found.pct < 100) {
        const value = Math.round(found.pct * state.cart.order.payment.dish) / 100
        if (value > 0) {
          const name = `${found.name} ${found.pct}% off`
          state.cart.order.payment.deduction.items.push({ name: name, value: value })
        }
      }
    }
    /// 4. check influencer
    let influencerModule = rootState.influencer
    let result = BizUtils.getInfluencerPlan(influencerModule.influencerPlans, influencerModule.influencers, influencerModule.influencerCode)
    state.cart.order.influencer = null
    let influencerDeduction = 0
    if (result.plan && state.cart.order.payment.dish > result.plan.minimum) {
      if (result.plan.type == 'pct') {
        influencerDeduction = Math.min(state.cart.order.payment.dish, result.plan.limit) * result.plan.pct / 100
      } else {
        influencerDeduction = result.plan.fix
      }
      let name = 'Influencer Discount'
      if (!result.plan.is_open) {
        name = 'Influencer ' + influencerModule.influencerCode
      }
      state.cart.order.payment.deduction.items.push({ name: name, value: round2(influencerDeduction) })
      state.cart.order.influencer = {
        account: result.influencer._id,
        code: result.influencer.code,
        plan: result.plan._id
      }
    }
    state.cart.order.payment.deduction.total = state.cart.order.payment.deduction.items?.reduce((a, b) => a + b.value, 0) || 0

    /// Finalize
    state.cart.order.payment.tax_items = getTaxItems(state.cart.order.dishes, state.cart.biz.tax_items)
    state.cart.order.summary = getSummary(state.cart.order.dishes, state.cart.biz)
    state.cart.order.payment.tax = state.cart.order.payment.tax_items.reduce((a, b) => {
      return b.type == 0 ? a + b.value : a
    }, 0)
    state.cart.order.payment.tax = round2(state.cart.order.payment.tax)
    state.cart.order.payment.total = state.cart.order.payment.dish +
      state.cart.order.payment.tax + state.cart.order.payment.fee + state.cart.order.payment.gift +
      state.cart.order.payment.tip - state.cart.order.payment.deduction.total +
      state.cart.order.payment.selflane.total
    state.cart.order.payment.total = round2(state.cart.order.payment.total)
    const paid = state.cart.order.payment.charges?.reduce((a, b) => a + b.amount, 0) || 0
    state.due = round2(state.cart.order.payment.total - paid)
    state.restaurantTotal = round2(state.cart.order.payment.total - state.cart.order.payment.selflane.total)
  },
  setFeeStructure(state) {
    state.serviceFeeRate = 4 // basic fee rate. apply to any orders
    state.minServiceFee = 0 // minimum fee if the percentage result is too little
    state.deliveryFee = 2.99
    state.inclusiveRange = 5
    state.maxRange = 5
    state.unitFee = 1.5

    const order = state.cart.order
    const hasAlcohol = order.summary.some(o => o.classification == 3)
    const isDelivery = order.type == 'delivery'
    const is3rdDelivery = isDelivery && state.cart.biz.orderType?.delivery?.deliv
    if (is3rdDelivery) {
      if (state.geoService?.delivery) {
        state.serviceFeeRate = state.geoService.delivery.rate
        state.minServiceFee = state.geoService.delivery.minService
        state.deliveryFee = state.geoService.delivery.fee
        state.inclusiveRange = state.geoService.delivery.inclusiveRange
        state.maxRange = state.geoService.delivery.maxRange
        state.unitFee = state.geoService.delivery.unitFee
      }
    } else {
      if (state.geoService?.basicRate) {
        state.serviceFeeRate = state.geoService.basicRate
        state.minServiceFee = state.geoService.minBasic || 0
      }
      // self delivery has no extended range
      if (state.cart.biz.orderType?.delivery?.radius) {
        state.inclusiveRange = state.cart.biz.orderType.delivery.radius
        state.deliveryFee = state.cart.biz.orderType.delivery.price
      }
      state.maxRange = state.inclusiveRange
    }
    if (hasAlcohol) state.serviceFeeRate += 4 // 4% extra for alcohol
  },
  resetCart(state) {
    state.cart = {
      giftProgram: {},
      biz: {},
      order: {
        seller: {},
        customer: {},
        orderer: { type: 0 },
        dishes: [],
        gifts: [],
        deliv: undefined,
        payment: {
          dish: 0,
          tax_items: [],
          tax: 0,
          fee: 0,
          gift: 0,
          tip: 0,
          deduction: { total: 0, items: [] },
          selflane: { total: 0, items: [] },
          total: 0,
          card: {},
          charges: []
        },
        summary: [],
        type: undefined,
        needed: 0,
        note: '',
        proxy: 'Vue',
        promo: null
      },
      promotion: null,
    }
  },
  prepareCheckout(state, user) {
    if (!user) return
    // set customer info
    let phone = state.cart.order?.customer?.phone
    if (!phone) {
      phone = user.phones?.length ? user.phones[0].number : ''
    }
    let name = state.cart.order?.customer?.name
    if (!name) {
      name = user.name.preferred || user.name.first
    }
    let address = state.cart.order?.customer?.address
    if (!address?.line1) {
      address = _.first(user.addresses)
    }
    const access = state.cart.order?.customer?.access
    state.cart.order.customer = { id: user._id, name, access, email: user.email, phone, address }

    // set type and needed time
    const gift_only = !state.cart.order.dishes?.length
    if (gift_only) {
      state.cart.order.type = 'pickup'
    } else if (!state.cart.order.type) {
      const types = state.cart.biz.orderType
      if (types.pickup?.status) {
        state.cart.order.type = 'pickup'
      } else if (types.curbside?.status) {
        state.cart.order.type = 'curbside'
      } else if (types.delivery?.status) {
        state.cart.order.type = 'delivery'
      } else {
        state.cart.order.type = 'pickup'
      }
    }

    // tip setting
    // if business does not allow online tip, set tip to 0
    // or if the cart is gift only, set tip to 0
    // otherwise, set tip to 10% of the dish total if it is 0
    const biz = state.cart.biz
    const tip_online = biz.settings?.tip_online != false
    if (!tip_online || gift_only) {
      state.cart.order.payment.tip = 0
    } else if (state.cart.order.payment.tip == 0) {
      state.cart.order.payment.tip = Math.round(state.cart.order.payment.dish * 10) / 100
    }

    this.dispatch('prepareCartNeeded')
  },
  prepareCartNeeded(state) {
    const biz = state.cart.biz
    const timezone = biz?.address?.timezone || moment.tz.guess()
    const isDelivery = state.cart.order.type == 'delivery'
    const is3rdDelivery = isDelivery && state.cart.biz?.orderType?.delivery?.deliv
    let leadtime = 20
    if (state.cart.order.type) {
      const type = _.property(state.cart.order.type)(state.cart.biz.orderType)
      if (type?.beforehand) leadtime = type.beforehand
    }
    // estimate the earliest time for the order
    const minutes = getEarliestMinutes(biz.schedule, leadtime, isDelivery, is3rdDelivery, timezone)
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const needed = moment().tz(timezone).hour(hours).minute(mins).startOf("minute").valueOf();
    if (!state.cart.order?.needed || moment(state.cart.order.needed).valueOf() < needed) {
      state.cart.order.needed = needed
    }

    this.dispatch('updateCartPayment')
  },
  setTip(state, tip) {
    if (tip < 0) tip = 0
    state.cart.order.payment.tip = round2(tip)
    this.dispatch('updateCartPayment')
  },
  setPhone(state, number) {
    state.cart.order.customer.phone = number
  },
  setAddress(state, address) {
    state.cart.order.customer.address = address
    this.dispatch('updateCartPayment')
  },
  setCard(state, card) {
    if (!card?.id) {
      state.cart.order.payment.card = {}
    } else {
      state.cart.order.payment.card = {
        id: card.id,
        last4: card.last4,
        brand: card.brand,
        type: 0
      }
    }
  },
  setNote(state, note) {
    state.cart.order.note = note
  },
  setCartBiz(state, biz) {
    state.cart.biz = biz
    const sellerPhone = (biz.phones?.length) ? biz.phones[0].number : ''
    state.cart.order.seller = {
      id: biz._id,
      url: biz.url,
      name: biz.name,
      industry: biz.industry,
      phone: sellerPhone,
      address: biz.address
    }
  },
  setCartGeoService(state, data) {
    state.geoService = data
  },
  setPromotion(state, promotion) {
    if (!promotion) return
    if (state.cart.order.payment.dish < promotion.minimum) {
      alert('Minimum purchase of $' + promotion.minimum + ' is required')
      return
    }
    if (state.cart.promotion?._id == promotion?._id) state.cart.promotion = null
    else state.cart.promotion = promotion
    state.cart.order.payment.charges = []
    this.dispatch('updateCartPayment')
  },
  applyGC(state, gc) {
    state.cart.promotion = null
    if (state.due <= 0) {
      alert('Order has no amount due')
      return;
    }
    const found = state.cart.order.payment.charges.find(o => o.transId === gc._id);
    if (found) {
      const message = 'A same gift card is found in the payment. If you want to apply this gift card again after cart is edited, you need to remove this card first.'
      confirm(message) && this.dispatch('removeGC', gc._id);
      return;
    }
    const amount = Math.min(state.due, gc.balance);
    const charge = { base: amount, amount: amount, method: 'gift', transId: gc._id };
    state.cart.order.payment.charges.push(charge);
    this.dispatch('updateCartPayment')
  },
  removeGC(state, id) {
    state.cart.order.payment.charges = state.cart.order.payment.charges.filter(o => o.transId !== id);
    this.dispatch('updateCartPayment')
  },
  setCartNeeded(state, needed) {
    state.cart.order.needed = needed
  }
}

export default { state, getters, actions, mutations }

/**
 * get earliest minutes available for a biz for today
 * @param {*} schedule
 * @param {*} leadtime
 * @param {*} isDelivery
 * @param {*} is3rdDelivery
 */
function getEarliestMinutes(schedule, leadtime, isDelivery, is3rdDelivery, timezone) {
  const today = BizUtils.getDaySchedule(schedule, moment().tz(timezone), timezone)

  // current time in minutes in timezone + leadtime + 5 minutes
  let minutes = moment().tz(timezone).hour() * 60 + moment().tz(timezone).minute() + leadtime + 5
  // limit 3rd party delivery to 8:30-22; 510-1320
  if (is3rdDelivery && isDelivery) { minutes = Math.max(510, minutes) }

  // if business is closed on the selected date, simply return the current earliest time
  if (!today.status) return minutes

  // find the earliest time in the schedule
  // range is [start, end]
  // if earliest time is in time window, return the window
  // otherwise, return the start of the next window

  let found = today.schedule.find(o => {
    if (o.range?.length != 2) return false
    return (o.range[0] <= minutes && o.range[1] >= minutes)
  })
  // if not found, find the next time window
  if (!found) {
    found = today.schedule.find(o => {
      if (o.range?.length != 2) return false
      return o.range[0] > minutes
    })
  }
  if (found) {
    let open_delay = schedule.open_delay || 10
    if (isDelivery) open_delay = Math.max(open_delay, 30)
    open_delay = Math.max(open_delay, 10)
    let minimum = found.range[0] + open_delay
    return Math.max(minutes, minimum)
  }
  return minutes
}

function getSummary(dishes, biz) {
  return _.chain(dishes)
    .filter(o => o.course)
    .groupBy('course')
    .map((list, course) => {
      const classification = biz.courses.find(course => course._id == course)?.classification || 0
      const amount = list.reduce((a, b) => a + getDishValue(b), 0)
      return { course, classification, amount }
    })
    .groupBy('classification')
    .map((list, classification) => {
      const amount = list.reduce((a, b) => a + b.amount, 0)
      return { classification, amount }
    })
    .value()
}

function getTaxItems(dishes_input, tax_items_input) {
  // filter inactive items
  const tax_items = tax_items_input?.filter(o => o.status)
  // courses with special tax
  const special_courses = _.chain(tax_items).pluck('courses').flatten().uniq().compact().value()
  const dishes = dishes_input?.map(o => {
    return {
      name: o.name,
      value: getDishValue(o),
      course: o.course
    }
  })
  const regular_dishes = []
  const special_dishes = []
  _.each(dishes, o => {
    const found = special_courses.find(course => course == o.course)
    if (found) {
      special_dishes.push(o)
    } else {
      regular_dishes.push(o)
    }
  })
  const result = _.map(tax_items, o => {
    let passed = []
    if (o.courses && o.courses.length > 0) {
      passed = _.filter(special_dishes, dish => {
        return o.courses.some(course => course == dish.course)
      })
    } else {
      passed = regular_dishes
    }
    const total = passed?.reduce((a, b) => a + b.value, 0) || 0
    let value = 0
    if (o.type == 0) {
      value = Math.round(total * o.percentage) / 100
    } else {
      // equation for inclusive tax calculation
      value = Math.round(total / (1 + o.percentage / 100) * o.percentage) / 100
    }
    const { name, percentage, type } = o
    return { name, value, percentage, type }
  })
  return result.filter(o => o.value)
}

function getDishValue(dish) {
  let pctOff = 0
  if (dish.pctOff && dish.pctOff > 0 && dish.pctOff <= 100) {
    pctOff = dish.pctOff
  }
  let div = dish.div
  if (!div || div < 1) div = 1
  if (dish.uom && dish.uom.length > 1) {
    return Math.round(dish.unitPrice * (100 - pctOff) * dish.wom) / 100
  } else {
    return Math.round(dish.unitPrice * (100 - pctOff) * dish.quantity / div) / 100
  }
}

function round2(num) {
  return Math.round(num * 100) / 100
}