/**
 * input: weekly schedule or special schedule in format of {status, schedule: [{range: [begin, end]}, ...]}
 * @returns formatted string of the schedule on that day
 */

function dayschedule(input, hasStatus = true) {
  if (!input) return ''
  if (!input.status && hasStatus == true) return 'Closed'
  return input.schedule.map(o =>
    o.range.map(r => fromMinutesToTime(r)).join('–')
  ).join(', ')
}

function fromMinutesToTime(minutes) {
  if (isNaN(minutes)) return 'N/A'
  const day = Math.floor(minutes / 1440)
  minutes = Math.round(minutes % 1440) // ensure integer
  let hh = Math.floor(minutes / 60)
  let ampm = 'AM'
  if (hh >= 12) {
    ampm = 'PM'
    hh -= 12
  }
  if (hh === 0) hh = 12
  hh = String(hh)
  const mm = String(minutes % 60)
  let text = ''
  if (mm === '0') text = hh + ' ' + ampm
  else {
    text = hh + ':' + mm.padStart(2, '0') + ' ' + ampm
  }
  if (day == 1) text += " (next day)"
  else if (day > 0) text += " (+" + day + ' day)'
  return text
}

export default dayschedule