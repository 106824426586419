<template lang="pug">
div(v-if="biz")
  .my-line-items.my-2(v-if="phone")
    v-icon(small) mdi-phone
    .subtitle-2 {{ phone | phone }}
  .my-line-items.my-2
    v-icon(small) mdi-map-marker
    .subtitle-2 {{ biz.address | address("", biz.industry) }}
    .caption(v-if="biz.industry != 0", small, outlined) \#{{ biz.industry | industry }}
    .caption.text--secondary.nowrap(v-if="distance") {{ distance | readableDistance(unit) }}
  .my-line-items.my-2
    v-icon(small) mdi-tag
    .subtitle-2 {{ biz.cates | cates }}
</template>

<script>
import BizUtils from "/libs/utils/biz.js";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["biz", "userLocation"]),
    phone() {
      if (this.biz.phones?.length) return this.biz.phones[0].number;
      else return null;
    },
    unit() {
      if (this.biz?.address?.country == "CA") return "km";
      return "mi.";
    },
    distance() {
      if (!this.userLocation?.lat) return null;
      const bizGeo = this.biz?.address?.geometry;
      if (!bizGeo) return null;
      return BizUtils.geoDistanceInMeter(this.userLocation, bizGeo);
    },
  },
};
</script>

<style lang="sass" scoped>
// flex in row with gap of 4px, align with first item
.my-line-items
  display: flex
  flex-direction: row
  align-items: baseline
  gap: 5px
</style>