<template lang="pug">
v-card(v-if="order", flat)
  v-card-title
  v-card-text
    Head(:order="order")
    Items(:order="order")
    Payment(:order="order")
    Reorder(:order="order", v-if="showAction", @close="$emit('close')")
  v-card-actions
    Download(:order="order", :delivOrder="delivOrder")
</template>

<script>
import Head from "./Head";
import Items from "./Items";
import Payment from "/libs/components/Order/Payment";
import Reorder from "./Reorder";
import Download from "/libs/components/Order/Detail/Download";

export default {
  components: { Head, Items, Payment, Reorder, Download },
  props: ["order", "delivOrder", "showAction"],
};
</script>
