/**
 * Convert a numeric day or list of days to a string or a joined string
 * @param {number} value - The numeric day
 * @param {boolean} short - If true, return the short version of the day
 * @returns {string} The string day
 * @example
 * // returns 'Sunday'
 * day(0)
 * @example
 * // returns 'Sunday Monday'
 * day([0, 1])
 */

function handler(value, short) {
  if (typeof (value) !== 'number') return value || ''
  let text = getDay(value)
  if (short && typeof text == 'string') return text.substring(0, 3)
  return text
}

function getDay(input) {
  switch (input) {
    case 0: return 'Sunday'
    case 1: return 'Monday'
    case 2: return 'Tuesday'
    case 3: return 'Wednesday'
    case 4: return 'Thursday'
    case 5: return 'Friday'
    case 6: return 'Saturday'
    default: return input
  }
}

export default function (data, short) {
  if (Array.isArray(data)) {
    const sorted = data.toSorted()
    if (sorted.length == 5 && sorted[0] == 1 && sorted[4] == 5) return 'Weekdays'
    if (sorted.length == 2 && sorted[0] == 0 && sorted[1] == 6) return 'Weekends'
    if (sorted.length == 7) return 'All Week'
    const result = sorted.map(value => handler(value, short))
    return result.join(' ')
  }
  return handler(data, short)
}