/**
 * Format an address object
 * @param {*} address 
 * @param {string} option. 'postal_code', 'street', 'city, state'
 * @param {Number} industry. 1 = restaurant, 4 = virtual kitchen
 * @returns 
 */

function formatter(address, option, industry) {
  if (!address) return ''

  const street = getStreet(address)
  const cityState = getCityState(address)

  if (option == 'street') return street
  if (option == 'city, state') return cityState

  let items = []
  if (street && industry != 4) items.push(street) // virtual kitchens don't need street address
  if (cityState) items.push(cityState)
  if (option == 'postal_code' && address.postal_code) return items.join(', ') + ' ' + address.postal_code
  return items.join(', ')
}

function getStreet(address) {
  let items = []
  if (address.line1) items.push(address.line1.trim())
  if (address.line2) items.push(address.line2.trim())
  return items.join(' ')
}

function getCityState(address) {
  let items = []
  if (address.city) items.push(address.city.trim())
  if (address.state) items.push(address.state)
  return items.join(', ')
}

export default formatter
