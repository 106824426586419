<template lang="pug">
v-container
  .d-flex.flex-row.align-center.justify-space-between.flex-wrap
    .sl-title Work
  v-divider.mt-2.mb-5
  Weekly
</template>

<script>
import Weekly from "./Weekly.vue";

export default {
  components: { Weekly },
};
</script>