/**
 * Convert leading minutes to human readable format
 * @param {*} input 
 * @returns
 * example: 10 => 10 minutes
 * example: 60 => 1 hour
 * example: 1440 => 1 day
 * example: 2880 => 2 days
 */

function leadtime(input) {
  const num = Number(input)
  const days = Math.floor(num / 1440)
  const hours = Math.floor(num % 1440 / 60)
  const minutes = Math.floor(num % 60)
  const list = []
  if (days > 1) {
    list.push(days + ' days')
  } else if (days > 0) {
    list.push(days + ' day')
  }
  if (hours > 1) {
    list.push(hours + ' hours')
  } else if (hours > 0) {
    list.push(hours + ' hour')
  }
  if (minutes > 1) {
    list.push(minutes + ' minutes')
  } else if (minutes > 0) {
    list.push(minutes + ' minute')
  }
  if (list.length > 0) return list.join(' and ')
  else return '0 minute'
}

export default leadtime
