<template lang="pug">
div
  .d-flex.flex-row.sticky(v-if="biz")
    Logo
    .text-box
      .biz-name {{ biz.name }}
      .subtitle-2.text--secondary {{ biz.short_name }}
      .subtitle-2.text--secondary(v-if="!isRestaurant", small, outlined) {{ biz.industry | industry }}
      .body-2.text--secondary {{ phone | phone }}
      .body-2.text--secondary {{ biz.address | address("", biz.industry) }}
      ChainBtn.mt-1
      ScheduleStatus.mt-1
  LimitedAccess(:biz="biz")
  DistanceCheck
</template>

<script>
import { mapGetters } from "vuex";
import Logo from "./Logo";
import ChainBtn from "./ChainBtn";
import ScheduleStatus from "./ScheduleStatus";
import DistanceCheck from "./DistanceCheck";
import LimitedAccess from "/libs/components/Biz/LimitedAccess";

export default {
  components: { Logo, ChainBtn, ScheduleStatus, DistanceCheck, LimitedAccess },
  computed: {
    ...mapGetters(["biz"]),
    isRestaurant() {
      return this.biz?.industry == 0;
    },
    phone() {
      const phones = this.biz?.phones;
      if (!phones?.length) return "";
      return phones[0].number;
    },
  },
};
</script>

<style lang="sass" scoped>
.sticky
  position: -webkit-sticky
  position: sticky
  top: 60px
  z-index: 4
  background: #F0F2F5
  display: flex
  padding-top: 12px
  padding-bottom: 12px

.text-box
  flex: 1
  overflow-x: hidden

.biz-name
  font-weight: 500
  font-size: 17px
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
</style>