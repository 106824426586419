<template lang="pug">
.my-btn-items
  Like
  v-btn.text-capitalize(
    rounded,
    depressed,
    small,
    color="#E4E6EB",
    @click="showChain()",
    v-if="chain"
  )
    v-icon(small, left) mdi-store
    span Locations
  Reserve
  Member
  Share
</template>

<script>
import Like from "./Like";
import Reserve from "./Reserve";
import Member from "./Member";
import Share from "./Share";
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  components: { Like, Reserve, Member, Share },
  computed: { ...mapGetters(["chain"]) },
  methods: {
    showChain() {
      EventBus.$emit("show-chain-dialog", this.chain);
    },
  },
};
</script>

<style lang="sass" scoped>
.my-btn-items
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  gap: 10px
  row-gap: 5px
  overflow-x: auto
</style>
