import _ from 'underscore';

function dishNames(ids, biz) {
  if (!ids || !biz) return "";
  const result = _.chain(ids)
    .map(id => {
      const dish = biz.dishes.find(o => o._id === id);
      if (dish) return { name: dish.name, course: dish.course };
      return undefined;
    })
    .compact()
    .groupBy("course")
    .map(arr => arr.map(o => o.name).join(", "))
    .value()
    .join("<br/>");
  return result;
}
export default dishNames