<template lang="pug">
.dish-price
  span.red--text(v-if="discounted_price") {{ discounted_price | currency }}
  span(v-if="!price") +
  span(:class="{ 'discounted-price': discounted_price }", v-else) {{ price | currency }}
  .dish-quantity(v-if="showQuantity") &#215; {{ dish.quantity }}
</template>

<script>
export default {
  props: {
    dish: { type: Object, required: true },
    showQuantity: { type: Boolean, default: false },
  },
  computed: {
    price() {
      if (this.dish.unitPrice) return this.dish.unitPrice;
      return this.dish.price + this.dish.onlineUpcharge;
    },
    discounted_price() {
      if (!this.dish.pctOff) return null;
      return this.price * (1 - this.dish.pctOff / 100);
    },
  },
};
</script>

<style  lang="sass" scoped>
.dish-price
  font-weight: 600
  font-size: 14px
  display: flex
  align-items: center
  gap: 3px

.dish-quantity
  white-space: nowrap

.discounted-price
  color: gray
  text-decoration: line-through
  margin-left: 6px
</style>
