<template lang='pug'>
div(v-if="payment")
  .check-header(v-if="checkId")
    .subtitle Check # {{ checkIndex + 1 }}
    v-spacer
    span(v-if="!showItems") {{ payment.total | currency }}
    v-btn(icon, small, color="secondary", @click="showItems = !showItems")
      v-icon(small) {{ showItems ? "mdi-chevron-up" : "mdi-chevron-down" }}
  div(v-if="!checkId || showItems")
    Item(
      v-for="(item, index) in service_items",
      :key="index + 'service'",
      :title="item.name",
      :amount="item.value"
    )
    Item(title="Subtotal", :amount="payment.subtotal || payment.dish")
    div(v-if="hasTaxItems")
      Item(
        v-for="(item, index) in payment.tax_items",
        :key="index + 'tax'",
        :title="item.name + ' ' + item.percentage + '%'",
        :amount="item.value",
        v-if="item.type !== 1"
      )
    Item(v-else, title="Tax", :amount="payment.tax")
    Item(title="Gift", :amount="payment.gift")
    Item(title="Fee", :amount="payment.fee")
    Item(
      v-for="(item, index) in payment.adjustment?.items",
      :key="index + 'adjustment'",
      :title="item.name",
      :amount="item.value"
    )
    Item(
      v-for="(item, index) in payment.deduction?.items",
      :key="index + 'deduction'",
      :title="item.name",
      :amount="-item.value"
    )
    div
      Item(
        v-for="(item, index) in payment.selflane?.items",
        :key="index + 'selflane'",
        :title="getItemName(item.name)",
        :amount="item.value"
      )
    Item(title="Tip", :amount="payment.tip")
    Item(title="Total", :amount="payment.total")
  div(v-if="hasCharge")
    Charge(:item="online_charge", :isInternal="isInternal")
    Charge(
      v-for="item in payment.charges",
      :key="item._id",
      :item="item",
      :isInternal="isInternal",
      @click="showVoid(item)"
    )
  .sl-dash-divider.my-2
</template>

<script>
import { EventBus } from "@/event-bus";
import Charge from "./Charge";
import Item from "./Item";

export default {
  name: "PaymentGroup",
  components: { Item, Charge },
  props: {
    payment: { type: Object, required: true },
    order: { type: Object, required: true },
    checkId: { type: String, default: null },
    checkIndex: { type: Number, default: null },
  },
  data() {
    return {
      app_name: process.env.VUE_APP_NAME || "Selflane",
      showItems: false,
    };
  },
  computed: {
    // compitable with old version of tax structure
    service_items() {
      return this.payment.service?.items || [];
    },
    hasTaxItems() {
      return this.payment.tax_items && this.payment.tax_items.length > 0;
    },
    isInternal() {
      return this.app_name == "Selflane Internal";
    },
    hasCharge() {
      if (this.payment.charge && this.payment.charge.amount > 0) {
        return true;
      }
      return this.payment.charges.length > 0;
    },
    online_charge() {
      return {
        method: "Online",
        amount: this.payment.charge?.amount,
        transId: this.payment.charge?.id,
        last4: this.payment.card?.last4,
      };
    },
  },
  methods: {
    showVoid(charge) {
      EventBus.$emit("void-charge", charge, this.order, this.checkId);
    },
    getItemName(name) {
      return "Selflane " + name.replace(/_/g, " ");
    },
  },
};
</script>

<style lang="sass" scoped>
.check-header
  display: flex
  align-items: center
  font-size: 13px
  font-weight: 500
  gap: 5px
</style>