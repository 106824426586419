<template lang="pug">
v-container
  Header
  v-divider.my-3
  BizTitle
  Cart(v-if="valid")
  EmptyView(v-else, text="Cart is Empty")
  Info.my-10
  SuccessMessage
</template>

<script>
import Header from "./Header";
import BizTitle from "./BizTitle";
import Cart from "./Cart";
import Info from "./Info";
import SuccessMessage from "./SuccessMessage";
import { mapGetters } from "vuex";

export default {
  components: { Header, BizTitle, Cart, Info, SuccessMessage },
  computed: {
    ...mapGetters(["cartOrder", "cartCount", "biz"]),
    valid() {
      const bizId = this.biz?._id;
      const cartSeller = this.cartOrder?.seller?.id;
      return this.cartCount > 0 && bizId && bizId == cartSeller;
    },
  },
};
</script>
