<template lang="pug">
.items
  // dishes
  DishCell(
    v-for="(dish, index) in cartOrder.dishes",
    :key="index + 'd'",
    :dish="dish",
    @delete="removeDishAt(index)",
    @click="edit(dish, index)",
    editable
  )
  // gift cards
  .item-row(v-for="(gift, index) in cartOrder.gifts", :key="index + 'gift'")
    .name-block eGift Card
    span {{ gift.amount | currency }}
    v-btn(icon, small, @click.stop="removeGiftAt(index)")
      v-icon(small) mdi-delete
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  computed: {
    ...mapGetters(["cartOrder", "cartPromotion"]),
  },
  methods: {
    ...mapActions(["removeDishAt", "removeGiftAt"]),
    edit(dish, index) {
      const clone = JSON.parse(JSON.stringify(dish));
      EventBus.$emit("show-dish", clone, index);
    },
  },
};
</script>

<style lang="sass" scoped>
.items
  font-size: 15px

.item-row
  display: flex
  align-items: center
  overflow: hidden
  cursor: pointer
  gap: 6px
  margin-top: 12px
  margin-bottom: 12px

.name-block
  flex-grow: 1
</style>