import UserProfile from './Views/Profile/index'
import UserWork from '@/components/Work/index.vue'
import loginRoute from './Views/Login/route'

const routes = [
  { path: '/user/profile', component: UserProfile, meta: { auth: true } },
  { path: '/user/work', component: UserWork, meta: { auth: true } },
  ...loginRoute
]

export default routes
