<template lang="pug">
div
  .event-time {{ event.event_time?.begin | date({ dynamic: true }) }}
  .event-title(@click="goHome") {{ event.title }}
  .biz-name {{ biz.name }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: { ...mapGetters(["biz", "event"]) },
  methods: {
    goHome() {
      const path = "/event/" + this.event._id;
      if (this.$route.path == path) return;
      this.$router.push(path);
    },
  },
};
</script>

<style lang="sass" scoped>
.event-time
  font-size: 13px
  font-weight: 500
  color: #413E56

.event-title
  font-size: 18px
  font-weight: 600
  color: #413E56
  cursor: pointer

.biz-name
  font-size: 15px
  font-weight: 500
  color: #8F90A0
</style>