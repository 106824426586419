<template lang="pug">
.dish-tags
  v-icon(
    v-for="(tag, index) in tags",
    :key="index",
    small,
    :color="tag.color",
    :title="tag.title"
  ) {{ tag.icon }}
</template>

<script>
export default {
  props: {
    dish: { type: Object, required: true },
  },
  data() {
    return {
      tagItems: [
        {
          icon: "mdi-barley-off",
          color: "#009600",
          value: "gf",
          title: "Gluten Free",
        },
        { icon: "mdi-sprout", color: "green", value: "vegan", title: "Vegan" },
        {
          icon: "mdi-chili-mild",
          color: "#d70000",
          value: "spicy-mild",
          title: "Spicy Mild",
        },
        {
          icon: "mdi-chili-medium",
          color: "#d70000",
          value: "spicy-medium",
          title: "Spicy Medium",
        },
        {
          icon: "mdi-chili-hot",
          color: "#d70000",
          value: "spicy-hot",
          title: "Spicy Hot",
        },
      ],
    };
  },
  computed: {
    tags() {
      return this.tagItems.filter((o) => this.dish?.tags?.includes(o.value));
    },
  },
};
</script>

<style  lang="sass" scoped>
.dish-tags
  display: flex
  gap: 3px
</style>
