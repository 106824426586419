<template lang="pug">
div
  Cell.mb-5(:ticket="item", v-for="(item, index) in items", :key="index")
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "underscore";
import Cell from "./Cell";

export default {
  components: { Cell },
  computed: {
    ...mapGetters(["event_tickets"]),
    items() {
      return _.chain(this.event_tickets)
        .reject((o) => o.status == false)
        .sortBy((o) => -o.created)
        .value();
    },
  },
  mounted() {
    this.loadTickets();
  },
  methods: {
    ...mapActions(["setEventTickets", "setEvents"]),
    async loadTickets() {
      const user = this.$auth.user();
      if (!user) return;
      const params = { criteria: { "user.id": user._id } };
      try {
        const { data } = await this.axios.post("/event/tickets/list", params);
        this.setEventTickets(data);
        this.loadEvents();
      } catch (e) {
        //
      }
    },
    async loadEvents() {
      const ids = this.event_tickets.map((o) => o.event_id);
      const params = { criteria: { _id: { $in: ids } } };
      try {
        const { data } = await this.axios.post("/event/entries/list", params);
        this.setEvents(data);
      } catch (e) {
        //
      }
    },
  },
};
</script>