<template lang="pug">
v-app-bar(app, flat, color="white")
  v-app-bar-nav-icon(
    @click="$emit('toggleDrawer')",
    v-if="$auth.check()",
    color="primary"
  )
  v-btn(icon, @click="toHome()", v-if="isNormal")
    v-icon $selflaneRev
  .selflane-title.hidden-xs-only(@click="toHome()", v-if="isNormal") Selflane
  v-spacer
  v-btn.mr-2(icon, v-if="isNormal", @click="handleSearch()", color="primary")
    v-icon mdi-magnify
  Cart
  div(v-if="!$auth.check()")
    v-btn(to="/login", rounded, depressed, small, color="primary") Sign in
</template>

<script>
import Cart from "./Cart/index";
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  components: { Cart },
  data() {
    return {
      mode: process.env.VUE_APP_MODE,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    isNormal() {
      return this.mode != "store_front";
    },
  },
  methods: {
    toHome() {
      const currentPath = this.$router.history.current.path;
      if (currentPath != "/") this.$router.push("/");
    },
    handleSearch() {
      EventBus.$emit("search-biz");
    },
  },
};
</script>

<style lang="sass" scoped>
.selflane-title
  font-weight: 600
  font-size: 18px
  cursor: pointer
  color: #FF6D32
  margin-left: 10px
</style>
