<template lang="pug">
.coupon.elevation-3(v-if="promotion")
  CouponTop(:promotion="promotion", :bizs="bizs", :chains="chains")
  v-divider
  .d-flex.flex-row.justify-center.my-2
    ClaimBtn(:promotion="promotion", :promoclaim="promoclaim")
</template>

<script>
import CouponTop from "./Top";
import ClaimBtn from "@/components/Promotion/ClaimBtn";

export default {
  components: { CouponTop, ClaimBtn },
  props: ["promotion", "claims", "bizs", "chains"],
  computed: {
    promoclaim() {
      return this.claims?.find((o) => o.promotion == this.promotion._id);
    },
  },
};
</script>

<style lang="sass" scoped>
.coupon
  border-radius: 20px
  background-color: #31375d
  color: white
  overflow: hidden
</style>
