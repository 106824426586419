<template lang="pug">
div(v-if='bizs && bizs.length').my-3.white
  v-container
    .sl-title Food Trucks
    BizList(:bizs='bizs')
</template>

<script>
import { mapGetters } from "vuex";
import BizList from "@/components/Bizs/ListRow";
import _ from "underscore";

export default {
  components: { BizList },
  computed: {
    ...mapGetters(["bizAccounts"]),
    bizs() {
      return _.filter(this.bizAccounts, o => {
        return o.industry == 1;
      });
    },
  },
};
</script>
