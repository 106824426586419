const state = {
  event: null,
  eventCart: {
    user_name: '',
    user_phone: '',
    event_id: null,
    event_items: [],
    payment: {
      subtotal: 0, tax: 0, total: 0,
      card: {}
    }
  },
  events: [],
  event_tickets: [],
};

const getters = {
  event: state => state.event,
  eventCart: state => state.eventCart,
  events: state => state.events,
  event_tickets: state => state.event_tickets,
};

const actions = {
  setEvent: ({ commit }, data) => { commit("setEvent", data); },
  setEventCart: ({ commit }, data) => { commit("setEventCart", data); },
  addEventCartItem: ({ commit }, data) => { commit("addEventCartItem", data); },
  resetEventCart: ({ commit }, data) => { commit("resetEventCart", data); },
  updateEventCartPayment: ({ commit }, data) => { commit("updateEventCartPayment", data); },
  submitEventCart: ({ commit }, data) => { commit("submitEventCart", data); },
  setEvents: ({ commit }, data) => { commit("setEvents", data); },
  setEventTickets: ({ commit }, data) => { commit("setEventTickets", data); },
  updateEventTicket: ({ commit }, data) => { commit("updateEventTicket", data); },
};

const mutations = {
  setEvent(state, data) {
    state.event = data;
  },
  setEventCart(state, data) {
    state.eventCart = data;
  },
  addEventCartItem(state, data) {
    if (!state.eventCart) this.dispatch('resetEventCart')
    state.eventCart.event_id = state.event._id
    const some = state.eventCart.event_items.some(o => o._id == data._id)
    if (some) {
      state.eventCart.event_items = state.eventCart.event_items.map(o => {
        return (o._id === data._id) ? data : o
      })
    } else {
      state.eventCart.event_items.push(data)
    }
    this.dispatch('updateEventCartPayment')
  },
  resetEventCart(state) {
    state.eventCart = {
      user_name: '',
      user_phone: '',
      event_id: null,
      event_items: [],
      payment: { subtotal: 0, tax: 0, total: 0, card: {} }
    }
  },
  updateEventCartPayment(state) {
    let subtotal = state.eventCart.event_items.reduce((a, b) => a + (b.price * b.quantity || 0), 0)
    subtotal = Math.round(subtotal * 100) / 100
    const tax_rate = state.event.tax_rate || 0
    const tax = Math.round(subtotal * tax_rate) / 100
    const total = Math.round((subtotal + tax) * 100) / 100
    state.eventCart.payment = {
      subtotal: subtotal,
      tax: tax,
      total: total,
      card: state.eventCart.payment.card
    }
  },
  setEvents(state, data) {
    state.events = data;
  },
  setEventTickets(state, data) {
    state.event_tickets = data;
  },
  updateEventTicket(state, data) {
    state.event_tickets = state.event_tickets.map(o => {
      return (o._id === data._id) ? data : o
    });
  },
};

export default { state, getters, actions, mutations };