<template lang="pug">
v-text-field(
  label="",
  :placeholder="placeholder",
  v-model="formatted_address",
  ref="autocomplete",
  @keydown.enter.prevent="",
  color="secondary",
  :loading="loading"
)
</template>

<script>
import MapUtils from "/libs/utils/map";

export default {
  name: "GoogleAddressField",
  props: {
    value: { type: Object, default: null },
    placeholder: { type: String, default: "Enter address" },
    fields: {
      type: Array,
      default: () => [
        "address_component",
        "formatted_address",
        "geometry",
        "name",
        "place_id",
        "type",
      ],
    },
  },
  data() {
    return {
      formatted_address: this.$options.filters.address(this.value), // address in one_line format
      loading: false,
    };
  },
  watch: {
    value: {
      handler() {
        this.formatted_address = this.$options.filters.address(this.value);
      },
      deep: true,
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.autocomplete = new google.maps.places.Autocomplete(
      this.$refs.autocomplete.$refs.input
    );
    this.autocomplete.setFields(this.fields);
    this.autocomplete.setComponentRestrictions({ country: ["us", "ca"] });
    this.autocomplete.addListener("place_changed", () => {
      const place = this.autocomplete.getPlace();
      this.setWithGooglePlace(place);
    });
  },
  methods: {
    async getTimezone(address) {
      this.loading = true;
      try {
        const { data } = await MapUtils.getTimeZone(
          address.geometry.lat,
          address.geometry.lng
        );
        address.timezone = data.timeZoneId;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        throw "No corresponding timezone";
      }
    },
    async setWithGooglePlace(place) {
      const street_number = this.getComponent(place, "street_number");
      const route = this.getComponent(place, "route");
      let locality = this.getComponent(place, "locality");
      if (!locality) {
        locality = this.getComponent(place, "administrative_area_level_3");
      }
      const city = locality?.short_name;
      const state = this.getComponent(
        place,
        "administrative_area_level_1"
      )?.short_name;
      const country = this.getComponent(place, "country")?.short_name;
      const postal_code = this.getComponent(place, "postal_code")?.short_name;
      const line1 = `${street_number?.short_name || ""} ${
        route?.short_name || ""
      }`;
      const address = {
        line1: line1.trim(),
        line2: this.value?.line2,
        city: city,
        state: state,
        country: country,
        postal_code: postal_code,
        geometry: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
        note: this.value?.note,
        place_id: place.place_id,
      };
      if (place.types?.includes("establishment") && place.name) {
        address.name = place.name;
      }
      this.formatted_address = place.formatted_address;
      try {
        await this.getTimezone(address);
      } catch (e) {
        console.log(e);
      } finally {
        this.$emit("input", address);
        this.$emit("select", place);
      }
    },
    getComponent(place, name) {
      return place.address_components.find((o) => o.types.includes(name));
    },
  },
};
</script>
