import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import 'vuetify/dist/vuetify.min.css'
import Vuetify from 'vuetify/lib'
import SelflaneIcon from './SelflaneIcon.vue'
import SelflaneRevIcon from './SelflaneRevIcon.vue'

import {
  colors
} from 'vuetify/lib'

Vue.use(Vuetify);

export default new Vuetify({
  iconfont: 'mdi',
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#FF6D32',
        // primary: '#ff7878', // valentines
        secondary: colors.indigo.base,
        accent: '#747FFF',
        navy: '#1C1F34',
        background: '#F0F2F5'
      }
    }
  },
  icons: {
    values: {
      selflane: {
        component: SelflaneIcon,
      },
      selflaneRev: {
        component: SelflaneRevIcon,
      }
    }
  }
})