<template lang="pug">
.item-container.py-2
  v-row(dense, align="center")
    v-col(cols="8")
      .subtitle-2 {{ item.name }}
    v-col(cols="4")
      .d-flex.flex-row.align-center.justify-end
        v-btn(icon, color="success", @click="minus")
          v-icon mdi-minus-circle
        .subtitle-2.mx-3 {{ quantity }}
        v-btn(icon, color="success", @click="add")
          v-icon mdi-plus-circle
        .subtitle-2.ml-5 {{ cost | currency }}
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["item"],
  computed: {
    ...mapGetters(["eventCart", "event"]),
    quantity() {
      return (
        this.eventCart?.event_items?.find((o) => o.name == this.item.name)
          ?.quantity || 0
      );
    },
    cost() {
      return this.item.price * this.quantity;
    },
  },
  methods: {
    ...mapActions(["addEventCartItem"]),
    add() {
      const quantity = this.quantity + 1;
      this.applyQuantity(quantity);
    },
    minus() {
      let quantity = this.quantity - 1;
      if (quantity < 0) return;
      this.applyQuantity(quantity);
    },
    applyQuantity(quantity) {
      const data = {
        _id: this.item._id,
        name: this.item.name,
        price: this.item.price,
        quantity,
      };
      this.addEventCartItem(data);
    },
  },
};
</script>

<style lang="sass" scoped>
.item-container
  border-bottom: 1px solid #DBDAE3
</style>