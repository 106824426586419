import Favorites from './favorites.vue'
import Orders from './orders.vue'
import Reservations from './reservations.vue'
import Reviews from './reviews.vue'

export default [
    { path: '/user/favorites', component: Favorites, meta: { auth: true } },
    { path: '/user/orders', component: Orders, meta: { auth: true } },
    { path: '/user/reservations', component: Reservations, meta: { auth: true } },
    { path: '/user/reviews', component: Reviews, meta: { auth: true } }
]