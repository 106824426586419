<template lang="pug">
v-footer(dark, padless)
  v-card.navy(flat, tile, width="100%")
    StoreFront(v-if="!isNormal")
    v-container(v-else)
      Links
      AppLinks
      v-divider
      LangSocial
      v-divider
      Ending
</template>

<script>
import StoreFront from "./StoreFront";
import AppLinks from "./AppLinks";
import Links from "./Links";
import LangSocial from "./LangSocial/index";
import Ending from "./Ending";

export default {
  name: "Footer",
  components: { StoreFront, AppLinks, Links, LangSocial, Ending },
  data() {
    return {
      mode: process.env.VUE_APP_MODE,
    };
  },
  computed: {
    isNormal() {
      return this.mode == "normal";
    },
  },
};
</script>
