<template lang="pug">
div
  v-progress-linear.my-3(indeterminate, v-if="loading")
  Recent
  div(v-if="bizAccounts && bizAccounts.length && isNormal")
    NearBy
    FoodTruck
    VirtualKitchen
    MostLiked
    TopReviewed
    NewToSelflane
    Cates
  .text-center.py-10.subtitle-2(v-if="non_found") No Business Found in the Selected Area
</template>

<script>
import BizList from "@/components/Bizs/List";
import BizUtils from "/libs/utils/biz.js";
import { mapActions, mapGetters } from "vuex";
import _ from "underscore";
import { EventBus } from "@/event-bus.js";
import Recent from "./Recent";
import MostLiked from "./MostLiked";
import TopReviewed from "./TopReviewed";
import NewToSelflane from "./NewToSelflane";
import NearBy from "./NearBy";
import FoodTruck from "./FoodTruck";
import VirtualKitchen from "./VirtualKitchen";
import Cates from "./Cates";

export default {
  components: {
    Recent,
    BizList,
    NearBy,
    MostLiked,
    TopReviewed,
    NewToSelflane,
    FoodTruck,
    VirtualKitchen,
    Cates,
  },
  data() {
    return {
      mode: process.env.VUE_APP_MODE,
      loading: false,
      non_found: false,
    };
  },
  computed: {
    ...mapGetters(["selectedCity", "bizAccounts"]),
    isNormal() {
      return this.mode == "normal";
    },
  },
  mounted() {
    EventBus.$on("set-selected-city", this.load);
    this.load();
  },
  destroyed() {
    EventBus.$off("set-selected-city", this.load);
  },
  methods: {
    ...mapActions(["setBizAccounts", "setUserLocation"]),
    async load() {
      if (!this.selectedCity) return;
      this.non_found = false;
      const select =
        "name logo url address cates schedule stats orderType industry online_start";
      let params;
      if (
        this.selectedCity &&
        this.selectedCity.geometry &&
        this.selectedCity.geometry.lat
      ) {
        params = {
          criteria: {
            "status.byAdmin": 1,
            "status.byUser": 1,
            "address.geometry.lat": {
              $gte: this.selectedCity.geometry.lat - 0.3,
              $lte: this.selectedCity.geometry.lat + 0.3,
            },
            "address.geometry.lng": {
              $gte: this.selectedCity.geometry.lng - 0.3,
              $lte: this.selectedCity.geometry.lng + 0.3,
            },
            "schedule.temporary_close.status": { $ne: true },
          },
          select: select,
        };
      } else {
        params = {
          criteria: {
            "status.byAdmin": 1,
            "status.byUser": 1,
            "address.city": this.selectedCity.city,
            "address.state": this.selectedCity.state,
          },
          select: select,
        };
      }
      this.loading = true;
      const result = await this.$api.biz.list(params);
      this.setBizAccounts(result);

      this.loading = false;
      if (!result?.length) {
        this.non_found = true;
        return;
      }
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const location = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.setUserLocation(location);
          const data = _.map(result, (o) => {
            o.distance = BizUtils.geoDistance(o.address.geometry, location);
            return o;
          });
          this.setBizAccounts(_.sortBy(data, "distance"));
        });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
#restaurants-bkg
  background-color: white
</style>
