<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  v-card(v-if="review")
    v-toolbar(flat, dense)
      .subtitle-2 Order Review
    v-card-text
      v-form(@submit.prevent="submit")
        .d-flex.flex-row.align-center
          v-text-field(
            label="Title",
            v-model.trim="title",
            @blur="$v.title.$touch()",
            :error-messages="titleErrors",
            :counter="50",
            autofocus,
            ref="focus"
          )
          v-rating.ml-3(v-model="stars", dense)
        v-textarea(
          label="Content",
          v-model.trim="content",
          @blur="$v.content.$touch()",
          :counter="2000",
          :error-messages="contentErrors"
        )
        v-btn(
          block,
          :disabled="$v.$invalid",
          :loading="loading",
          type="submit",
          color="secondary"
        ) Save
</template>

<script>
import { validationMixin } from "vuelidate";
import { maxLength, required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  name: "ReviewForm",
  mixins: [validationMixin],
  validations: {
    title: { required, maxLength: maxLength(50) },
    content: { required, maxLength: maxLength(2000) },
  },
  data() {
    return {
      review: null,
      dialog: false,
      title: "",
      stars: 5,
      content: "",
      loading: false,
    };
  },
  computed: {
    titleErrors() {
      const errors = [];
      if (!this.$v.title.$dirty) return errors;
      !this.$v.title.required && errors.push("Title is required");
      !this.$v.title.maxLength && errors.push("Max length is 50");
      return errors;
    },
    contentErrors() {
      const errors = [];
      if (!this.$v.content.$dirty) return errors;
      !this.$v.content.required && errors.push("Content is required");
      !this.$v.content.maxLength && errors.push("Max length is 2000");
      return errors;
    },
  },
  methods: {
    ...mapActions(["updateReview", "addReview"]),
    open(data) {
      this.$v.$reset();
      this.review = data;
      this.title = data.title;
      this.stars = data.stars;
      this.content = data.content;
      this.dialog = true;
    },
    async submit() {
      this.loading = true;
      const review = JSON.parse(JSON.stringify(this.review));
      if (review._id) {
        try {
          const action = {
            $set: {
              title: this.title,
              stars: this.stars,
              content: this.content,
            },
          };
          const result = await this.$api.review.put(review._id, action);
          this.updateReview(result);
          this.dialog = false;
        } catch (e) {
          this.$toast.error(e.response?.data || e.message);
        }
        this.loading = false;
      } else {
        review.title = this.title;
        review.stars = this.stars;
        review.content = this.content;
        // validate
        const errors = [];
        if (!review.author || !review.author.id)
          errors.push("Missing author information");
        if (!review.biz) errors.push("Missing business information");
        if (!review.order) errors.push("Missing order information");
        if (errors.length) {
          this.loading = false;
          return;
        }
        try {
          const result = await this.$api.review.create(review);
          this.addReview(result);
          this.dialog = false;
        } catch (e) {
          this.$toast.error(e.response?.data || e.message);
        }
        this.loading = false;
      }
    },
  },
};
</script>
