<template lang="pug">
.items-container
  DishCell(
    v-for="(dish, index) in order.dishes",
    :key="index + 'd'",
    :dish="dish",
    divider
  )
  .items-container(v-for="(item, index) in order.gifts", :key="index + 'gift'")
    .item
      v-flex
        .item-name Gift Card
      div {{ item.amount | currency }}
    .sl-dash-divider
  .items-container(
    v-for="(item, index) in order.vouchers",
    :key="index + 'voucher'"
  )
    .item
      v-flex
        .item-name {{ item.name }}
      .red--text {{ -item.amount | currency }}
    .sl-dash-divider
</template>

<script>
export default {
  props: ["order"],
};
</script>

<style lang="sass" scoped>
.items-container
  display: flex
  flex-direction: column
  gap: 10px

.item
  display: flex
  align-items: center

  &-name
    font-size: 15px

  &-detail
    font-size: 14px
    color: rgba(28, 31, 52, 0.6)
</style>
