<template lang="pug">
.item-row
  .item-text
    .subtitle-2 {{ address | address("street") }}
    .body-2 {{ address | address("city, state") }}
    .body-2 {{ address.note }}
  div
    v-btn(icon, @click.stop="edit()")
      v-icon(color="grey", small) mdi-pencil
    v-btn(icon, @click.stop="remove()", :loading="loading")
      v-icon(color="grey", small) mdi-delete
</template>

<script>
export default {
  props: ["address"],
  data() {
    return { loading: false };
  },
  methods: {
    edit() {
      this.$emit("edit");
    },
    async remove() {
      const userId = this.$auth.user()?._id;
      if (!userId) return;
      const params = {
        criteria: { _id: userId },
        action: { $pull: { addresses: { _id: this.address._id } } },
      };
      this.loading = true;
      try {
        const user = await this.$api.user.update(params);
        this.$auth.user(user);
        this.$toast.success("Address removed");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.item-row
  display: flex
  align-items: center
  overflow: hidden
  padding: 12px

.item-text
  flex-grow: 1
  padding-right: 8px
</style>
