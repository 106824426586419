/**
 * Description: return holiday name
 * @param {*} '2020/12/31'
 * @returns 
 */

function holiday(value) {
  if (!value || typeof value != 'string') return "";
  const items = value.split("/");
  if (items.length !== 3) return "";
  const year = parseInt(items[0]);
  const mm = parseInt(items[1]);
  const dd = parseInt(items[2]);
  if (mm === 12 && dd === 31) return "New Year's Eve";
  if (mm === 1 && dd === 1) return "New Year's Day";
  if (mm === 2 && dd == 14) return "Valentine's Day";
  if (mm === 7 && dd === 4) return "Independence Day";
  if (mm === 11 && dd === 11) return "Veterans Day";
  if (mm === 12 && dd === 24) return "Christmas Eve";
  if (mm === 12 && dd === 25) return "Christmas Day";
  if (mm === 10 && dd === 31) return "Halloween";
  if (mm === 9 && dd === 5) return "Labor Day";
  if (year == 2023 && mm == 5 && dd == 29) return "Memorial Day";
  if (year == 2023 && mm == 5 && dd == 14) return "Mother's Day";
  if (year == 2023 && mm == 6 && dd == 18) return "Father's Day";
  if (year == 2023 && mm == 11 && dd == 23) return "Thanksgiving Day";
  return "";
}
export default holiday;