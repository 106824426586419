<template lang="pug">
div
  v-menu(top, close-on-click, close-on-content-click, offset-y)
    template(v-slot:activator="{ on, attrs }")
      .country.d-flex.flex-row.align-center(v-on="on", v-bind="attrs")
        img.mr-3(:src="selected_item.icon")
        span {{ selected_item.name }}
    v-list
      v-list-item(
        v-for="(item, index) in items",
        :key="index",
        @click="setSelectedCountry(item.abbr)"
      )
        v-list-item-avatar
          img(:src="item.icon")
        v-list-item-content {{ item.name }}
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      on: false,
      items: [
        {
          name: "United States",
          abbr: "US",
          icon: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2FCountries%2Funited-states.svg?alt=media&token=f4d33ec6-6482-490a-952c-ba86fece0d72",
        },
        {
          name: "Canada",
          abbr: "CA",
          icon: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2FCountries%2Fcanada.svg?alt=media&token=e6b06d3b-43cd-4210-b80c-27b1950f5110",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["selectedCountry"]),
    selected_item() {
      if (this.selectedCountry) {
        const found = this.items.find((o) => o.abbr == this.selectedCountry);
        if (found) return found;
      }
      return this.items[0];
    },
  },
  methods: {
    ...mapActions(["setSelectedCountry"]),
  },
};
</script>

<style lang="sass" scoped>
img
  height: 30px
  width: 30px

.country
  color: white
  font-size: 16px
  font-weight: 500
  cursor: pointer
</style>